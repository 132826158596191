import React from "react";
import "./PastEvents.scss";
import logo from "../../../Assets/Academy/Academy-past-event-logo.png";
import { Card } from "primereact/card";
import SFT1 from "../../../Assets/Academy/Startup Foundations for Teens - 1.png";
import SFT2 from "../../../Assets/Academy/Startup Foundations for Teens - 2.png";
import ME from "../../../Assets/Academy/Master Entrepreneurship.png";
import { useNavigate } from "react-router-dom";
import WSData from "../../../NewLandingPage/Workshop/Academy-Workshops/WorkshopsData";

const PastEvents = () => {
    const navigate = useNavigate();
    const data = [
        {
            id: 1,
            courseName: "Startup Foundations for Teens",
            date: "September 14th, 2024",
            location: "Plano",
            img: SFT1,
            description:
                "Join our Entrepreneurship Masterclass to learn from industry experts and secure valuable internship opportunities!",
            logo: logo,
        },
        {
            id: 2,
            courseName: "Startup Foundations for Teens",
            date: "September 8th, 2024",
            location: "Flower Mound",
            img: SFT2,
            description:
                "Join our Entrepreneurship Masterclass to learn from industry experts and secure valuable internship opportunities!",
            logo: logo,
        },
        {
            id: 3,
            courseName: "Master Entrepreneurship",
            date: "July 27th, 2024",
            location: "Flower Mound",
            img: ME,
            description:
                "Discover Your True Potential! Participate in our exclusive 3-hours workshop and acquire cutting-edge skills that will distinguish you in your future career. This is a unique chance for high school students",
            logo: logo,
        },
    ];

    const getWorkShopUrl = (date) => {
        const workshop = WSData.find((workshop) => workshop.date === date);
        return workshop ? handleClickPastEvent(workshop.url) : null;

    }

    const handleClickPastEvent = (url) => {
        navigate(`/workshop/${encodeURIComponent(url.replace(/\s+/g, '-').toLowerCase())}`);
        window.scrollTo(0, 0);
    }


    return (
        <div className="s2l-academy-pastevents">
            <div className="pastevents-container">
                <h2>See our past Events</h2>
                <div className="pastevents-data mt-5 ">
                    {data.map((item) => (
                        <div className="flex-1">
                            <Card className="shadow-5 mx-2 p-0 pastevents-card border-round-xl">
                                <div className="pastevents-card-content  ">
                                    <div className="pastevents-card-img mb-2">
                                        <img src={item.img} alt="img" className="border-round-xl" />
                                    </div>
                                    <div className="pastevents-header flex flex-row gap-4">
                                        <img src={item.logo} alt="logo" />
                                        <h3>{item.courseName}</h3>
                                    </div>
                                    <div className="pastevents-details flex flex-column">
                                        <div className="date-location flex flex-row justify-content-between gap-2">
                                            <p className="pe-date">Date: {item.date}</p>
                                            <p className="pe-location">Location: {item.location}</p>
                                        </div>
                                        <p className="pe-desc">{item.description}</p>
                                    </div>
                                    <div className="pastevents-readmore">
                                        <span className="readmore-btn cursor-pointer" onClick={() => { getWorkShopUrl(item.date); }} >
                                            Read more <i className="pi pi-angle-right"></i>
                                        </span>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PastEvents;
