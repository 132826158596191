import React from "react";
import "./AcademyHeader.scss";
import { Button } from "primereact/button";
import AcademyHeaderImage from "../../../Assets/Academy/Academy-header-bg.png";
import { useNavigate } from "react-router-dom";

const AcademyHeader = () => {
    const navigate = useNavigate();
    return (
        <div className="s2l-academy-header">
            <div className="academy-container  flex flex-row gap-8 justify-content-between">
                <div className="header-content  ">
                    <h2>Your Journey to Innovation Begins Here!</h2>
                    <p>
                        Explore entrepreneurship with hands-on programs designed for high
                        school and middle school students.
                    </p>
                    <Button label="Get Started" className="learn-more-btn-lg" onClick={() => { navigate("/questionnaire"); window.scrollTo(0, 0); }} />
                </div>
                <div className="header-bg">
                    <img
                        src={AcademyHeaderImage}
                        alt="Academy Header "
                        className="s2l-bg h-full w-full"
                    />
                </div>
            </div>
            <div className="lg-sc-btn">
                <Button label="Get Started" className="learn-more-btn-sm" onClick={() => { navigate("/questionnaire") }} />
            </div>
        </div>
    );
};

export default AcademyHeader;
