import React from 'react';
import { Card } from "primereact/card";
import { FaDiagramProject } from 'react-icons/fa6';
import "./Dashboard.scss";
import { FaUsers } from 'react-icons/fa';

const Dashboard = ({ data, onSectionChange }) => {

    const handleCourseCardClick = () => {
        onSectionChange('courses');
    };

    const handleUsersCardClick = () => {
        onSectionChange('users');
    };

    return (
        <>
            <div className="admin-container">
                <div className="course-header">
                    <div className="dashboard-title text-left p-3 mt-0">Dashboard</div>
                </div>

                <div className="dashboard-card-section mt-3">
                    <Card className='w-25rem text-center'>
                        <div className="card-details-sec p-0">
                            <div className="">
                                <h2>{data.totalUser}</h2>
                                <div className="heading">
                                    <h3>Users</h3>
                                </div>
                            </div>
                            <div className="card-icon">
                                <FaUsers />
                            </div>
                        </div>
                        <p onClick={handleUsersCardClick}>
                            See More Information
                        </p>
                    </Card>

                    <Card className='w-25rem text-center'>
                        <div className="card-details-sec p-0">
                            <div className="">
                                <h2>{data.totalCourse}</h2>
                                <div className="heading">
                                    <h3>Courses</h3>
                                </div>
                            </div>
                            <div className="card-icon">
                                <FaDiagramProject />
                            </div>
                        </div>
                        <p onClick={handleCourseCardClick}>
                            See More Information
                        </p>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Dashboard;
