import React from "react";
import logo from "../../Assets/Home Page/S2L-Logo.svg";

const StackNavbar = () => {
    
    return (
        <>
            <header className="s2l-header">
                <nav className="navigation">
                    <div className="logo">
                        <a href="/"><img src={logo} alt="" /></a>
                    </div>
                    <div className="stack-user">
                        
                    </div>
                </nav>
            </header>
        </>
    )
}

export default StackNavbar;