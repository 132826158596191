import React from 'react';
import './Footer.scss';
import { FaInstagram, FaFacebookF, FaYoutube, FaLinkedinIn, FaTiktok } from 'react-icons/fa';
import { TbBrandX } from 'react-icons/tb';
import FooterLogo from "../../../Assets/Home Page/Startup2Launch-Logo-White-LB-2024_.png";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const Footer = () => {
    return (
        <footer className="footer">
            <img src={FooterLogo} alt="Startup2Launch Logo" className="logo" />

            <div className="footer-content">
                <div className="footer-left">

                    <nav className="footer-nav">
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/programs">Programs</a></li>
                            <li><a href="/academy">Academy</a></li>
                            <li><a href="/aboutUs">About Us</a></li>
                        </ul>
                    </nav>

                    <div className="social-icons">
                        <a href="https://www.instagram.com/startup2launchhq" target='_blank' rel="noopener noreferrer"><FaInstagram /></a>
                        <a href="https://x.com/startup2launchq" target='_blank' rel="noopener noreferrer"><TbBrandX /></a>
                        <a href="https://www.facebook.com/profile.php?id=61553933195892" target='_blank' rel="noopener noreferrer"><FaFacebookF /></a>
                        <a href="https://www.youtube.com/@Startup2Launch-zo3vn" target='_blank' rel="noopener noreferrer"><FaYoutube /></a>
                        <a href="https://www.linkedin.com/company/startup2launch/?trk=feed_main-feed-card_feed-actor-name" target='_blank' rel="noopener noreferrer"><FaLinkedinIn /></a>
                        <a href="https://www.tiktok.com/@startup2launchq" target='_blank' rel="noopener noreferrer"><FaTiktok /></a>
                    </div>

                    <div className="footer-bottom">
                        <div className="legals">
                            <h3>Legals</h3>
                        </div>
                        <div className="links-lists">
                            <a href="https://staticfile.startup2launch.com/Non-Disclosure+Agreement.pdf" target="_blank" rel="noopener noreferrer">Non-Disclosure Agreement (NDA)</a>
                            <span> | </span>
                            <a href="https://staticfile.startup2launch.com/Student+Participation+Agreement.pdf" target="_blank" rel="noopener noreferrer">Student Participation Agreement</a>
                            <span> | </span>
                            <a href="https://staticfile.startup2launch.com/Patent+Co-Ownership+Agreement.pdf" target="_blank" rel="noopener noreferrer">Patent Co-Ownership Agreement</a>
                            <span> | </span>
                            <a href="https://staticfile.startup2launch.com/Terms+and+Conditions.pdf" target="_blank" rel="noopener noreferrer">Terms and conditions</a>
                            <span> | </span>
                            <a href="https://staticfile.startup2launch.com/Privacy+Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </div>
                    </div>
                </div>

                <div className="footer-right">
                    <div className="newsletter">
                        <p>Subscribe to our Newsletter</p>
                        <form>
                            <InputText className="w-9" type="email" placeholder="Enter your Email ID" />
                            <Button type="submit">Subscribe</Button>
                        </form>
                    </div>

                    <div className="contact-info">
                        <p>Contact Us</p>
                        <p>Phone Number: (214) 906-3355</p>
                        <p>Email: info@startup2launch.com</p>
                    </div>
                </div>
            </div>

            <div className='rights-section'>
                <p>All rights reserved by Syncronix Group</p>
            </div>
        </footer>
    );
};

export default Footer;
