import React, { useRef, useState } from 'react';
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import LogIn from "../../../../Assets/Authentication/ResetPwd.jpg";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { FORGOT_PASSWORD_RESEND_URL, RESET_PASSWORD_URL, UNIQUEID_URL } from "../../../../Utils/APIService";
import { CONTENT_HEADERS } from "../../../../Utils/LandingService";
import JSEncrypt from "jsencrypt";
import "../LoginPage.scss";
import ErrorMessage from '../../../../Shared-Components/ErrorMessage/ErrorMessage';
import { Messages } from 'primereact/messages';
import logo from "../../../../Assets/Home Page/S2L-Logo.svg";

const ResetPasswordPage = () => {
    const location = useLocation();

    const { email } = location.state || {}

    const [confirmcode, setConfirmcode] = useState('');
    const [username, setUsername] = useState(email);
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [activeBtn, setActiveBtn] = useState(true);
    const [code, setCode] = useState(true);
    const [showMessages, setShowMessages] = useState(false);
    const msgs = useRef(null);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setUsername(e.target.value);
        setError('');
    };

    const handleConfirmcodeChange = (e) => {
        const value = e.target.value;
        setConfirmcode(value);
        if (value) {
            let codeLength = value.length;
            if (codeLength >= 6) {
                setCode(false)
            } else {
                setCode(true)
            }
        } else {
            setCode(true)
        }
        setError('');
    };

    const header = <div className="font-bold mb-3">Pick a password</div>;
    const footer = (
        <>
            <Divider />
            <p className="mt-2">Your password must contain</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li className={isLengthValid ? "valid" : "invalid"}>Between 8 and 16 characters</li>
                <li className={hasNumber ? "valid" : "invalid"}>At least one number</li>
                <li className={hasSpecialCharacter ? "valid" : "invalid"}>At least one special character</li>
                <li className={hasUppercase ? "valid" : "invalid"}>At least one uppercase letter</li>
                <li className={hasLowercase ? "valid" : "invalid"}>At least one lowercase letter</li>
            </ul>
        </>
    );
    const handleConfirmLogin = () => {

        const uniqueId = uuidv4();

        const requestData = {
            uniqueId: uniqueId,
        };

        if (!username || !confirmcode || !password || !confirmpassword) {
            setError('Please fill all the fields.');
        }

        else if (password !== confirmpassword) {
            setError("Your password doesn't seem to match, kindly try again.");

        }
        else if (!password || !confirmpassword) {
            setError("Your not filling confirm password and new password.Please check it.")
        }
        else if (!password) {
            setError('Please fill your new password.');
        } else if (!confirmpassword) {
            setError('Please fill your confirm password.');
        }
        else if (!confirmcode) {
            setError('Please provide confirmation code.');

        }
        else if (!/^\d{6}$/.test(confirmcode)) {
            setError("Invalid confirmation code. Please provide a 6-digit code.");
            return;
        }
        else {
            axios
                .post(UNIQUEID_URL, requestData, { headers: CONTENT_HEADERS })
                .then((response) => {

                    const data = {
                        publicKey: response.data.PublicKey,
                    }

                    const jsEncrypt = new JSEncrypt();
                    jsEncrypt.setPublicKey(data.publicKey)

                    const combinedString = `${username}:${password}:${confirmcode}`;
                    const encryptedCombinedString = jsEncrypt.encrypt(combinedString);

                    const apiPayload = {
                        uniqueId: requestData.uniqueId,
                        encryptedData: encryptedCombinedString,
                    };

                    axios
                        .post(RESET_PASSWORD_URL, apiPayload, CONTENT_HEADERS)
                        .then((response) => {
                            if (response?.data?.hasOwnProperty('message')) {
                                const sucMessage = response.data.message;
                                if (sucMessage === "Password updated Successfully") {
                                    setSuccessMessage("You've successfully changed your password! You can now use your new security info to sign in to your account.");
                                    setTimeout(() => {
                                        setSuccessMessage('');
                                        navigate("/login");
                                    }, 3000);
                                }
                            }

                        })
                        .catch(error => {
                            if (error?.response?.data?.hasOwnProperty('message')) {
                                const errorMessage = error.response.data.message;
                                if (errorMessage === "Enter the valid username") {
                                    if (!username) {
                                        setError("Please provide an email.");
                                    }
                                    else {
                                        setError("Please provide valid email.");
                                    }
                                }
                                if (errorMessage === "Cognito user confirmation code mismatch") {
                                    if (!confirmcode) {
                                        setError("Please provide confirmation code.");
                                    }
                                    else {
                                        setError("Your confirmation code mismatched.");
                                    }
                                }

                                if (errorMessage === "Cognito user confirmation code expired") {
                                    setError("Your confirmation code expired.");
                                }
                            }
                            else {
                                setError("Please double-check and try again.")
                            }

                        })
                        .catch((error) => {
                            console.error('API Error:', error);
                        })

                })
        }
    }

    const handleResendCode = () => {
        axios
            .post(FORGOT_PASSWORD_RESEND_URL, { email: username }, { headers: CONTENT_HEADERS })
            .then((response) => {
                setShowMessages(showMessages);
                msgs.current.show({ severity: 'info', detail: 'Your verification code has been resend to your email. Please verify that.' });
                setTimeout(() => {
                    setShowMessages(!showMessages);
                    setShowMessages('');
                }, 5000);
            })
            .catch((error) => {
                if (error?.response?.data?.hasOwnProperty("message")) {
                    const errorMessage = error.response.data.message;
                    if (errorMessage === "Enter the valid username") {
                        setError("The email you’re entered doesn’t exist. Please check that you have typed your email correctly.");
                    }
                } else {
                    if (!username.email) {
                        setError("Please provide an email");
                    }
                }
            });
    };

    const handleChangePassword = (inputValue) => {
        setPassword(inputValue);

        const isLengthValid = inputValue.length >= 8;
        const hasNumber = /\d/.test(inputValue);
        const hasUppercase = /[A-Z]/.test(inputValue);
        const hasLowercase = /[a-z]/.test(inputValue);
        const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(inputValue);

        setIsLengthValid(isLengthValid);
        setHasNumber(hasNumber);
        setHasUppercase(hasUppercase);
        setHasLowercase(hasLowercase);
        setHasSpecialCharacter(hasSpecialCharacter);

        if (confirmpassword) {
            if (inputValue === confirmpassword) {
                setActiveBtn(false);
            } else {
                setActiveBtn(true);
            }
        } else {
            setActiveBtn(true);
        }
    };

    const handleChangeConfirmPassword = (inputValue) => {
        setConfirmPassword(inputValue);

        const isLengthValid = inputValue.length >= 8;
        const hasNumber = /\d/.test(inputValue);
        const hasUppercase = /[A-Z]/.test(inputValue);
        const hasLowercase = /[a-z]/.test(inputValue);
        const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(inputValue);

        setIsLengthValid(isLengthValid);
        setHasNumber(hasNumber);
        setHasUppercase(hasUppercase);
        setHasLowercase(hasLowercase);
        setHasSpecialCharacter(hasSpecialCharacter);

        if (password) {
            if (password === inputValue) {
                setActiveBtn(false);
            } else {
                setActiveBtn(true);
            }
        } else {
            setActiveBtn(true);
        }
    };
    return (
        <>
            <div className="auth-section">
                <div className="logos">
                    <a href="/"><img src={logo} alt="" /></a>
                </div>

                <div className="login-page">
                    <Card className="login-card p-2">
                        <h2 className="text-center">Reset Password</h2>
                        <div className="login-section">
                            <div className="w-full login-detail-section">

                                <div className="fields">

                                    {error ?
                                        <div className="py-3"><ErrorMessage message={error} isSuccess={true} /></div>
                                        : successMessage ?
                                            <div className="py-3"><ErrorMessage message={successMessage} isSuccess={false} /></div>
                                            : null}

                                    {!showMessages && <Messages ref={msgs} className="noteMessages" />}
                                    <div className="p-fluid">
                                        <div className="field">
                                            <label className="schoolid">Confirm Code</label>
                                            <InputText
                                                // type="number"
                                                keyfilter="int"
                                                value={confirmcode}
                                                placeholder='Enter the confirmation code'
                                                onChange={handleConfirmcodeChange}
                                                maxLength={6}
                                                useGrouping={false}
                                                className={error && !password ? "email-error" : ""}
                                            />
                                        </div>

                                        <div className="field">
                                            <label className="email">Email</label>
                                            <InputText
                                                readOnly
                                                value={username}
                                                placeholder='Enter your email'
                                                onChange={handleInputChange}
                                                className={error && !username ? "email-error" : ""}
                                            />
                                        </div>

                                        <div className="field">
                                            <label className="password">New Password</label>
                                            <Password
                                                value={password}
                                                placeholder='Enter your new password'
                                                onChange={(e) => handleChangePassword(e.target.value)}
                                                header={header}
                                                footer={footer}
                                                toggleMask
                                                className={error && !password || activeBtn === true && !password && confirmpassword ? "error" : ""}
                                                minLength={8}
                                                maxLength={16}
                                            />
                                            {activeBtn === true && !password && confirmpassword ? <div>
                                                <div className='errorLable'>Please enter a new password</div>
                                            </div> : null}
                                        </div>

                                        <div className="field">
                                            <label className="password">Confirm Password</label>
                                            <Password
                                                value={confirmpassword}
                                                placeholder='Confirm password'
                                                // disabled={!password}
                                                onChange={(e) => handleChangeConfirmPassword(e.target.value)}
                                                header={header}
                                                footer={footer}
                                                toggleMask
                                                className={error && !confirmpassword || activeBtn === true && confirmpassword && password ? "error" : "" || error ? "error" : ""}
                                                minLength={8}
                                                maxLength={16}
                                            />
                                            {activeBtn === true && confirmpassword && password ? <div>
                                                <div className='errorLable'>Please check a confirm password</div>
                                            </div> : null}
                                        </div>
                                        <div className='button' style={{ margin: 'unset' }}>
                                            <Button label="Confirm" className="p-button-sm" type="submit" onClick={handleConfirmLogin} disabled={activeBtn || code} />
                                            <div className='resend'><span onClick={handleResendCode}>Resend Code</span></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="login-img">
                                <img src={LogIn} alt="login-up-image"></img>
                            </div>
                        </div>

                    </Card>
                </div>
            </div>
        </>
    )
}

export default ResetPasswordPage;