// Base Url
export const BASE_URL = `${process.env.REACT_APP_URL}`;

// export const BASE_URL = 'https://backend.devl.startup2launch.com/startup2launch';

// Headers

export const CONTENT_HEADERS = {
    "content-type": "application/json",
};


// API Endpoints

export const Contact_Us = `${BASE_URL}/client-form/submit`;

// Job Openings Api

export const Get_Job_Openings = `${BASE_URL}/workable_jobs`;

// Demo Projects Endpoints

export const PAYMENT = `${BASE_URL}/payment-management/card/stripe/authorise/`;

export const TRANSCRIPTION = `${BASE_URL}/bot/transcription`;

export const SENTIMENT_ANALYSIS = `${BASE_URL}/bot/sentiment_analysis`;