import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import JSEncrypt from "jsencrypt";
import { DELETE_USER_URL, SIGNUP_URL, UNIQUEID_URL } from "../../../Utils/APIService";
import { CONTENT_HEADERS } from "../../../Utils/LandingService";
import LogIn from "../../../Assets/Authentication/Signup.jpg";
import logo from "../../../Assets/Home Page/S2L-Logo.svg";
import { Card } from "primereact/card";
import "../Signup/Signup.scss";

const CreateAccount = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        createpassword: "",
        confirmPassword: "",
        termsChecked: false,
    });

    const [passwordError, setPasswordError] = useState(null);
    const [validation, setValidation] = useState(false);
    const [emailValidation, setemailValidation] = useState(false);

    const [isLengthValid, setIsLengthValid] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        const isLengthValid = value.length >= 8;
        const hasNumber = /\d/.test(value);
        const hasUppercase = /[A-Z]/.test(value);
        const hasLowercase = /[a-z]/.test(value);
        const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

        setIsLengthValid(isLengthValid);
        setHasNumber(hasNumber);
        setHasUppercase(hasUppercase);
        setHasLowercase(hasLowercase);
        setHasSpecialCharacter(hasSpecialCharacter);

        // if (name === "confirmPassword" || name === "createpassword") {
        //   setValidation(formData.createpassword === formData.confirmPassword);
        // }
        if (e.target.placeholder === "Confirm Password") {
            if (
                formData?.createpassword ===
                (e.target.value || formData?.confirmPassword)
            ) {
                setValidation(true);
            } else {
                setValidation(false)
            }

            if (e?.target?.checked && (formData?.createpassword === (e.target.value || formData?.confirmPassword))) {
                setValidation(true)
            } else if (!e?.target?.checked) {
                if ((formData?.createpassword === e.target.value)) {
                    setValidation(true)
                } else {
                    setValidation(false)
                }
            }

        } else {
            if (formData?.confirmPassword === (e.target.value || formData?.createpassword)) {
                setValidation(true)
            } else {
                setValidation(false)
            }

            if (e?.target?.checked && (formData?.confirmPassword === (e.target.value || formData?.createpassword))) {
                setValidation(true)
            } else if (!e?.target?.checked) {
                if ((formData?.confirmPassword === (e.target.value || formData?.createpassword))) {
                    setValidation(true)
                } else {
                    setValidation(false)
                }
            }
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setFormData({ ...formData, email: emailValue });
        setemailValidation(
            validateEmail(emailValue) ? "" : "Please enter a valid email address"
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.email || !formData.createpassword || !formData.confirmPassword || !formData.termsChecked) {
            setPasswordError("Please fill all the fields.");
            return;
        }
        else if (formData.createpassword !== formData.confirmPassword) {
            setPasswordError("Your passwords do not match. Please make sure they match to continue.");
            return;
        }

        setPasswordError('');
        const uniqueId = uuidv4();
        axios
            .post(UNIQUEID_URL, { uniqueId }, { headers: CONTENT_HEADERS })
            .then((response) => {
                const publicKey = response.data.PublicKey;
                const jsEncrypt = new JSEncrypt();
                jsEncrypt.setPublicKey(publicKey);

                const encryptedPassword = jsEncrypt.encrypt(formData.createpassword);

                const requestData = {
                    email: formData.email,
                    password: encryptedPassword,
                    uniqueId: uniqueId,
                    termsChecked: formData.termsChecked,
                };

                axios
                    .post(SIGNUP_URL, requestData, { headers: CONTENT_HEADERS })
                    .then((response) => {
                        localStorage.setItem("email", requestData.email);
                        navigate("/signup/confirmcode", { state: { requestData } });
                    })
                    .catch((error) => {
                        console.error('API Error:', error.response.data.message);
                        if (error?.response?.data?.hasOwnProperty("message")) {
                            const errorMessage = error.response.data.message;

                            switch (errorMessage) {
                                case "Given User Not Registered By Admin":
                                    setPasswordError("You are not registered by Admin");
                                    break;

                                case "Cognito user already exists":
                                    axios
                                        .delete(DELETE_USER_URL + formData.email, {})
                                        .then((res) => {
                                            axios.post(SIGNUP_URL, requestData, { headers: CONTENT_HEADERS })
                                                .then((response) => {
                                                    localStorage.setItem('email', requestData.email);
                                                    localStorage.setItem("userId", response.data.user_id);
                                                    navigate('/signup/confirmcode');
                                                })
                                                .catch((error) => { });
                                        })
                                        .catch((error) => { });
                                    break;

                                case "Email already exists.":
                                    setPasswordError(errorMessage);
                                    break;

                                case "INVALID_PASSWORD_COMPOSITION,":
                                    setPasswordError("Your password is invalid. Please follow the password criteria.");
                                    break;

                                case "Exceeded daily email limit for the operation or the account":
                                    setPasswordError("Exceeded daily email limit for the operation or the account");
                                    break;

                                default:
                                    setPasswordError(errorMessage);
                                    break;
                            }
                        } else {
                            setPasswordError('Please double-check and try again.');
                        }

                    })
                    .catch((error) => {
                        console.error('API Error:', error.response.data.message);
                    });
            })
            .catch((error) => {
                console.error('API Error:', error.response.data.message);
            })
    };

    const header = <div className="font-bold mb-3">Pick a password</div>;
    const footer = (
        <>
            <Divider />
            <p className="mt-2">Your password must contain</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li className={isLengthValid ? "valid" : "invalid"}>Between 8 and 16 characters</li>
                <li className={hasNumber ? "valid" : "invalid"}>At least one number</li>
                <li className={hasSpecialCharacter ? "valid" : "invalid"}>At least one special character</li>
                <li className={hasUppercase ? "valid" : "invalid"}>At least one uppercase letter</li>
                <li className={hasLowercase ? "valid" : "invalid"}>At least one lowercase letter</li>
            </ul>
        </>
    );

    return (
        <>

            <div className="auth-section">
                <div className="logos">
                    <a href="/"><img src={logo} alt="" /></a>
                </div>

                <div className="login-page">
                    <Card className="login-card p-2">
                        <div className="login-section">
                            <div className="w-full login-detail-section">
                                <h2 className="">Create Your Account</h2>
                                <p>Review you information and create a password</p>

                                <div className="fields">
                                    <div className="flex flex-column w-full">
                                        <label htmlFor="email">Email</label>
                                        <InputText
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            onChange={handleEmailChange}
                                            placeholder="Enter your Email"
                                            required
                                            className={passwordError && !formData.email ? 'email-error' : '' || emailValidation ? 'email-error' : ''}
                                        />
                                    </div>
                                    {emailValidation && (
                                        <small className="error-message">{emailValidation}</small>
                                    )}

                                    <div className="flex flex-column w-full mt-3">
                                        <label htmlFor="createpassword">Password</label>
                                        <Password
                                            id="createpassword"
                                            required
                                            name="createpassword"
                                            value={formData.createpassword}
                                            onChange={handleChange}
                                            toggleMask
                                            header={header} footer={footer}
                                            className={`p-password ${passwordError && !formData.createpassword ? 'error' : ''} ${passwordError && formData.createpassword !== formData.confirmPassword || !formData.createpassword && formData.confirmPassword ? 'error' : ''}`}
                                            placeholder="Enter Your Password"
                                            minLength={8}
                                            maxLength={16}
                                        />
                                        {!formData.createpassword && formData.confirmPassword ? <div>
                                            <div className='errorLable'>Please enter a create password</div>
                                        </div> : null}
                                    </div>

                                    <div className="flex flex-column w-full mt-3">
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <Password
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            toggleMask
                                            header={header} footer={footer}
                                            className={`p-password ${passwordError && !formData.confirmPassword ? 'error' : ''} ${passwordError && formData.createpassword !== formData.confirmPassword || formData?.confirmPassword && formData?.createpassword && !validation ? 'error' : ''}`}
                                            placeholder="Confirm Password"
                                            minLength={8}
                                            maxLength={16}
                                        />
                                        {formData?.confirmPassword && formData?.createpassword && !validation ? <div>
                                            <div className='errorLable'>Please check a confirm password</div>
                                        </div> : null}
                                    </div>
                                </div>

                                <div className="field-checkbox mt-3">
                                    <Checkbox
                                        inputId="termsChecked"
                                        name="termsChecked"
                                        checked={formData.termsChecked}
                                        onChange={(e) =>
                                            setFormData({ ...formData, termsChecked: e.checked })
                                        }
                                        required
                                    />
                                    <label htmlFor="termsChecked">
                                        I’ve read and accepted Terms of Service and Privacy Policy
                                    </label>
                                </div>

                                <Button
                                    type="submit"
                                    label="Create Account"
                                    className="mt-2"
                                    onClick={handleSubmit}
                                    disabled={
                                        !formData.email || 
                                        !formData.createpassword || 
                                        !formData.confirmPassword || 
                                        !formData.termsChecked || 
                                        formData.createpassword !== formData.confirmPassword
                                    }
                                />

                                <span className="login-footer">
                                    Do you have an account?&nbsp;
                                    <span
                                        className="signup-button"
                                        onClick={() => navigate("/login")}
                                    >
                                        Login
                                    </span>
                                </span>
                            </div>
                            <div className="login-img">
                                <img src={LogIn} alt="login-up-image"></img>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default CreateAccount;
