import React from "react";
import "./AcademyPrograms.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const AcademyPrograms = () => {
    const navigate = useNavigate();
    const programs = [
        {
            id: "1",
            name: "SP-1: Introductory Entrepreneurship Workshop",
            description:
                "Learn the basics of entrepreneurship, brainstorm ideas, and pitch your first concept—all in one day!",
            duration: "1 Day",
        },
        {
            id: "2",
            name: "SP-2: Advanced Entrepreneurship Bootcamp with Capstone Project",
            description:
                "Dive deep into entrepreneurship. From planning and prototyping to pitching your startup idea, gain hands-on experience in building a business.",
            duration: "12 weeks",
        },
        {
            id: "3",
            name: "SP-3: Idea to Implementation Program",
            description:
                "Transform your business idea into a real, scalable startup with hands-on mentorship, expert guidance, and comprehensive support.",
            duration: "16 weeks",
        },
    ];
    return (
        <div className="s2l-academy-programs">
            <div className="program-container flex flex-column gap-5">
                <div className="program-header">
                    <h2>Find the Perfect Program for You</h2>
                    <p>
                        From introductory workshops to immersive boot camps, S2L Academy has
                        a program for every aspiring entrepreneur. <br />
                        Learn by doing, connect with mentors, and collaborate with
                        like-minded students.
                    </p>
                </div>

                <div className="program-list grid">
                    {programs.map((program) => (
                        <div
                            className="program-card col-4 border-round-xl"
                            id={`pc_${program.id}`}
                            key={program.id}
                        >
                            <h3>{program.name}</h3>
                            <p>Duration: {program.duration}</p>
                            <p>{program.description}</p>
                        </div>
                    ))}
                </div>

                <div className="program-button">
                    <Button label="Get Started" onClick={()=>{navigate("/questionnaire"); window.scrollTo(0, 0);}} />
                </div>
            </div>
        </div>
    );
};

export default AcademyPrograms;
