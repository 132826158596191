import React, { useRef, useState } from "react";
import "../../Login/LoginPage.scss";
import { Button } from "primereact/button";
import LogIn from "../../../../Assets/Authentication/ConfirmCode.jpg";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../Assets/Home Page/S2L-Logo.svg";
import axios from "axios";
import { CONFIRMATION_URL, RESEND_CONFIRMATION_URL } from "../../../../Utils/APIService";
import { CONTENT_HEADERS } from "../../../../Utils/LandingService";
import { Messages } from 'primereact/messages';
import ErrorMessage from "../../../../Shared-Components/ErrorMessage/ErrorMessage";

const ConfirmationPage = () => {
    const [confirmCode, setConfirmCode] = useState("");
    const [error, setError] = useState("");
    const [showMessages, setShowMessages] = useState(false);
    const msgs = useRef(null);

    const navigate = useNavigate();

    const location = useLocation();
    const { requestData } = location.state || {};

    const handleInputChange = (e) => {
        setConfirmCode(e.target.value);
        setError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const confirmCodePattern = /^\d{6}$/;
        if (!confirmCode) {
            setError("Provide a confirmation code");
            return;
        }
        else if (!confirmCodePattern.test(confirmCode)) {
            setError("Invalid confirmation code. Please provide a 6-digit code.");
            return;
        }

        const request = {
            confirmationCode: confirmCode,
            uniqueId: requestData.uniqueId,
            email: requestData.email,
            password: requestData.password,
        }

        axios
            .post(CONFIRMATION_URL, request, { headers: CONTENT_HEADERS })
            .then((response) => {
                localStorage.setItem("userAccessToken", response.data.access_token);
                localStorage.setItem("userRefreshToken", response.data.refresh_token);
                localStorage.setItem("userId", response.data.user_id);
                localStorage.setItem("email", response.data.email_Id);
                localStorage.setItem("botId", response.data.botId);

                if (response.data.role === "ADMIN") {
                    navigate("/adminDashboard")
                } else {
                    if (response.data.isUserEnabled) {
                        navigate("/dashboard")
                    } else {
                        navigate("/additionalInfo");
                    }
                }
            })
            .catch((error) => {
                if (error?.response?.data?.hasOwnProperty("message")) {
                    const errorMessage = error.response.data.message;

                    if (errorMessage === "Confirmation code doesn't match") {
                        setError("Your confirmation code mismatched.");
                    }
                }
                else {
                    setError("Please double-check and try again.")
                }

            });
    }

    const handleResendCode = () => {
        const userName = requestData.email;
        if (userName) {
            axios
                .get(RESEND_CONFIRMATION_URL + userName, {})
                .then((response) => {
                    setShowMessages(showMessages);
                    msgs.current.show({ severity: 'info', detail: 'Your verification code has been resend to your email. Please verify that.' });
                    setTimeout(() => {
                        setShowMessages(!showMessages);
                        setShowMessages('');
                    }, 5000);
                })
                .catch((error) => {
                    console.log(error?.response?.data?.message);
                });
        }
    };

    return (
        <>
            <div className="auth-section">
                <div className="logos">
                    <a href="/"><img src={logo} alt="" /></a>
                </div>

                <div className="login-page">
                    <Card className="login-card p-2">
                        <div className="login-section">
                            <div className="w-full login-detail-section">
                                {!showMessages && <Messages ref={msgs} className="noteMessages" />}

                                <h2 className="">Confirmation Code!</h2>
                                <p>Verify your email address by entering the code that was sent to it</p>

                                {error && <ErrorMessage message={error} isSuccess={true} />}

                                <div className="fields">
                                    <div className="flex flex-column w-full">
                                        <label htmlFor="confirmcode">Confirm Code</label>
                                        <InputText
                                            maxLength={6}
                                            keyfilter="int"
                                            value={confirmCode}
                                            placeholder="Enter the confirmation code"
                                            onChange={handleInputChange}
                                            className={error && !confirmCode ? "email-error" : ""}
                                        />
                                    </div>

                                    <div className="login-button">
                                        <Button
                                            type="button"
                                            label="Confirm"
                                            onClick={handleSubmit}
                                            disabled={!confirmCode}
                                            className="w-full mt-4"
                                        />
                                    </div>
                                    <br />

                                    <div className='resend'>
                                        <span onClick={handleResendCode}>Resend Code</span>
                                    </div>
                                </div>
                            </div>

                            <div className="login-img">
                                <img src={LogIn} alt="login-up-image"></img>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ConfirmationPage;