import React, { useState, useRef, useEffect } from 'react';
import './UpcomingEventsDetails.scss';
import { useParams } from 'react-router-dom';
import Header from '../../../NewLandingPage/Home/Header/Header';
import Footer from '../../../NewLandingPage/Home/Footer/Footer';
import { Button } from 'primereact/button';
import { FaClock } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaShareSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaFacebook } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Toast } from 'primereact/toast';
import MapComponent from '../../../Shared-Components/MapComponent/MapComponent';

const colors = ["#FFF7F6", "#F5F6F9", "#F5F9F7"];
const backgroundColors = ["#FAAFB4", "#324B8C", "#CDDFD6"];

const UpcomingEventsDetails = ({ UpcomingEvents }) => {

    const { name } = useParams();
    const formattedEventName = name.replace(/-/g, ' ').toLowerCase();
    const toast = useRef(null);

    const event = UpcomingEvents.find(event => {
        // Convert event name to lowercase and replace spaces with dashes for URL compatibility
        const formattedName = event.url.toLowerCase();
        return formattedName === formattedEventName;
    });

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);



    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            closeDropdown();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleShare = (option) => {
        const shareLink = window.location.href;
        const shareText = `Check out this workshop: ${event.url}`;
        const encodedShareText = encodeURIComponent(shareText);
        const encodedShareLink = encodeURIComponent(shareLink);
        const emailSubject = encodeURIComponent(shareText);
        const emailBody = encodeURIComponent(`I thought you might be interested in this workshop: ${event.name}. Here's the link: ${shareLink}`);

        console.log(`Sharing via: ${option}`); // For debugging

        switch (option) {
            case 'WhatsApp':
                window.open(`https://wa.me/?text=${encodedShareText} - ${encodedShareLink}`, '_blank');
                break;
            case 'Email':
                window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;
                break;
            case 'Facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}`, '_blank');
                break;
            case 'X':
                window.open(`https://x.com/intent/tweet?url=${encodedShareLink}&text=${encodedShareText}`, '_blank');
                break;
            case 'LinkedIn':
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareLink}`, '_blank');
                break;
            case 'Copy Link':
                navigator.clipboard.writeText(shareLink).then(() => {
                    toast.current.show({ severity: 'success', detail: 'Link copied to your clipboard.' });
                }).catch(err => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Could not copy the link.' });
                    console.error('Could not copy text: ', err);
                });
                break;
            default:
                break;
        }

        closeDropdown();
    };

    if (!event) {
        return <div className="ued-details-container">
            <div>Event not found</div>
        </div>;
    }

    return (
        <div>
            <Header />
            <div className="ued-details-container">
                <Toast ref={toast} />
                <div className='ued-banner' style={{ backgroundImage: `url(${event.headerImage})` }}>
                    <div className='grid'>
                        <div className='banner-left md:col-12 lg:col-7 xl:col-7'>
                            <div className='ued-heading'>{event.workshopName}</div>
                            {/* <div className='by'>By Startup2Launch</div> */}
                            <div className='ued-desc'>{event.description}</div>
                        </div>
                        <div className='banner-right md:col-12 lg:col-5  xl:col-5'>
                            <div className='ued-b-card'>
                                <div className='ued-date-title'>Date & Time</div>
                                <div className='ued-date-details'>
                                    <div className='ued-date'>{event.date},</div>
                                    <div className='ued-time'>{event.time}</div>
                                </div>
                                <div className='ued-duration'><FaClock className='ued-clock mr-2' />{event.duration}</div>
                                <div className='ued-price'>{event.price}</div>
                                <div className='reg-but'>
                                    <Button
                                        label="Register Here" style={{ width: '100%' }} className='register-button'
                                        onClick={() => window.open(event.registerLink, '_blank')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='ued-content'>
                    <div className='ued-sections'>
                        <div className='ued-ls'>
                            <div className='ued-sideheadings'>Location:</div>
                            <div className='share-dropdown'>
                                <div className='pr-1 pt-1'><FaShareSquare /></div>
                                <div>
                                    <div onClick={toggleDropdown} style={{ cursor: 'pointer', fontSize: '20px' }}>Share</div>
                                    {isDropdownOpen && (
                                        <ul ref={dropdownRef} className='dropdown-wdmenu'>
                                            {/* <li onClick={() => handleShare('Email')}>
                                            <MdEmail className='social-icon' />Email</li> */}
                                            <li onClick={() => handleShare('WhatsApp')}>
                                                <IoLogoWhatsapp className='social-icon' style={{ color: '#25D366' }} />WhatsApp</li>
                                            <li onClick={() => handleShare('Facebook')}>
                                                <FaFacebook className='social-icon' style={{ color: '#316FF6' }} />Facebook</li>
                                            <li onClick={() => handleShare('X')}>
                                                <FaSquareXTwitter className='social-icon' />X</li>
                                            <li onClick={() => handleShare('LinkedIn')}>
                                                <IoLogoLinkedin className='social-icon' style={{ color: '#0077B5' }} />LinkedIn</li>
                                            <CopyToClipboard text={window.location.href}>
                                                <li onClick={() => handleShare('Copy Link')}>
                                                    <FaLink className='social-icon' />Copy Link</li>
                                            </CopyToClipboard>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='ued-location-details'><FaLocationDot style={{ color: 'green', fontSize: '18px' }} />&nbsp;{event.location}</div>
                        <div className='ued-map'>
                            <MapComponent latitude={event.latitude} longitude={event.longitude} />
                        </div>
                    </div>

                    <div className='ued-sections'>
                        <div className='ued-sideheadings'>{event.about.title}</div>
                        <div className='ued-topics'>
                            <div className='ued-about'>
                                <p>{event.about.duration}</p>
                                <p>{event.about.description}</p>
                                <p>{event.about.note}</p>
                                <p>{event.about.help}</p>
                            </div>
                        </div>
                    </div>

                    <div className='ued-sections'>
                        <div className='ued-sideheadings'>Agenda:</div>
                        <div className='ued-topics'>
                            {event.agenda.map((item, index) => (
                                <div key={index} className="ue-agenda-item" style={{ backgroundColor: colors[index % colors.length], borderLeft: `2px solid ${backgroundColors[index % backgroundColors.length]}` }}>
                                    <div className="ue-agenda-time">{item.time}</div>
                                    <div className="ue-agenda-title">{item.title}</div>
                                    <div className="ue-agenda-details">{item.details}</div>
                                    <div className="ue-agenda-note">{item.note}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='ued-sections'>
                        <div className='ued-sideheadings'>Topics:</div>
                        <p className='ued-about'>{event.topics.title}</p>
                        <div className='ued-topics'>
                            {event.topics.items.map((topic, index) => (
                                <div key={index}>
                                    <strong>{topic.name}</strong>
                                    <ul>
                                        {topic.subpoints.map((subpoint, subIndex) => (
                                            <li key={subIndex}>{subpoint}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>

                    {event.guest && event.guest.length > 0 && (
                    <div className='ued-sections'>
                        <div className='ued-sideheadings'>Guest Speaker</div>
                        <div className='ued-topics'>
                            {event.guest.map((guest, index) => (
                                <div key={index}>
                                    <p className='speaker-name'>{guest.name}</p>
                                    <div className='guest-designation'><i>{guest.designation}</i></div>
                                    {/* <div className='guest-description'>{guest.description}</div> */}
                                    <div className='guest-description mt-3'>{guest.fullDescription}</div>
                                    {/* <ul>
                                        {guest.subpoints.map((subpoint, subIndex) => (
                                            <li key={subIndex}>{subpoint}</li>
                                        ))}
                                    </ul> */}
                                </div>
                            ))}
                        </div>
                    </div>
                    )}

                    <div className='ued-sections'>
                        <div className='ued-sideheadings'>Benefits of Attending:</div>
                        <div className='ued-topics'>
                            {event.benefits.map((benefit, index) => (
                                <div key={index}>
                                    {/* <strong>{benefit.name}</strong> */}
                                    <ul>
                                        {benefit.subpoints.map((subpoint, subIndex) => (
                                            <li key={subIndex}>{subpoint}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* <div className='ued-sections'>
                        <div className='ued-sideheadings'>Speaker</div>
                        {event.guest.map((guest, index) => (
                            <div key={index} className='grid guest'>
                                <div className='col-12 md:col-12 lg:col-3 xl:col-2'>
                                    <img src={guest.Image} alt={guest.name} className='guest-image' />
                                </div>
                                <div className='col-12 md:col-12 lg:col-9 xl:col-10 guest-info'>
                                    <div className='guest-content'>
                                        <div>
                                            <div className='guest-name'>{guest.name}</div>
                                            <div className='guest-designation'>{guest.designation}</div>
                                        </div>
                                        <div>
                                            <Link to={`/speaker/${guest.name.replace(/\s+/g, '-').toLowerCase()}`} style={{ textDecoration: 'none' }} className="front-link">See Speaker Profile</Link> &gt;
                                        </div>
                                    </div>
                                    <div className='guest-description'>{guest.description}</div>
                                </div>
                            </div>
                        ))}
                    </div> */}

                </div>

                <div className='wd-social-media'>
                    {/* <div onClick={() => handleShare('Email')}>
                            <MdEmail className='social-icon' /></div> */}
                    <div onClick={() => handleShare('WhatsApp')}>
                        <IoLogoWhatsapp className='social-icon' style={{ color: '#25D366' }} /></div>
                    <div onClick={() => handleShare('Facebook')}>
                        <FaFacebook className='social-icon' style={{ color: '#316FF6' }} /></div>
                    <div onClick={() => handleShare('X')}>
                        <FaSquareXTwitter className='social-icon' /></div>
                    <div onClick={() => handleShare('LinkedIn')}>
                        <IoLogoLinkedin className='social-icon' style={{ color: '#0077B5' }} /></div>
                    <CopyToClipboard text={window.location.href}>
                        <div onClick={() => handleShare('Copy Link')}>
                            <FaLink className='social-icon' /></div>
                    </CopyToClipboard>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default UpcomingEventsDetails;