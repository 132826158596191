import React, { useEffect, useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import "./InfoPage.scss";
import axios from 'axios';
import { USER_INFO_URL } from '../../../Utils/APIService';
import { CONTENT_HEADERS } from '../../../Utils/LandingService';
import StackNavbar from "../../../Shared-Components/StackNavbar/StackNavbar";
import { useNavigate } from 'react-router-dom';
import parentInfoImg from "../../../Assets/Authentication/parentinfo.png";
import generalInfoImg from "../../../Assets/Authentication/generalinfo.png";
import AreaofInterest from "../../../Assets/Authentication/AreaofInterest.png";
import { Accordion, AccordionTab } from "primereact/accordion";

const InfoPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeAccordian, setActiveAccordian] = useState(0);
  const [, setDOB] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [emailValidation, setemailValidation] = useState("");
  const [formData, setFormData] = useState({
    parentEmail: '',
    parentFirstName: '',
    parentLastName: '',
    parentPhoneNumber: '',
    areaOfInterest: [],
    firstName: '',
    middleName: '',
    lastName: '',
    schoolName: '',
    schoolGrade: null,
    phoneNumber: '',
    dob: null,
  });

  const navigate = useNavigate();

  const tabs = [
    { label: 'Parent Information' },
    { label: 'General Information' },
    { label: 'Areas of Interest' }
  ];

  const gradeOptions = [
    { label: "Grade 6", value: "Grade 6" },
    { label: "Grade 7", value: "Grade 7" },
    { label: "Grade 8", value: "Grade 8" },
    { label: "Grade 9", value: "Grade 9" },
    { label: "Grade 10", value: "Grade 10" },
    { label: "Grade 11", value: "Grade 11" },
    { label: "Grade 12", value: "Grade 12" },
  ];

  const interestOptions = [
    {
      name: 'Digital Marketing and E-commerce',
      key: 'Digital Marketing and E-commerce',
      describe: 'SEO, social media marketing, content creation, and managing online stores.'
    },
    {
      name: 'Technology and Innovation',
      key: 'Technology and Innovation',
      describe: 'Coding, app development, and emerging technologies like AI and blockchain.'
    },
    {
      name: 'Financial Management',
      key: 'Financial Management',
      describe: 'Advanced financial planning, investment strategies, and financial analysis.'
    },
    {
      name: 'Social Entrepreneurship',
      key: 'Social Entrepreneurship',
      describe: 'Developing businesses with a social impact focus, sustainable practices, and CSR.'
    },
  ];


  const validateForm = () => {
    switch (activeIndex) {
      case 0:
        return (
          formData.parentEmail &&
          formData.parentFirstName &&
          formData.parentLastName &&
          formData.parentPhoneNumber
        );
      case 1:
        return (
          formData.firstName &&
          formData.lastName &&
          formData.phoneNumber &&
          formData.dob &&
          formData.schoolGrade &&
          formData.schoolName
        );
      case 2:
        return formData.areaOfInterest.length > 0;
      default:
        return false;
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (e) => {
    const selectedDate = e.value;
    setDOB(selectedDate);
    setFormData({ ...formData, dob: selectedDate });
  };

  useEffect(() => {
    setIsValid(validateForm());
  }, [formData, activeIndex]);


  const handleInterestChange = (e) => {
    let selectedInterests = [...formData.areaOfInterest];
    if (e.checked)
      selectedInterests.push(e.value);
    else
      selectedInterests = selectedInterests.filter(item => item !== e.value);

    setFormData({ ...formData, areaOfInterest: selectedInterests });
  };

  const handleContinue = () => {
    if (activeIndex < tabs.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {

      const information = {
        ...formData,
        dob: formData.dob ? formData.dob.toLocaleDateString() : "",
        userId: localStorage.getItem('userId'),
      }

      axios
        .post(USER_INFO_URL, information, { headers: { Authorization: `Bearer ${localStorage.getItem('userAccessToken')}` } })
        .then((response) => {
          navigate("/dashboard")
        })
        .catch((error) => {
          console.log(error);
        })
    }
  };

  const handleBack = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const validateEmail = (email) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);

};



  const handleEmailChange = (e) => {

    const emailValue = e.target.value;

    setFormData({ ...formData, [e.target.name]: e.target.value });

    setemailValidation(

        validateEmail(emailValue) ? "" : "Please enter a valid email address"

    );

};



  const renderParentInformation = () => (
    <div className="additionalInfo-Section">
      <div className="p-fluid p-6 pb-0">
        <h2 className="mb-4">Parent or Legal Guardian Information</h2>
        <p>
          All the students under 18 years old should provide informaion about his/her parent or guardian.
          Use the same email that your parent or legal guardian use to register to the 8week course.
        </p>

                <div className="info-form-sec">
                    <div className="mb-3">
                        <label htmlFor="parentEmail" className="block mb-2">Email</label>
                        <InputText id="parentEmail" type='email' name="parentEmail" value={formData.parentEmail} onChange={handleEmailChange} required />
                        {emailValidation && (
                                        <small className="error-message">{emailValidation}</small>
                                    )}

                    </div>

          <div className="mb-3">
            <label htmlFor="parentFirstName" className="block mb-2">First Name of the Parent or Legal Guardian</label>
            <InputText id="parentFirstName" type='text' name="parentFirstName" value={formData.parentFirstName} onChange={handleInputChange} required />
          </div>

          <div className="mb-3">
            <label htmlFor="parentLastName" className="block mb-2">Last Name of the Parent or Legal Guardian</label>
            <InputText id="parentLastName" type='text' name="parentLastName" value={formData.parentLastName} onChange={handleInputChange} required />
          </div>

          <div className="mb-3">
            <label htmlFor="parentPhoneNumber" className="block mb-2">Phone Number</label>
            <InputMask id="parentPhoneNumber" name="parentPhoneNumber" value={formData.parentPhoneNumber} mask="(999) 999-9999" placeholder="(999) 999-9999" onChange={handleInputChange} required />
          </div>
        </div>
      </div>
      <div className="parentinfoImg">
        <img src={parentInfoImg} alt="" />
      </div>
    </div>
  );

  const renderGeneralInformation = () => (
    <div className="additionalInfo-Section">
      <div className="p-fluid p-6 pb-0 w-full">
        <h2 className="mb-4">General Information</h2>
        <p>To continue please complete your profile</p>

        <div className="info-form-sec">
          <div className="flex justify-content-between gap-3">
            <div className="p-col-12 p-md-3 mb-3 w-full">
              <label htmlFor="firstName" className="block mb-2">First Name</label>
              <InputText id="firstName" type='text' name="firstName" value={formData.firstName} onChange={handleInputChange} required />
            </div>

            <div className="p-col-12 p-md-3 mb-3 w-full">
              <label htmlFor="middleName" className="block mb-2">Middle Name</label>
              <InputText id="middleName" type='text' name="middleName" value={formData.middleName} onChange={handleInputChange} />
            </div>
          </div>

          <div className="p-col-12 p-md-6 mb-3">
            <label htmlFor="lastName" className="block mb-2">Last Name</label>
            <InputText id="lastName" type='text' name="lastName" value={formData.lastName} onChange={handleInputChange} required />
          </div>

          <div className="p-col-12 p-md-6 mb-3">
            <label htmlFor="phoneNumber" className="block mb-2">Phone Number</label>
            <InputMask id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} mask="(999) 999-9999" placeholder="(999) 999-9999" onChange={handleInputChange} required />
          </div>

          <div className="flex justify-content-between gap-3">
            <div className="p-col-12 p-md-6 mb-3 w-full">
              <label htmlFor="dob" className="block mb-2">Date of Birth</label>
              <Calendar
                id="dob"
                name="dob"
                value={formData.dob}
                onChange={handleDateChange}
                dateFormat='mm/dd/yy'
                placeholder="MM/DD/YYYY"
                required />
            </div>

            <div className="p-col-12 p-md-6 mb-3 w-full">
              <label htmlFor="schoolGrade" className="block mb-2">School Grade</label>
              <Dropdown
                id="schoolGrade"
                name="schoolGrade"
                value={formData.schoolGrade}
                options={gradeOptions}
                onChange={handleInputChange}
                placeholder="Select Grade"
                required
              />
            </div>
          </div>

          <div className="p-col-12 mb-3">
            <label htmlFor="schoolName" className="block mb-2">School Name</label>
            <InputText id="schoolName" type='text' name="schoolName" value={formData.schoolName} onChange={handleInputChange} required />
          </div>
        </div>
      </div>
      <div className="parentinfoImg">
        <img src={generalInfoImg} alt="" />
      </div>
    </div>
  );

  const renderAreasOfInterest = () => (
    <div className="additionalInfo-Section">
      <div className="p-6 pb-0">
        <h2 className="mb-4">Areas of Interest</h2>
        <p>To continue, please select the areas you are interested in studying.</p>

        <div className="flex flex-column gap-3">
          {interestOptions.map((areaOfInterest) => (
            <div key={areaOfInterest.key} className="flex align-items-center">
              <Checkbox
                inputId={areaOfInterest.key}
                name="areaOfInterest"
                value={areaOfInterest.key}
                onChange={handleInterestChange}
                checked={formData.areaOfInterest.includes(areaOfInterest.key)}
              />
              <div>
                <p htmlFor={areaOfInterest.key} className="ml-2" style={{ fontSize: "18px", marginTop: 0, marginBottom: 0 }}>{areaOfInterest.name}</p>
                <p className="ml-2" style={{ fontSize: "18px", color: "#433F3E", marginTop: 0, marginBottom: 0 }}>{areaOfInterest.describe}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="parentinfoImg">
        <img src={AreaofInterest} alt="" />
      </div>
    </div>
  );

  const renderParentInformationHeader = (index) => (
    <div className="additionalInfo-header-accordian w-full flex flex-row align-items-center justify-content-between">
      <span>Parent Information</span>
      <span>
        <i className={`pi ${activeAccordian === index ? "pi-angle-up" : "pi-angle-down"}`} ></i>
      </span>
    </div>
  );

  const renderGeneralInformationHeader = (index) => (
    <div className="additionalInfo-header-accordian w-full flex flex-row align-items-center justify-content-between">
      <span>General Information</span>
      <span>
        <i className={`pi ${activeAccordian === index ? "pi-angle-up" : "pi-angle-down"}`} ></i>
      </span>
    </div>
  );

  const renderAreasOfInterestHeader = (index) => (
    <div className="additionalInfo-header-accordian w-full flex flex-row align-items-center justify-content-between">
      <span>Areas of Interest</span>

      <span>
        <i className={`pi ${activeAccordian === index ? "pi-angle-up" : "pi-angle-down"}`} ></i>
      </span>
    </div>
  );

  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        return renderParentInformation();
      case 1:
        return renderGeneralInformation();
      case 2:
        return renderAreasOfInterest();
      default:
        return null;
    }
  };

  return (
    <>
      <StackNavbar />
      <div className="info-section">
        <TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

        <div className="">
          {renderContent()}
        </div>

        <div className="button-group flex justify-content-end gap-3 my-4 mr-6">
          {activeIndex > 0 && (
            <Button
              className="bck-btn"
              label="Back"
              onClick={handleBack}
            />
          )}
          <Button
            label={activeIndex === tabs.length - 1 ? "Save" : "Continue"}
            className="p-button-lg continue-btn"
            onClick={handleContinue}
            disabled={!isValid}
          />
        </div>
      </div>

      <div className="info-section-accordian">
        <div className="info-accordian-content">
          <Accordion
            activeIndex={activeAccordian}
            onTabChange={(e) => {
              setActiveAccordian(e.index);
            }}
          >
            <AccordionTab header={renderParentInformationHeader(0)}>
              {renderParentInformation()}
            </AccordionTab>
            <AccordionTab header={renderGeneralInformationHeader(1)}>
              {renderGeneralInformation()}
            </AccordionTab>
            <AccordionTab header={renderAreasOfInterestHeader(2)}>
              {renderAreasOfInterest()}
            </AccordionTab>
          </Accordion>
        </div>

        <div className="button-group gap-3 my-4 ">
          <Button
            label={activeIndex === tabs.length - 1 ? "Save" : "Continue"}
            className="p-button-lg continue-btn w-full"
            onClick={handleContinue}
            disabled={!isValid}
          />
        </div>
      </div>
    </>
  );
};

export default InfoPage;
