import { useNavigate, useLocation } from 'react-router-dom';
import React from "react";
import "./BestPlan.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import { Button } from "primereact/button";
import CalendlyComponent from '../../../../../Shared-Components/Calendly/Calendly';

const planDetails = {
    starter: {
        title: "Starter Plan",
        subtitle: "Ignite Your Business Dream Today",
        bestFor: [
            "Early-stage entrepreneurs or students in the ideation phase.",
            "Those with an idea needing refinement, validation, and a basic launch strategy."
        ],
        price: "1K",
        duration: "4 weeks",
        equity: "1%",
        includes: [
            {
                title: "Ideation Session",
                description: "Work with experts to refine and articulate your idea clearly."
            },
            {
                title: "Product Market Fit Report",
                description: "Assess the viability of your idea in the target market?"
            },
            {
                title: "Preliminary Business Plan",
                description: "Develop an initial roadmap for your startup."
            },
            {
                title: "Home Page Design",
                description: "Get a basic online presence for your business."
            },
            {
                title: "Launch Plan",
                description: "A step-by-step plan to bring your idea to market."
            }
        ]
    },
    standard: {
        title: "Standard Launch Plan",
        subtitle: "Transform Your Vision into Reality",
        bestFor: [
            "Entrepreneurs who have a validated idea and are ready to build and launch their product.",
            "Those who need comprehensive support across business modeling, branding, and marketing."
        ],
        price: "25K",
        duration: "15 weeks",
        equity: "5%",
        includes: [
            {
                title: "All features available in the Starter Plan.",
            },
            {
                title: "Idea Validation",
                description: "Ensure your idea is market-ready through validation sessions."
            },
            {
                title: "Business Modeling",
                description: "Develop a detailed business model for long-term growth."
            },
            {
                title: "Brand and Identity",
                description: "Craft a compelling brand strategy and visual identity."
            },
            {
                title: "Product Development",
                description: "Begin building and refining your product or service."
            },
            {
                title: "Marketing and Launch",
                description: "Develop a targeted marketing plan and launch strategy."
            }
        ]
    },
    premium: {
        title: "Premium Launch Plan",
        subtitle: "From Groundbreaking to Game-Changing",
        bestFor: [
            "Entrepreneurs ready to fully launch, scale, and optimize their business.",
            "Those looking for an advanced program with extensive support in product development, marketing, and growth planning."
        ],
        price: "25K",
        duration: "18 weeks",
        equity: "10%",
        includes: [
            {
                title: "All features available in the Standard Launch Plan."
            },
            {
                title: "Enhanced Product Development",
                description: "Deep dive into product design and feature enhancement."
            },
            {
                title: "Multi-Channel Marketing",
                description: "Leverage diverse marketing channels for maximum reach."
            },
            {
                title: "Legal and Compliance Advisory",
                description: "Ensure your business adheres to legal requirements."
            },
            {
                title: "Sales Optimization and CRM",
                description: "Set up customer management systems for growth."
            },
            {
                title: "Post-Launch Support",
                description: "Continued assistance and monitoring post-launch."
            },
            {
                title: "Growth Planning",
                description: "Develop strategies for scaling and expanding your business."
            }
        ]
    },
};

const BestPlan = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [recommendedPlan, setRecommendedPlan] = React.useState('starter');

    React.useEffect(() => {
        console.log(location.state);
        console.log(location.state.answers);
        if (location.state?.answers) {
            const answers = location.state.answers;

            const userType = answers[1];

            const hasIdea = answers[2];

            const businessStage = answers[4];

            if (hasIdea === "No") {
                setRecommendedPlan('starter');
            } else if (businessStage === "Idea Stage") {
                setRecommendedPlan('starter');
            } else if (businessStage === "Development Stage") {
                setRecommendedPlan(userType === "Student" ? 'standard' : 'premium');
            } else if (businessStage === "Early Launch Stage") {
                setRecommendedPlan('premium');
            } else if (businessStage === "Growth Stage") {
                setRecommendedPlan('premium');
            }
        }
    }, [location.state]);

    const plan = planDetails[recommendedPlan];

    const pageSettings = {
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '#8DAF61',
        textColor: '#352750'
    };

    const handlePlans = () => {
        navigate("/programs");
    };

    return (
        <>
            <Header />

            <div className="starter-plan-container">
                <h2 className="section-title">The Best plan for you is</h2>
                <div className="bestPlan-card">
                    <div className="bestPlan-details">
                        <div className="bestPlan-title"></div>
                        <div className="bestPlan">
                            <h3 className="bestPlan-plan-title">{plan.title}</h3>
                            <h3 className="bestPlan-subtitle">
                                {plan.subtitle}
                            </h3>
                            <p className="best-for">Best For:</p>
                            <ul>
                                {plan.bestFor.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                            <div className="bestPlan-price">
                                <span className="price-value">{plan.price}</span>&nbsp;
                                <span className="price-currency"> <span> /USD</span></span>
                            </div>
                            <div className="bestPlan-info">
                                <p>Duration: {plan.duration} | Equity Stake: {plan.equity}</p>
                            </div>
                        </div>
                    </div>
                    <div className="bestPlan-includes">
                        <h3>What Include:</h3>
                        <ul>
                            {plan.includes.map((item, index) => (
                                <li key={index}>
                                    <FontAwesomeIcon icon={faCheck} />{" "}
                                    <b>{item.title}:</b>{" "}
                                    {item.description}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="bestPlan-buttons">
                    <Button
                        className="secondary-btn button-style-primary-square-line"
                        onClick={handlePlans}
                    >
                        See other plans
                    </Button>
                    <Button className="primary-btn">
                        <CalendlyComponent
                            buttonLabel="Schedule an Appointment to Talk"
                            pageSettings={pageSettings}
                            url="https://calendly.com/contactus-s2l/30min"
                        />
                    </Button>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default BestPlan;