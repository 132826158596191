export const BASE_URL = `${process.env.REACT_APP_URL}`;

export const MULTIPART_URL = {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

export const CONTENT_AUTHORIZE_URL = {
    "content-type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};

// Authentication Endpoints

export const UNIQUEID_URL = `${BASE_URL}/uniqueId`;

export const LOGIN_URL = `${BASE_URL}/log_in`;

export const SIGNUP_URL = `${BASE_URL}/sign_up`;

export const CONFIRMATION_URL = `${BASE_URL}/sign_up/confirmation`;

export const RESEND_CONFIRMATION_URL = `${BASE_URL}/resend_confirmation_code/`;

export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgot_password/`;

export const RESET_PASSWORD_URL = `${BASE_URL}/forgot_password/confirm_code`;

export const FORGOT_PASSWORD_RESEND_URL = `${BASE_URL}/forgot_password/resend_code`;

export const DELETE_USER_URL = `${BASE_URL}/delete_user/`;

export const LOGOUT_URL = `${BASE_URL}/sign_out/`;

export const USER_INFO_URL = `${BASE_URL}/user_information`;

export const USER_INFO_ID_URL = `${BASE_URL}/user_information/`;

export const CUSTOM_BOT_STYLE_URL = `${BASE_URL}/customize_bot`;

export const GET_CUSTOM_BOT_URL = `${BASE_URL}/get_chat_bot`;

export const USER_INFO = `${BASE_URL}/user_information/${localStorage.getItem('userId')}`;

export const CHAT_URL = `${BASE_URL}/chat`;

export const GET_MESSAGE_URL = `${BASE_URL}/chat_messages/`;

export const GET_ADMIN_INFO_URL = `${BASE_URL}/admin_information/`;

export const PAYMENT_EDIT_URL = `${BASE_URL}/update_account_status`;

export const COURSE_CREATE_URL = `${BASE_URL}/course`;

export const GET_COURSE_URL = `${BASE_URL}/course`;

export const UPDATE_COURSE_URL = `${BASE_URL}/course/update_course`;

export const CREATE_TOPIC_URL = `${BASE_URL}/course/add_topic`;

export const GET_TOPIC_LIST_URL = `${BASE_URL}/course/topic_list/`;

export const UPDATE_TOPIC_URL = `${BASE_URL}/course/update_topic`;

export const DELETE_TOPIC_URL = `${BASE_URL}/course/topic`;

export const QUILL_UPLOAD_URL = `${BASE_URL}/upload`;

export const CHANGE_PASSWORD = `${BASE_URL}/change_password`


// Student Dashboard Endpoints

export const GET_ALL_COURSES = `${BASE_URL}/course`;

export const GET_ENROLLEDCOURSE_BYID = `${BASE_URL}/course/enrolled_courses/`;

export const GET_TOPIC_LISt = `${BASE_URL}/course/topic_list/`;

export const POST_ADD_TOPIC = `${BASE_URL}/add_note`;

export const GET_ALL_NOTES_BY_USER = `${BASE_URL}/get_all-notes`;

export const DELETE_NOTE = `${BASE_URL}/delete_note`;

export const UPDATE_NOTE = `${BASE_URL}/update_note`;

export const POST_ENROLL_COURSE = `${BASE_URL}/course/enroll`;

export const GET_ALL_NOTES_BY_USER_TOPIC = `${BASE_URL}/get_topic_notes`;



