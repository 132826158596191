import React from "react";
import "./Academy.scss";
import AcademyHeader from "./Academy-Header/AcademyHeader";
import AcademyPrograms from "./Programs/AcademyPrograms";
import Features from "./Features/Features";
import AcademyEvents from "./Events/AcademyEvents";
import PastEvents from "./PastEvents/PastEvents";
import { AcademyStudent } from "./Students/AcademyStudent";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";

const Academy = () => {
  return (
    <div className="noSelect">
      <Header/>
      <AcademyHeader />
      <AcademyPrograms />
      <Features />
      <AcademyEvents />
      <PastEvents />
      <AcademyStudent />
      <Footer />
    </div>
  );
};

export default Academy;
