import React, { useState } from "react";
import "./AcademyStudent.scss";
import annotation from "../../../Assets/Academy/Academy-student-annotation.png";
import { Carousel } from "primereact/carousel";

export const AcademyStudent = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const data = [
        {
            id: 1,
            name: "Luke Lizardo",
            achievements:
                "Launched a tutoring platform connecting students with mentors.",
            grade: "Grade 8",
            video: "https://staticfile.startup2launch.com/LukeLizardo.mp4"
        },
        {
            id: 2,
            name: "Shruthi Mohan",
            achievements:
                "Started a sustainable business reducing plastic waste in schools.",
            grade: "Grade 9",
            video: "https://staticfile.startup2launch.com/ShruthiMohan.mp4"
        },
        {
            id: 3,
            name: "Anvitha Rayala",
            achievements:
                "Created an app to help students with homework organization.",
            grade: "Grade 10 ",
            video: "https://staticfile.startup2launch.com/AnvithaRayala.mp4"
        },
        {
            id: 4,
            name: "Lakshita",
            achievements:
                "Launched a tutoring platform connecting students with mentors.",
            grade: "Grade 11",
            video: "https://staticfile.startup2launch.com/Lakshita.mp4"
        },
        {
            id: 5,
            name: "Jimy Rubio",
            achievements:
                "Launched a tutoring platform connecting students with mentors.",
            grade: "Grade 12",
            video: "https://staticfile.startup2launch.com/JimyRubio.mp4"
        }
    ];

    const productTemplate = (product) => {
        return (
            <div className="product-container mx-5">
                <div className="academy-student-profile flex flex-row gap-3 justify-content-center">
                    <div className="student-profile-card" key={product.id}>
                        <div className="stud-profile-pic">
                            <video src={product.video} alt="student" className="student" controls />
                            <img src={annotation} alt="annotation" className="annotation" />
                        </div>
                        <div className="student-profile-card-content">
                            <h3 className="student-name">{product.name}</h3>
                            <p className="student-grade">{product.grade}</p>
                            <p className="student-achievements">{product.achievements}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const onPrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const onNext = (visible) => {
        if (visible === 2) {
            if (activeIndex < data.length - 2) {
                setActiveIndex(activeIndex + 1);
            }
        } else {
            if (activeIndex < data.length - 1) {
                setActiveIndex(activeIndex + 1);
            }
        }

    };

    return (
        <div className="s2l-academy-students">
            <div className="students-container">
                <h2>Meet Our Students: Future Leaders and Innovators!</h2>
                <p>
                    S2L has a growing community of successful alumni who have launched
                    startups, developed apps, and created social impact projects. See
                    their stories and be inspired.
                </p>

                <div className="students-data mt-5">
                    <Carousel
                        className="lg-screen-carousel"
                        value={data}
                        numVisible={2}
                        numScroll={1}
                        itemTemplate={productTemplate}
                        page={activeIndex}
                        onPageChange={(e) => setActiveIndex(e.page)}
                    />
                    <Carousel
                        className="sm-screen-carousel"
                        value={data}
                        numVisible={1}
                        numScroll={1}
                        itemTemplate={productTemplate}
                        page={activeIndex}
                        onPageChange={(e) => setActiveIndex(e.page)}
                    />
                </div>

                <div className="carousel-navigatoion flex flex-row justify-content-center gap-3 mt-4">
                    <span
                        className={`cursor-nav cursor-pointer prev ${activeIndex === 0 ? `disabled` : `active`
                            }`}
                        onClick={onPrev}
                    >
                        <i className="pi pi-arrow-left"></i>
                    </span>

                    <span
                        className={`cursor-nav cursor-nav-lg cursor-pointer next ${activeIndex >= data.length - 2 ? `disabled` : `active`
                            }`}
                        onClick={() => onNext(2)}
                    >
                        <i className="pi pi-arrow-right"></i>
                    </span>

                    <span
                        className={`cursor-nav-sm cursor-pointer next ${activeIndex >= data.length - 1 ? `disabled` : `active`
                            }`}
                        onClick={() => onNext(1)}
                    >
                        <i className="pi pi-arrow-right"></i>
                    </span>
                </div>
            </div>
        </div>
    );
};
