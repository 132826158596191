import event1 from '../../../Assets/UpcomingEvents/Event_background.png';
import headerImage from '../../../Assets/UpcomingEvents/Header_image.png';
import guest1 from '../../../Assets/Workshops/Workshop1/Speaker.png';

const UEData = [
    {
        "url": "w4/Startup Foundations for Teens: Your path to Entrepreneurship",
        "workshopName": "Startup Foundations for Teens: Your path to Entrepreneurship",
        "subname": "When you list 'Founder' on your college application, you're not just another student.",
        "closingDate": "14",
        "closingMonth": "September",
        "date": "September 14, Saturday",
        "time": "2pm - 4pm CDT",
        "location": "Davis Library, 7501 Independence Parkway, Plano, TX 75025",
        "companyName": "Startup2Launch",
        "guest": null,
        "price": "Free Event",
        "description": "Join our Entrepreneurship Masterclass to learn from industry experts and secure valuable internship opportunities!",
        "registerLink": "https://www.eventbrite.com/e/free-in-personstartup-foundations-for-teens-your-path-to-entrepreneurship-tickets-998875001017?aff=oddtdtcreator",
        "duration": "2 hours",
        "about": {
            "title": "About this event: Entrepreneurship Masterclass with Internship Opportunities",
            "duration": "Event lasts 2 hours",
            "description": "Join us for an exciting event at the Flower Mound Library where you can learn from successful entrepreneurs and explore internship opportunities. This in-person masterclass will cover key aspects of starting and growing a business, with a focus on practical skills and real-world experiences. Don't miss out on this chance to network, gain valuable insights, and potentially kickstart your entrepreneurial journey. Register now!",
            "note": "After reserving your spot, please expect an email from @Startup2Launch confirming your registration within 24hrs. All workshop participants must complete the Registration Form to attend.",
            "help": "Need more information - Email: academy@startup2launch.com (or) Call: (214)766-0767"
        },
        "latitude": "33.080201664308944",
        "longitude": "-96.7517877270232",
        "benefits": [
            {
                "name": "1",
                "subpoints": [
                    "Sharpen your academic and Essential life skills.",
                ]
            },
            {
                "name": "2",
                "subpoints": [
                    "Get a head start on college prep with College application strategy and career goals.",
                ]
            },
            {
                "name": "3",
                "subpoints": [
                    "Learn the best study practices and Time Management skills",
                ]
            },
            {
                "name": "4",
                "subpoints": [
                    "Acquire practical, real-life skills for future success.",
                ]
            },
        ],
        "topics": {
            "title": "Engage with mentors and tackle real-world challenges.",
            "items": [
                {
                    "name": "Think Differently",
                    "subpoints": [
                        "Harness the power of First Principles Thinking."
                    ]
                },
                {
                    "name": "Manage Your Time:",
                    "subpoints": [
                        "Learn techniques to organize and prioritize effectively."
                    ]
                },
                {
                    "name": "Start It Up",
                    "subpoints": [
                        "Get the lowdown on launching and running your own business."
                    ]
                },
                {
                    "name": "Interactive Sessions",
                    "subpoints": [
                        "Engage in lively discussions and hands-on activities."
                    ]
                },
                {
                    "name": "Networking Opportunities",
                    "subpoints": [
                        "Meet like-minded peers and experienced mentors."
                    ]
                },
                {
                    "name": "Practical Learning",
                    "subpoints": [
                        "Apply what you learn immediately to your daily life and future ambitions."
                    ]
                },
            ]
        },
        "agenda": [
            {
                "time": "1:45 pm - 2 pm",
                "title": "CHECK-IN AND GET READY FOR WORKSHOP",
                "details": "The Workshop starts with a warm welcome and check-in process, followed by the assignment seats.",
                "note": "(15 MINS Early)"
            },
            {
                "time": "2pm - 2:15 pm",
                "title": "Introduction to S2L Academy (Parents and Students)",
                "details": "FOR PARENTS (15 min) During this session, you will: Meet the passionate individuals driving Startup2Launch Academy. Receive a comprehensive overview of the S2L Academy program offerings. Connect with fellow parents and students while enjoying snacks and refreshments.",
                "note": "(15 min)"
            },
            {
                "time": "2:15 pm - 4:00 pm",
                "title": "Entrepreneurship MasterClass",
                "details": "FOR STUDENTS, NOTE: Please bring a Device for the workshop (Laptop/tablet/phone) We'll kick off by assigning your seat. Then workshop will start where you will learn to :- Think Differently: Harness the power of First Principles Thinking. Boost Your Grades: Get tips and tricks to master your academics. Manage Your Time: Learn techniques to organize and prioritize effectively. Start It Up: Get the lowdown on launching and running your own business. Meet other like-minded students in the workshop and participate in fun quiz/activities You don't need to have a business idea to attend the workshop.",
                "note": "(1 hours 45 mins)"
            }
        ],
        "backgroundImage": event1,
        "headerImage": headerImage,
    },

    {
        "url": "w3/Startup Foundations for teens: Your path to Entrepreneurship",
        "workshopName": "Startup Foundations for teens: Your path to Entrepreneurship",
        "subname": "When you list 'Founder' on your college application, you're not just another student.",
        "closingDate": "8",
        "closingMonth": "September",
        "date": "September 8, Sunday",
        "time": "2pm - 4pm CDT",
        "location": "3030 Broadmoor Ln, Flower Mound, TX 75022",
        "companyName": "Startup2Launch",
        "guest": null,
        "price": "Free Event",
        "description": "Join our Entrepreneurship Masterclass to learn from industry experts and secure valuable internship opportunities!",
        "registerLink": "https://www.eventbrite.com/e/startup-foundations-your-path-to-entrepreneurship-for-teens-tickets-995550286707?aff=oddtdtcreator",
        "latitude": "33.03370425777512",
        "longitude": "-97.07746809285823",
        "duration": "2 hours",
        "about": {
            "title": "About this event: Entrepreneurship Masterclass with Internship Opportunities",
            "duration": "Event lasts 2 hours",
            "description": "Join us for an exciting event at the Flower Mound Library where you can learn from successful entrepreneurs and explore internship opportunities. This in-person masterclass will cover key aspects of starting and growing a business, with a focus on practical skills and real-world experiences. Don't miss out on this chance to network, gain valuable insights, and potentially kickstart your entrepreneurial journey. Register now!",
            "note": "After reserving your spot, please expect an email from @Startup2Launch confirming your registration within 24hrs. All workshop participants must complete the Registration Form to attend.",
            "help": "Need more information - Email: academy@startup2launch.com (or) Call: (214)766-0767"
        },
        "benefits": [
            {
                "name": "1",
                "subpoints": [
                    "Sharpen your academic and Essential life skills.",
                ]
            },
            {
                "name": "2",
                "subpoints": [
                    "Get a head start on college prep with College application strategy and career goals.",
                ]
            },
            {
                "name": "3",
                "subpoints": [
                    "Learn the best study practices and Time Management skills",
                ]
            },
            {
                "name": "4",
                "subpoints": [
                    "Acquire practical, real-life skills for future success.",
                ]
            },
        ],
        "topics": {
            "title": "Engage with mentors and tackle real-world challenges.",
            "items": [
                {
                    "name": "Think Differently",
                    "subpoints": [
                        "Harness the power of First Principles Thinking."
                    ]
                },
                {
                    "name": "Manage Your Time:",
                    "subpoints": [
                        "Learn techniques to organize and prioritize effectively."
                    ]
                },
                {
                    "name": "Start It Up",
                    "subpoints": [
                        "Get the lowdown on launching and running your own business."
                    ]
                },
                {
                    "name": "Interactive Sessions",
                    "subpoints": [
                        "Engage in lively discussions and hands-on activities."
                    ]
                },
                {
                    "name": "Networking Opportunities",
                    "subpoints": [
                        "Meet like-minded peers and experienced mentors."
                    ]
                },
                {
                    "name": "Practical Learning",
                    "subpoints": [
                        "Apply what you learn immediately to your daily life and future ambitions."
                    ]
                },
            ]
        },
        "agenda": [
            {
                "time": "1:45 pm - 2 pm",
                "title": "CHECK-IN AND GET READY FOR WORKSHOP",
                "details": "The Workshop starts with a warm welcome and check-in process, followed by the assignment seats.",
                "note": "(15 MINS Early)"
            },
            {
                "time": "2pm - 2:15 pm",
                "title": "Introduction to S2L Academy (Parents and Students)",
                "details": "FOR PARENTS (15 min) During this session, you will: Meet the passionate individuals driving Startup2Launch Academy. Receive a comprehensive overview of the S2L Academy program offerings. Connect with fellow parents and students while enjoying snacks and refreshments.",
                "note": "(15 min)"
            },
            {
                "time": "2:15 pm - 4:00 pm",
                "title": "Entrepreneurship MasterClass",
                "details": "FOR STUDENTS, NOTE: Please bring a Device for the workshop (Laptop/tablet/phone) We'll kick off by assigning your seat. Then workshop will start where you will learn to :- Think Differently: Harness the power of First Principles Thinking. Boost Your Grades: Get tips and tricks to master your academics. Manage Your Time: Learn techniques to organize and prioritize effectively. Start It Up: Get the lowdown on launching and running your own business. Meet other like-minded students in the workshop and participate in fun quiz/activities You don't need to have a business idea to attend the workshop.",
                "note": "(1 hours 45 mins)"
            }
        ],
        "backgroundImage": event1,
        "headerImage": headerImage,
    },
]

export default UEData;
