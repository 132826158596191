import React, { useState } from "react";
import "./Features.scss";
import AcademyFeatureImage from "../../../Assets/Academy/Academy-feature.png";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const Features = () => {
    const [activeAccordian, setActiveAccordian] = useState(null);
    const navigate = useNavigate();

    const renderEntrepreneurshipHeader = (index) => (
        <div className="feature-accordian-header w-full flex flex-row align-items-center justify-content-between">
            <h3>Entrepreneurship Chatbot</h3>
            <span>
                <i
                    className={`pi ${activeAccordian === index ? "pi-minus" : "pi-plus"}`}
                ></i>
            </span>
        </div>
    );

    const renderAIToolsHeader = (index) => (
        <div className="feature-accordian-header w-full flex flex-row align-items-center justify-content-between">
            <h3>AI Tools for Business Development</h3>
            <span>
                <i
                    className={`pi ${activeAccordian === index ? "pi-minus" : "pi-plus"}`}
                ></i>
            </span>
        </div>
    );

    const renderAITutorialHeader = (index) => (
        <div className="feature-accordian-header w-full flex flex-row align-items-center justify-content-between">
            <h3>Interactive AI Tutorials</h3>

            <span>
                <i
                    className={`pi ${activeAccordian === index ? "pi-minus" : "pi-plus"}`}
                ></i>
            </span>
        </div>
    );
    return (
        <div className="s2l-academy-features">
            <div className="feature-container">
                <div className="feature-header">
                    <h2>
                        Your Digital Learning Hub:
                        <br />
                        Innovate, Collaborate, and Grow!
                    </h2>
                    <p>
                        The S2L Digital Learning Platform provides access to interactive
                        worksheets, peer collaboration tools,
                        <br /> and AI-powered mentorship—available anytime, anywhere.
                    </p>
                </div>

                <div className="feature-content">
                    <div className="content-accordian-img">
                        <div className="acc-img ">
                            <img
                                src={AcademyFeatureImage}
                                alt="Academy Feature"
                                className="s2l-feature h-full"
                            />
                        </div>
                        <div className="accordian-features-content ">
                            <h2 className="key-feature">Key Features:</h2>
                            <Accordion
                                activeIndex={activeAccordian}
                                onTabChange={(e) => {
                                    setActiveAccordian(e.index);
                                }}
                            >
                                <AccordionTab header={renderEntrepreneurshipHeader(0)}>
                                    <p>
                                        Ask questions, get instant responses, and receive
                                        personalized support on your entrepreneurial journey.
                                    </p>
                                </AccordionTab>
                                <AccordionTab header={renderAIToolsHeader(1)}>
                                    <p>
                                        Leverage AI for market research, content creation, and data
                                        analysis to enhance your projects.
                                    </p>
                                </AccordionTab>
                                <AccordionTab header={renderAITutorialHeader(2)}>
                                    <p>
                                        Learn about AI and ML fundamentals through engaging
                                        tutorials and explore how they can be applied in your
                                        business.
                                    </p>
                                </AccordionTab>
                            </Accordion>
                            <div className="features-button">
                                <Button label="Try the Digital Platform Today" onClick={()=>{navigate("/login"); window.scrollTo(0, 0);}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;
