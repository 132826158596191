import React from "react";
import "./FrameWork.scss";
import Mentorship from "../../../Assets/Home/Mentorship.JPG";
import Workshops from "../../../Assets/Home/Workshops.png";
import Resources from "../../../Assets/Home/Resources.png";

const FrameWork = () => {
    const data = [
        {
            title: "Mentorship",
            description: "One-on-one sessions with industry experts.",
            imageUrl: Mentorship,
            borderLineColor: "#EF5064",
        },
        {
            title: "Workshops",
            description: "Practical workshops on business and product development.",
            imageUrl: Workshops,
            borderLineColor: "#0050F5",
        },
        {
            title: "Resources",
            description: "Access to business tools, investor connections, and funding opportunities.",
            imageUrl: Resources,
            borderLineColor: "#F9AB60",
        },
    ];

    return (
        <div className="framework-section">
            <h2>Proven Framework to launch your ideas.</h2>
            <p className="subtitle">
                Through hands-on learning and mentorship, you gain the tools you need
                to build startups and launch your products or services.
            </p>

            <div className="framework-content">
                {data.map((item, index) => (
                    <div className="framework-item" key={index}>
                        <img src={item.imageUrl} alt={item.title} />
                        <h3 style={{ borderBottom: `2px solid ${item.borderLineColor}` }}>
                            {item.title}
                        </h3>
                        <p>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FrameWork;
