import React from 'react';

const Speakers = () => {
    return (
        <div>
            <h2>Speakers</h2>
            {/* Add your speaker list here */}
        </div>
    );
};

export default Speakers;