import React, { useEffect, useRef, useState } from "react";
import { MdNote, MdLightbulbOutline, MdHelpOutline } from "react-icons/md";
import "./MyNotes.scss";
import { ImEnlarge2 } from "react-icons/im";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { Dialog } from "primereact/dialog"; // Assuming you're using PrimeReact
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import {
  CREATE_TOPIC_URL,
  DELETE_NOTE,
  GET_ALL_NOTES_BY_USER,
  GET_ENROLLEDCOURSE_BYID,
  GET_TOPIC_LIST_URL,
  POST_ADD_TOPIC,
  QUILL_UPLOAD_URL,
  UPDATE_NOTE,
} from "../../../Utils/APIService";
import axios from "axios";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Editor } from "primereact/editor";

const MyNotes = () => {
  const toast = useRef(null);
  const editorRef = useRef(null);
  const userId = localStorage.getItem("userId");
  const userAccessToken = localStorage.getItem("userAccessToken");
  const [viewDialog, setViewDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});
  const [noteTitle, setNoteTitle] = useState("");
  const [noteType, setNoteType] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseTopic, setCourseTopic] = useState("");
  const [dialogeAction, setDialogeAction] = useState("");
  const [newNoteTitle, setNewNoteTitle] = useState("");
  const [newNoteContent, setNewNoteContent] = useState("");
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [myNotesData, setMyNotesData] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState({});
  const [updateTopicContent, setUpdateTopicContent] = useState("");
  const [filterCourseName, setFilterCourseName] = useState("");
  const [filterCourseTopic, setFilterCourseTopic] = useState("");
  const [selectTopicDisabled, setSelectTopicDisabled] = useState(true);
  const [uploadImg, setUploadImg] = useState();
  const [renderKey, setRenderKey] = useState(0);

  const noteTypes = [
    { label: "Class Notes", value: "Class Notes" },
    { label: "New Idea", value: "New Idea" },
    { label: "Question", value: "Question" },
  ];

  const config = {
    headers: { Authorization: `Bearer ${userAccessToken}` },
  };

  useEffect(() => {
    const getEnrolledCourses = () => {
      const enrolledCourseResponse = axios
        .get(`${GET_ENROLLEDCOURSE_BYID}${userId}`, config)
        .then((response) => {
          setEnrolledCourses(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const getAllNotesByUser = () => {
      const notesResponse = axios
        .get(GET_ALL_NOTES_BY_USER, {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
          },
          params: {
            userId: userId,
          },
        })
        .then((response) => {
          setMyNotesData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getEnrolledCourses();

    if (!editDialog) {
      getAllNotesByUser();
    }
  }, [userId, editDialog, renderKey]);

  const getTopicDataByCourse = (course) => {
    const topicDataResponse = axios
      .get(GET_TOPIC_LIST_URL + `${course.value.courseId}`, config)
      .then((response) => {
        setTopicData(response.data);
        setSelectTopicDisabled(false);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const createNote = () => {
    const payload = {
      noteTitle: newNoteTitle,
      noteType: selectedNote,
      noteContent: newNoteContent,
      topicId: courseTopic?.topicId,
      userId: userId,
    };

    const createNoteresponse = axios
      .post(POST_ADD_TOPIC, payload, config)
      .then((response) => {
        closeDialog();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredNotes = () => {
    let noteData = myNotesData;

    if (filterCourseName) {
      noteData = noteData.filter(
        (note) => note.courseId === filterCourseName.courseId
      );
    }

    if (filterCourseName && filterCourseTopic) {
      noteData = noteData.filter(
        (note) => note.topicId === filterCourseTopic.topicId
      );
    }
    return noteData;
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setSelectedEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateNote = () => {
    const payload = {
      noteId: selectedEditData.noteId,
      noteTitle: selectedEditData.noteTitle,
      noteType: selectedEditData.noteType,
      noteContent: updateTopicContent,
      topicId: selectedEditData.topicId,
      userId: userId,
    };
    const updateNoteresponse = axios
      .put(UPDATE_NOTE, payload, config)
      .then((response) => {
        closeDialog();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ImageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem(
                "userAccessToken"
              )}`,
            },
          };
          const res = await axios
            .post(QUILL_UPLOAD_URL, formData, config)
            .then((response) => {
              const imageUrl = response.data;

              const quill = editorRef.current.getQuill();
              const range = quill.getSelection();
              quill.insertEmbed(range.index, "image", imageUrl);

              setUploadImg(response.data);
            })
            .catch((error) => console.error(error));
        } catch (err) {
          console.error("Error uploading image:", err);
        }
      }
    };
  };

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
      ],
      handlers: {
        image: ImageHandler,
      },
    },
  };

  const getNoteIcon = (type) => {
    switch (type) {
      case "Class Notes":
        return <MdNote className="note-icon" />;
      case "New Idea":
        return <MdLightbulbOutline className="note-icon" />;
      case "Question":
        return <MdHelpOutline className="note-icon" />;
      default:
        return null;
    }
  };

  const openViewDialog = (note) => {
    setSelectedNote(note);
    setViewDialog(true);
  };

  const openEditDialog = (note) => {
    setSelectedEditData(note);
    setUpdateTopicContent(note?.noteContent);
    setEditDialog(true);
  };

  const handleNewNote = () => {
    setEditDialog(true);
  };

  const closeDialog = () => {
    setViewDialog(false);
    setEditDialog(false);
    setSelectedNote("");
    // setTopicData([]);
    setNewNoteContent("");
    setNewNoteTitle("");
    setEnrolledCourses([]);
    setCourseName("");
  };

  const handleAddNote = (action) => {
    setDialogeAction(action);
    setEditDialog(true);
  };

  const handleDialogeAction = (action, note) => {
    setDialogeAction(action);
    setSelectedNote(note);
    openEditDialog(note);
  };

  const formatDate = (date) => {
    const parsedDate = new Date(date);
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = parsedDate.getDate().toString().padStart(2, "0");
    const year = parsedDate.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  };
  const handleAccept = (note) => {
    const deleteNoteResponse = axios
      .delete(DELETE_NOTE, {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
        params: {
          noteId: note?.noteId,
        },
      })
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Confirmed",
          detail: `${response.data}`,
          life: 3000,
        });
        setRenderKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleReject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Cancelled",
      detail: "Your note remains intact",
      life: 3000,
    });
  };

  const confirmNoteDelete = (note) => {
    confirmDialog({
      message: (
        <span className="custom-message">
          <i className="pi pi-info-circle"></i> Do you want to delete this record?
        </span>
      ),
      header: "Delete Confirmation",
      // icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      rejectClassName: "bck-btn",
      accept: () => handleAccept(note),
      reject: handleReject,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog draggable={true} className="topic-del-cnf" />
      <div>
        <div className="notes-container">
          <div className="mynotes-title">My Notes</div>
          <div className="add-notes-section">
            {filterCourseName || filterCourseTopic ? (
              <div className="add-note-containers flex justify-content-end">
                <Button
                  className="clear-button"
                  label="Clear"
                  icon="pi pi-trash"
                  iconPos="right"
                  severity="danger"
                  onClick={() => {
                    setFilterCourseName("");
                    setFilterCourseTopic("");
                    setTopicData([]);
                    setSelectTopicDisabled(true);
                  }}
                />
              </div>
            ) : null}

            <div className="add-note-containers">
              <Dropdown
                className="w-full"
                placeholder="Select Course"
                value={filterCourseName}
                options={enrolledCourses}
                optionLabel="courseTitle"
                onChange={(e) => {
                  setFilterCourseName(e.target.value);
                  getTopicDataByCourse(e.target);
                }}
              />
            </div>
            <div
              className="add-note-containers sel-topic-notes"
              style={{
                cursor: selectTopicDisabled ? "not-allowed" : "pointer",
              }}
            >
              <Dropdown
                className="w-full"
                placeholder="Select Topic"
                value={filterCourseTopic}
                options={topicData}
                optionLabel="topicTitle"
                disabled={selectTopicDisabled}
                onChange={(e) => setFilterCourseTopic(e.value)}
              />
            </div>
            <div className="add-note-containers">
              <Button
                className="w-full"
                label="+ New Note"
                onClick={() => {
                  handleAddNote("new");
                }}
              />
            </div>
          </div>
          <div className="grid mynotes">
            {filteredNotes()?.map((note, index) => (
              <div key={index} className="col-12 md:col-6 lg:col-4 note-card">
                <div className="each-note">
                  <div className="note-header">
                    <div className="header-left">
                      {getNoteIcon(note.noteType)}
                      <div className="note-type">{note.noteType}</div>
                    </div>
                    <div>
                      <ImEnlarge2
                        className="view-icons cursor-pointer"
                        onClick={() => openViewDialog(note)}
                      />
                    </div>
                  </div>
                  <div className="note-content">
                    <div className="note-date">
                      {formatDate(note.createdOn)}
                    </div>
                    <div className="note-title">{note.noteTitle}</div>
                    <div className="note-courses">
                      <div className="note-course-type">{note.courseName}</div>
                      <div className="note-course-type">{note.topicName}</div>
                    </div>
                    <div className="view-edit-buttons">
                      <FaEdit
                        className="view-edit-icons cursor-pointer"
                        onClick={() => {
                          handleDialogeAction("edit", note);
                        }}
                      />
                      <AiFillDelete
                        className="view-edit-icons cursor-pointer"
                        // style={{ padding: "2rem 1.5rem 2rem 1.5rem"}}
                        onClick={() => confirmNoteDelete(note)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {selectedNote && (
            <Dialog
              header="Note Details"
              visible={viewDialog}
              style={{ width: "70vw" }}
              onHide={() => {
                setViewDialog(false);
                setSelectedNote({});
              }}
            >
              <div className="note-details">
                <h3>{selectedNote?.noteTitle}</h3>
                <p>
                  <strong>Type:</strong> {selectedNote?.noteType}
                </p>
                <p>
                  <strong>Date:</strong> {formatDate(selectedNote?.createdOn)}
                </p>
                <p>
                  <strong>Content:</strong>
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedNote?.noteContent,
                  }}
                ></div>
              </div>
            </Dialog>
          )}
        </div>
      </div>
      <Dialog
        className="edit-dialog"
        header={dialogeAction === "new" ? "New Note" : "Edit Note"}
        visible={editDialog}
        style={{ width: "70vw" }}
        onHide={closeDialog}
        draggable={false}
      >
        <div className="edit-note">
          <div className="grid">
            {dialogeAction === "new" && (
              <>
                <div className="field col-12 md:col-6 flex flex-column">
                  <label htmlFor="editNoteTitle">Course</label>
                  <Dropdown
                    id="editNoteTitle"
                    value={courseName}
                    options={enrolledCourses}
                    optionLabel="courseTitle"
                    onChange={(e) => {
                      setCourseName(e.target.value);
                      getTopicDataByCourse(e.target);
                    }}
                  />
                </div>
                <div className="field col-12 md:col-6 flex flex-column">
                  <label htmlFor="editNoteType">Topic</label>
                  <Dropdown
                    id="editNoteType"
                    value={courseTopic}
                    options={topicData}
                    optionLabel="topicTitle"
                    onChange={(e) => setCourseTopic(e.value)}
                  />
                </div>
              </>
            )}
            <div className="field col-12 md:col-8 flex flex-column">
              <label htmlFor="editNoteTitle">Note Title</label>
              <InputText
                id="editNoteTitle"
                name={dialogeAction === "new" ? "newNoteTitle" : "noteTitle"}
                value={
                  dialogeAction === "new"
                    ? newNoteTitle
                    : selectedEditData?.noteTitle
                }
                onChange={(e) => {
                  dialogeAction === "new"
                    ? setNewNoteTitle(e.target.value)
                    : handleUpdateChange(e);
                }}
              />
            </div>
            <div className="field col-12 md:col-4 flex flex-column">
              <label htmlFor="editNoteType">Note Type</label>
              <Dropdown
                id="editNoteType"
                name={dialogeAction === "new" ? "selectedNote" : "noteType"}
                value={
                  dialogeAction === "new"
                    ? selectedNote
                    : selectedEditData?.noteType
                }
                options={noteTypes}
                optionLabel="label"
                onChange={(e) => {
                  dialogeAction === "new"
                    ? setSelectedNote(e.value)
                    : handleUpdateChange(e);
                }}
              />
            </div>
          </div>
          <div className="field col-12 flex flex-column">
            <label htmlFor="editNoteContent">Content</label>
            <Editor
              theme="snow"
              ref={editorRef}
              value={
                dialogeAction === "new" ? newNoteContent : updateTopicContent
              }
              onTextChange={(e) => {
                dialogeAction === "new"
                  ? setNewNoteContent(e.htmlValue)
                  : setUpdateTopicContent(e.htmlValue);
              }}
              placeholder="Write your note description here..."
              modules={modules}
            />
          </div>
          <div className="edit-save-btn">
            <Button
              label="Save"
              onClick={dialogeAction === "new" ? createNote : handleUpdateNote}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default MyNotes;
