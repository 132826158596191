import React from "react";
import "./AcademyEvents.scss";
import eventCoverOne from "../../../Assets/Academy/Academy-event-cover-1.png";
import eventCoverOTwo from "../../../Assets/Academy/Academy-event-cover-two.png";
import { Button } from "primereact/button";

const AcademyEvents = () => {
    const events = [
        {
            id: 1,
            name: "Young Innovators Workshop",
            date: "October 25th, 2024",
            time: "10:00 AM  - 02:00 PM",
            location: "Somewhere in-person",
            btnLabel: "Register Now",
            image: eventCoverOne,
        },
        {
            id: 2,
            name: "Pitch Your Idea Contest",
            date: "November 20th, 2024",
            time: "01:00 PM  - 05:00 PM",
            location: "Virtual",
            btnLabel: "Sign Up",
            image: eventCoverOTwo,
        },
    ];
    return (
        <div className="s2l-academy-events">
            <div className="events-container">
                <h2>Join Our Exciting Events!</h2>

                <div className="event-content flex flex-row ">
                    {events.map((event) => (
                        <div className="event " key={event.id}>
                            <div className="event-image">
                                <img src={event.image} alt={event.name} className="h-full" />
                            </div>
                            <div className="event-details">
                                <h3>{event.name}</h3>
                                <div className="event-details-sub">
                                    <span className="event-data">
                                        <i className="pi pi-calendar"></i>
                                        <p>
                                            Date:
                                            <br /> {event.date}
                                        </p>
                                    </span>
                                    <span className="event-data">
                                        <i className="pi pi-clock"></i>
                                        <p>
                                            {" "}
                                            Time:
                                            <br /> {event.time}
                                        </p>
                                    </span>
                                    <span className="event-data">
                                        <i className="pi pi-map-marker"></i>
                                        <p>
                                            Location:
                                            <br /> {event.location}
                                        </p>
                                    </span>
                                </div>
                                {/* <div className="event-btn">
                                    <Button label={event.btnLabel} />
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AcademyEvents;
