import React, { useEffect, useState } from "react";
import "./ClientReview.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons/faQuoteLeft";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";

const testimonials = [
    {
        quote: "The workshop by S2L Academy was an awesome experience for my daughter to understand the basic concepts of entrepreneurship and the exam prep was a very useful session.",
        name: "Arpita Ghosh",
        rating: 5,
    },
    {
        quote: 'The MasterClass for High Schoolers program was very well-organized program. This event helps high school students in boosting their academic performance and future achievement, and highschoolers actively engaged throughout. We eagerly await the next session and 8-week program, which will definitely be equally amazing.',
        name: 'Prathyusha Allada',
        rating: 5,
    },
    {
        quote: 'I was very impressed with the quality of technical services during my doctor’s visit. I was able to be seen right away…',
        name: 'Mina',
        tag: 'Patient - Arya Psychiatry',
        rating: 4,
    },
    {
        quote: 'Urgent psychiatry in less than two hours from very experienced and professional psychiatrists.',
        name: 'Ram',
        tag: 'Patient - Arya Psychiatry',
        rating: 5,
    },
    {
        quote: 'I had a great experience here. Everything was timely, I felt understood and got the results. I was looking for. Very professional.',
        name: 'Jesse',
        tag: 'Patient - Arya Psychiatry',
        rating: 3,
    },
    {
        quote: 'These are great guys. This service is a life saver for mentally ill people. Their Psychiatrists are top notch.',
        name: 'Chris',
        tag: 'Patient - Arya Psychiatry',
        rating: 4,
    },
    {
        quote: 'Great doctors to work with.',
        name: 'SS',
        tag: "Patient - Arya Psychiatry",
        rating: 5,
    },
    {
        name: 'GG',
        quote: 'Dr Sunkara is very attentive and works well with our teen. I highly recommend this practice, and believe me when I say…',
        tag: "Patient - Arya Psychiatry",
        rating: 5,
    },
    {
        quote: "Thanks for letting my son have the exposure to such a nice exercise. He was excited and has learned a lot in how to express ideas.",
        name: "Naren Babu Guntupalli",
        rating: 5,
    }
];

const ClientReview = () => {

    const [index, setIndex] = useState(0);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 767);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex + (isMobileView ? 1 : 2)) % testimonials.length);
    };

    const handlePrev = () => {
        setIndex((prevIndex) => (prevIndex - (isMobileView ? 1 : 2) + testimonials.length) % testimonials.length);
    };

    const renderStars = (rating) => {
        return Array.from({ length: 5 }, (_, i) => (
            <span key={i} className={`star ${i < rating ? 'filled' : ''}`}> <FontAwesomeIcon icon={faStar} /></span>
        ));
    };

    const visibleTestimonials = isMobileView
        ? [testimonials[index]]
        : [testimonials[index], testimonials[(index + 1) % testimonials.length]];

    return (
        <div className="testimonial-container">
            <h2>Check what our clients are saying</h2>

            <div className="testimonials-wrapper">
                {visibleTestimonials.map((testimonial, i) => (
                    <div key={i} className="testimonial">
                        <div className="quote-icon"><FontAwesomeIcon icon={faQuoteLeft} /></div>
                        <p>{testimonial.quote}</p>
                        <div className="stars">{renderStars(testimonial.rating)}</div>
                        <h3>{testimonial.name}</h3>
                        <span className="position">{testimonial.tag}</span>
                    </div>
                ))}
            </div>

            <div className="controls">
                <button
                    onClick={handlePrev}
                    className={`prev ${index === 0 ? 'disabled' : ''}`}
                    disabled={index === 0}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                    onClick={handleNext}
                    className={`next ${index === testimonials.length - 1 ? 'disabled' : ''}`}
                    disabled={index === testimonials.length - 1}
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </div>
        </div>
    );
};

export default ClientReview;