import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../NewLandingPage/Home/Home';
import Workshops from '../NewLandingPage/Workshop/Academy-Workshops/Workshops';
import WorkshopDetails from '../NewLandingPage/Workshop/Academy-Workshops/WorkshopDetails';
import UpcomingEvents from '../NewLandingPage/Workshop/Academy-UpcomingEvents/UpcomingEvents';
import UpcomingEventsDetails from '../NewLandingPage/Workshop/Academy-UpcomingEvents/UpcomingEventsDetails';
import WSData from '../NewLandingPage/Workshop/Academy-Workshops/WorkshopsData';
import Speakers from '../NewLandingPage/Workshop/Academy-Speakers/Speakers';
import SpeakerDetails from '../NewLandingPage/Workshop/Academy-Speakers/SpeakerDetails';
import UEData from '../NewLandingPage/Workshop/Academy-UpcomingEvents/UpcomingEventsData';
import Dashboard from '../Module/Dashboard/Dashboard';
import LoginPage from '../Module/Authentication/Login/LoginPage';
import Signup from '../Module/Authentication/Signup/Signup';
import ConfirmationPage from '../Module/Authentication/Signup/ConfirmationPage/ConfirmationPage';
import ForgotPasswordPage from '../Module/Authentication/Login/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '../Module/Authentication/Login/ResetPasswordPage/ResetPasswordPage';
import InfoPage from '../Module/Dashboard/InfoPage/InfoPage';
import Admin from '../Module/Admin/Admin';
import Programs from '../NewLandingPage/Programs/Programs.jsx';
import Academy from '../NewLandingPage/Academy/Academy.jsx';
import AboutUs from '../NewLandingPage/AboutUs/AboutUs.jsx';
import EntrepreneurshipJourney from '../NewLandingPage/Home/EntrepreneurshipJourney/EntrepreneurshipJourney.jsx';
import Questionnarie from '../NewLandingPage/Home/EntrepreneurshipJourney/QuestionnarieSection/Questionnarie.jsx';
import BestPlan from '../NewLandingPage/Home/EntrepreneurshipJourney/QuestionnarieSection/BestPlan/BestPlan.jsx';
import Project from '../NewLandingPage/AboutUs/OurProjects/ProjectTemplate/Project.jsx';

function Navigation() {

    const [workshopsData, setWorkshopsData] = useState([]);
    const [UpcomingEventsData, setUpcomingEventsData] = useState([]);

    useEffect(() => {
        // Scroll to the top of the page on route change
        const onRouteChange = () => {
            window.scrollTo(0, 0);
        };

        // Listen for route changes
        window.addEventListener('hashchange', onRouteChange);

        // Clean up event listener
        return () => {
            window.removeEventListener('hashchange', onRouteChange);
        };
    }, []);


    useEffect(() => {
        // Load workshops data
        setWorkshopsData(WSData);
    }, []);

    useEffect(() => {
        // Load upcoming events data
        setUpcomingEventsData(UEData);
    }, []);

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path='/aboutus' element={<AboutUs />} />

                    {/* <Route path='/plans' element={<Plans />} />

                    <Route path='/services' element={<Services />} />

                    <Route path='/recruitment' element={<Recruitment />} />

                    <Route path='/s2lAcademy' element={<AcademyPage />} />

                    <Route path='/ourWork' element={<OurWork />} /> */}

                    <Route path="/project/:name"  element={<Project/>}/>

                    {/* <Route path='/investor/AryaNow' element={<AryaNowProject />} />
                    <Route path='/investor/Laulama' element={<LaulamaProject />} />
                    <Route path='/investor/Autism' element={<AutismProject />} /> */}

                    {/* demo project path */}

                    {/* <Route path='/ourWork/payment' element={<PaymentOption />} />

                    <Route path='/ourWork/chatbot' element={<ChatbotOption />} />

                    <Route path='/ourWork/CommunicationModule' element={<CommunicationModule />} />

                    <Route path='/ourWork/Image&VideoAnalysis' element={<VideoAnalysis />} />

                    <Route path='/ourWork/Appointment' element={<Appointment />} />

                    // <Route path='/Faq' element={<FAQ />} />

                    <Route path='/newclientform' element={<NewClientForm />} />

                    <Route path='/investor' element={<Investor />} />

                    <Route path='/studentprofile' element={<StudentProfile />} />
                    <Route path="/" element={<StudentDetails />} />
                    <Route path="/certificate" element={<Certificate />} /> */}


                    <Route path="/workshops" element={<Workshops workshops={workshopsData} />} />

                    <Route path="/workshop/:name" element={<WorkshopDetails workshops={workshopsData} />} />

                    <Route path="/speakers" element={<Speakers speakers={workshopsData} />} />

                    <Route path="/speaker/:speakerName" element={<SpeakerDetails workshops={workshopsData} />} />

                    <Route path='/upcomingevents' element={<UpcomingEvents UpcomingEvents={UpcomingEventsData} />} />

                    <Route path="/upcomingevents/:name" element={<UpcomingEventsDetails UpcomingEvents={UpcomingEventsData} />} />

                    {/* <Route path='/8-weekcourse' element={<AcademyInternship />} />

                    <Route path='/workshopGuide' element={<WorkshopGuide />} /> */}

                    <Route path='/dashboard' element={<Dashboard />} />

                    <Route path='/login' element={<LoginPage />} />

                    <Route path='/signup' element={<Signup />} />

                    <Route path='/signup/confirmcode' element={<ConfirmationPage />} />

                    <Route path='/forgot-password' element={<ForgotPasswordPage />} />

                    <Route path='/resetPassword' element={<ResetPasswordPage />} />

                    <Route path='/additionalInfo' element={<InfoPage />} />

                    <Route path='/adminDashboard' element={<Admin />} />

                    {/*  New Header */}

                    <Route path='/programs' element={<Programs />} />

                    <Route path='/academy' element={<Academy />} />

                    <Route path='/questionnaire' element={<EntrepreneurshipJourney />} />

                    <Route path='/questionnaire/:stage' element={<Questionnarie />} />

                    <Route path='/bestPlan' element={<BestPlan />} />

                </Routes>
            </Router>
        </div>
    );
}

export default Navigation;
