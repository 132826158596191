
import React, { useEffect, useState } from "react";
import "./Questionnaire.scss";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Button } from "primereact/button";

const questions = [
    {
        id: 1,
        sectionHeader: 'About You',
        subheader: 'Let’s Get to Know You!',
        question: "Tell us what describes you best:",
        options: ["Student", "Professional", "Other"],
        multiple: false,
    },
    {
        id: 2,
        sectionHeader: 'Exploring your interests',
        subheader: 'Your Entrepreneurial Journey Starts Here!',
        question: "Do you have a business idea in mind?",
        options: ["Yes", "No", "Somewhat"],
        multiple: false,
    },
    {
        id: 3,
        sectionHeader: 'Exploring your interests',
        subheader: 'Finding Your Passion',
        question: "Which type of business interests you most? (Select all that apply)",
        options: [
            "Technology (e.g., apps, software)",
            "Retail (e.g., e-commerce, stores)",
            "Services (e.g., education, health)",
            "Social impact (e.g., non-profit)",
            "Other",
        ],
        multiple: true,
    },
    {
        id: 4,
        sectionHeader: 'Business Stage',
        subheader: 'Assessing Your Current Stage',
        question: "Which stage best describes your idea or business?",
        options: [
            "Idea Stage",
            "Development Stage",
            "Early Launch Stage",
            "Growth Stage",
        ],
        multiple: false,
    },
    {
        id: 5,
        sectionHeader: 'Team and Collaboration',
        subheader: 'Team and Collaboration Status',
        question: "Do you currently work with others on your idea or business?",
        options: ["Yes", "No"],
        multiple: false,
    },
    {
        id: 6,
        sectionHeader: 'Your Goals',
        subheader: 'Setting Your Goals',
        question: "What’s your main objective for joining an entrepreneurship program?",
        options: [
            "Explore and learn",
            "Develop and Validate",
            "Launch my startup",
            "Grow my business",
        ],
        multiple: false,
    },
];

const Questionnaire = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [otherText, setOtherText] = useState("");
    const [visibleSectionIndex, setVisibleSectionIndex] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [progress, setProgress] = useState(((currentQuestionIndex + 1) / questions.length) * 100);

    const currentQuestion = questions[currentQuestionIndex];

    useEffect(() => {
        const newProgress = ((currentQuestionIndex + 1) / questions.length) * 100;
        setProgress(newProgress);

    }, [currentQuestionIndex]);

    const handleSelect = (option) => {
        if (currentQuestion.multiple) {
            setAnswers((prev) => {
                const selected = prev[currentQuestion.id] || [];
                if (selected.includes(option)) {
                    return {
                        ...prev,
                        [currentQuestion.id]: selected.filter((item) => item !== option),
                    };
                } else {
                    return { ...prev, [currentQuestion.id]: [...selected, option] };
                }
            });
        } else {
            setAnswers((prev) => ({
                ...prev,
                [currentQuestion.id]: option,
            }));
        }
    };

    const handleNext = () => {
        if (currentQuestion.id === 2 && answers[2] === "No") {
            setCurrentQuestionIndex(questions.length - 1);
            setVisibleSectionIndex(3);
        } else {
            if (
                currentQuestionIndex === 0 &&
                answers[1] === "Other" &&
                otherText === ""
            ) {
                alert('Please specify your "Other" option');
                return;
            }
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            setVisibleSectionIndex((prevIndex) => prevIndex + 1);
        }
    };


    const handleBack = () => {
        navigate("/questionnaire");
    };

    const handlePrevious = () => {
        setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
        setVisibleSectionIndex((prevIndex) => prevIndex - 1);
    };

    const handleSubmit = () => {
        navigate("/bestPlan", { state: { answers } });
    };

    return (
        <>
            <Header />
            <div className="progress-section">
                <div
                    style={{
                        width: `${progress}%`,
                        backgroundColor: '#8DAF61',
                        height: '10px',
                        borderRadius: '5px',
                        transition: 'width 0.3s ease-in-out',
                        PaddingTop: "100px"
                    }}
                />
            </div>
            <div className="questionnaire">
                <h2>Section {visibleSectionIndex}: {currentQuestion.sectionHeader}</h2>

                <h3>{currentQuestion.subheader}</h3>
                <p>{currentQuestion.question}</p>
                <div className="options">
                    {currentQuestion.options.map((option) => (
                        <button
                            key={option}
                            className={
                                currentQuestion.multiple
                                    ? answers[currentQuestion.id]?.includes(option)
                                        ? "option selected"
                                        : "option"
                                    : answers[currentQuestion.id] === option
                                        ? "option selected"
                                        : "option"
                            }
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </button>
                    ))}

                    {currentQuestion.id === 1 && answers[1] === "Other" && (
                        <div className="other-input">
                            <InputText
                                value={otherText}
                                onChange={(e) => setOtherText(e.target.value)}
                                placeholder="Type"
                                className="input-text"
                            />
                        </div>
                    )}
                </div>

                <div className="navigation">
                    {currentQuestionIndex > 0 ? (
                        <Button className="button-style-primary-square-line btn-width " onClick={handlePrevious}>Back</Button>
                    ) : (
                        <Button className="button-style-primary-square-line" onClick={handleBack}>Back</Button>
                    )}

                    {currentQuestionIndex < questions.length - 1 ? (
                        <Button onClick={handleNext}>Continue</Button>
                    ) : (
                        <Button onClick={handleSubmit}>Continue</Button>
                    )}
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Questionnaire;
