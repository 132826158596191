import React, { useEffect, useState } from "react";
import "./CustomizationDialog.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Avatar } from "primereact/avatar";
import avatar1 from "../../../Assets/Settings/avatar1.png";
import avatar2 from "../../../Assets/Settings/avatar2.png";
import avatar3 from "../../../Assets/Settings/avatar3.png";
import avatar4 from "../../../Assets/Settings/avatar4.png";
import avatar5 from "../../../Assets/Settings/avatar5.png";
import avatar6 from "../../../Assets/Settings/avatar6.png";
import avatar7 from "../../../Assets/Settings/avatar7.png";
import avatar8 from "../../../Assets/Settings/avatar8.png";
import avatar9 from "../../../Assets/Settings/avatar9.png";
import avatar10 from "../../../Assets/Settings/avatar10.png";
import avatar11 from "../../../Assets/Settings/avatar11.png";
import avatar12 from "../../../Assets/Settings/avatar12.png";
import { ColorPicker } from "primereact/colorpicker";
import axios from "axios";
import { CUSTOM_BOT_STYLE_URL } from "../../../Utils/APIService";
import backgroundImg1 from "../../../Assets/Chatbot/Wallpapers/1.jpg";
import backgroundImg2 from "../../../Assets/Chatbot/Wallpapers/2.jpg";
import backgroundImg3 from "../../../Assets/Chatbot/Wallpapers/3.jpg";
import backgroundImg4 from "../../../Assets/Chatbot/Wallpapers/4.jpg";
import backgroundImg5 from "../../../Assets/Chatbot/Wallpapers/5.jpg";
import backgroundImg6 from "../../../Assets/Chatbot/Wallpapers/6.jpg";
import backgroundImg7 from "../../../Assets/Chatbot/Wallpapers/7.jpg";
import backgroundImg8 from "../../../Assets/Chatbot/Wallpapers/8.jpg";
import backgroundImg9 from "../../../Assets/Chatbot/Wallpapers/9.jpg";
import backgroundImg10 from "../../../Assets/Chatbot/Wallpapers/10.jpg";
import backgroundImg11 from "../../../Assets/Chatbot/Wallpapers/11.jpg";
import backgroundImg12 from "../../../Assets/Chatbot/Wallpapers/12.jpg";
import backgroundImg13 from "../../../Assets/Chatbot/Wallpapers/13.jpg";

const avatars = [
    { name: "avatar1", icon: avatar1 },
    { name: "avatar2", icon: avatar2 },
    { name: "avatar3", icon: avatar3 },
    { name: "avatar4", icon: avatar4 },
    { name: "avatar5", icon: avatar5 },
    { name: "avatar6", icon: avatar6 },
    { name: "avatar7", icon: avatar7 },
    { name: "avatar8", icon: avatar8 },
    { name: "avatar9", icon: avatar9 },
    { name: "avatar10", icon: avatar10 },
    { name: "avatar11", icon: avatar11 },
    { name: "avatar12", icon: avatar12 },
];

const fontSizes = [
    { name: "Small", value: "small" },
    { name: "Medium", value: "medium" },
    { name: "Large", value: "large" },
];

const backgrounds = [
    { name: "backgroundImg1", image: backgroundImg1 },
    { name: "backgroundImg2", image: backgroundImg2 },
    { name: "backgroundImg3", image: backgroundImg3 },
    { name: "backgroundImg4", image: backgroundImg4 },
    { name: "backgroundImg5", image: backgroundImg5 },
    { name: "backgroundImg6", image: backgroundImg6 },
    { name: "backgroundImg7", image: backgroundImg7 },
    { name: "backgroundImg8", image: backgroundImg8 },
    { name: "backgroundImg9", image: backgroundImg9 },
    { name: "backgroundImg10", image: backgroundImg10 },
    { name: "backgroundImg11", image: backgroundImg11 },
    { name: "backgroundImg12", image: backgroundImg12 },
    { name: "backgroundImg13", image: backgroundImg13 },
];

const CustomizationDialog = ({ data, botId, onSave, handleOnClose }) => {
    const [avatar, setAvatar] = useState(data.avatar || avatars[0].icon);
    const [avatarName, setAvatarName] = useState(data && data.avatarName ? data.avatarName : 'avatar1');

    const [bubbleColor, setBubbleColor] = useState(data.bubbleBackground || 'FFFFFF');
    const [fontColor, setFontColor] = useState(data.fontColor || '000000');
    const [chatBotName, setChatBotName] = useState(data.chatBotName || '');
    const [fontSize, setFontSize] = useState(data.fontSize || '14px');
    const [backgroundImage, setBackgroundImage] = useState(data.chatTheme || backgrounds[0].image);
    const [backgroundName, setBackgroundName] = useState(data && data.chatTheme ? data.chatTheme : 'backgroundImage1');

    useEffect(() => {
        setBubbleColor(data.bubbleBackground || 'FFFFFF');
        setFontColor(data.fontColor || '000000');
        setChatBotName(data.chatBotName || '');
        setFontSize(data.fontSize || '14px');
        setAvatar(data.avatar);
        setBackgroundImage(data.chatTheme);
    }, [data]);

    const handleAvatarClick = (selectedAvatar) => {
        setAvatar(selectedAvatar.icon);
        setAvatarName(selectedAvatar.name);
    };

    const handleBackgroundClick = (background) => {
        setBackgroundImage(background.image);
        setBackgroundName(background.name);
    };

    const fetchAvatarAsBinary = async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error("Network response was not ok");
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error("Failed to fetch avatar:", error);
            return null;
        }
    };

    const handleSave = async () => {

        const data = {
            botId: botId,
            avatar: avatar,
            chatTheme: backgroundImage,
            chatBotName: chatBotName,
            fontSize: fontSize,
            fontColor: fontColor,
            bubbleBackground: bubbleColor
        }

        try {
            const userAccessToken = localStorage.getItem("userAccessToken");
            const response = await axios.post(CUSTOM_BOT_STYLE_URL, data, {
                headers: {
                    Authorization: "Bearer " + userAccessToken,
                },
            });

            if (response.status === 200) {
                console.log("Chatbot customization saved successfully");
                onSave(response.data);
            } else {
                console.error(
                    "Failed to save chatbot customization",
                    response.status
                );
            }
        } catch (error) {
            console.error("Error saving chatbot customization:", error);
        }

        handleOnClose();
    };

    const urlToFile = async (url, filename, mimeType) => {
        const res = await fetch(url);
        const blob = await res.blob();
        return new File([blob], filename, { type: mimeType });
    };

    const getFilename = (path) => {
        return path?.substring(path.lastIndexOf('/') + 1);
    };

    return (
        <Dialog
            header="Customize Your Chatbot"
            visible={true}
            style={{ width: "70vw" }}
            className="customize-chatbot-dialog "
            onHide={handleOnClose}
            footer={<Button label="Save" icon="pi pi-check" onClick={handleSave} />}
        >
            <div className="p-grid">
                <div className="p-col-6">
                    <h3>Profile Picture</h3>
                    <div className="p-d-flex p-ai-center flex align-items-center cu_cb_profile">
                        <Avatar
                            image={avatar}
                            size="xlarge"
                            style={{ marginRight: "20px" }}
                            className="customize-profile-avatar"
                        />
                        <div className="flex-column border-line p-2">
                            <p>Enter Chatbot Name</p>
                            <InputText
                                value={chatBotName}
                                onChange={(e) => setChatBotName(e.target.value)}
                                placeholder="Enter chatbot name"
                            />
                        </div>
                    </div>

                    <h4>Select your avatar</h4>

                    <div className="p-d-flex flex-wrap flex">
                        {avatars?.map((avatarOption) => {
                            const selectedAvatarFilename = getFilename(avatar);
                            const removedAvatarFilename = selectedAvatarFilename?.split('.')[0];
                            const avatarExists = avatars.some(avatar => avatar.name === removedAvatarFilename);
                            return (
                                <div
                                    key={avatarOption.name}
                                    className="avatar-item"
                                    onClick={() => handleAvatarClick(avatarOption)}
                                    style={{ margin: "10px" }}
                                >
                                    <Avatar
                                        image={avatarOption.icon}
                                        shape="circle"
                                        style={{
                                            cursor: "pointer",
                                            border:
                                                avatarExists &&
                                                    avatarOption.name === removedAvatarFilename
                                                    ? "3px solid #8DAF61"
                                                    : "none",
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <h3>Speech bubble</h3>
                    <div
                        style={{
                            // backgroundColor: `#${bubbleColor}`,
                            // border: "1px solid #000000",
                            // padding: "10px",
                            // borderRadius: "10px",
                            // marginBottom: "20px",
                        }}
                    >
                        <div className="flex align-items-center justify-content-center cu_cb_profile">
                            <Avatar
                                image={avatar}
                                size="large"
                                style={{ marginRight: "20px" }}
                                className="customize-profile-avatar-section"
                            />
                            <span
                                style={{
                                    color: `#${fontColor}`,
                                    fontSize: fontSize,
                                    backgroundColor: `#${bubbleColor}`,
                                    lineHeight: "30px",
                                    padding: "10px",
                                    borderRadius: "0 10px 0 10px",
                                }}
                            >
                                Hi! I'm {chatBotName || "a chatbot"} here to help. How can I
                                assist you today?
                            </span>
                        </div>
                    </div>

                    <p>Personalize the dialogue bubbles</p>

                    <div className="p-grid flex gap-4 customize_cb_style">
                        <div className="p-col-4 flex flex-column">
                            <label htmlFor="fontSize">Font Size</label>
                            <Dropdown
                                id="fontSize"
                                value={fontSize}
                                options={fontSizes}
                                onChange={(e) => setFontSize(e.value)}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-4 flex flex-column">
                            <label htmlFor="fontColor">Font Color</label>
                            <ColorPicker
                                id="fontColor"
                                value={fontColor}
                                onChange={(e) => setFontColor(e.value)}
                            />
                        </div>
                        <div className="p-col-4 flex flex-column">
                            <label htmlFor="bubbleColor">Bubble Color</label>
                            <ColorPicker
                                id="bubbleColor"
                                value={bubbleColor}
                                onChange={(e) => setBubbleColor(e.value)}
                            />
                        </div>
                    </div>

                    <h3>Chat background</h3>
                    <div className="p-col-6">
                        <div className="preview">
                            <img
                                src={backgroundImage}
                                alt="Background"
                                className="background-image"
                                />

                            <div className="text-overlay flex ">
                                <Avatar
                                    image={avatar}
                                    style={{ marginRight: "20px" }}
                                    size="large"
                                    className="customize-profile-avatar-section"
                                />
                                <div
                                    className="bubble"
                                    style={{ backgroundColor: `#${bubbleColor}`,  borderRadius: "0 10px 0 10px", }}
                                    
                                >
                                    <span
                                        style={{ color: `#${fontColor}`, fontSize: `#${fontSize}` }}
                                    >
                                        Hi! I'm {chatBotName || "a chatbot"}, here to help. How
                                        can I assist you today?
                                    </span>
                                </div>

                                <div
                                    className="user-message"
                                    style={{
                                        color: `#${fontColor}`,
                                        fontSize: fontSize,
                                        backgroundColor: `#${bubbleColor}`,
                                        opacity: 0.7,
                                        borderRadius: "0 10px 0 10px",
                                    }}
                                >
                                    Hi
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Personalize the dialogue bubbles</p>

                    <div className="p-d-flex p-flex-wrap flex flex-wrap">
                        {backgrounds.map((backgroundOption) => {
                            const selectedBackgroundImageName = getFilename(backgroundImage);

                            const removedFilename = selectedBackgroundImageName?.split('.')[0];

                            const bgImgExists = backgrounds.some(
                                (backgroundImage) => backgroundImage.name === removedFilename
                            );

                            return (
                                <div
                                    key={backgroundOption.name}
                                    className="background-item"
                                    onClick={() => handleBackgroundClick(backgroundOption)}
                                    style={{ margin: "10px" }}
                                >
                                    <img
                                        src={backgroundOption.image}
                                        alt={`Background ${backgroundOption.name}`}
                                        className="background-thumbnail"
                                        style={{
                                            backgroundImage: `url(${data.chatTheme})`,
                                            height: "100px",
                                            width: "100px",
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            border:
                                                bgImgExists && backgroundOption.name === removedFilename
                                                    ? "3px solid #8DAF61"
                                                    : "none",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CustomizationDialog;
