import React from "react";
import "./AboutUsBanner.scss";
import OurStory from "../OurStory/OurStory";

const AboutUsBanner = () => {
    return (
        <>
            <div className="s2l-academy-aboutus">
                <div className="aboutus-banner">
                    <div className="blur-background"></div>
                    <h2>
                        Empowering Visionaries to Launch <br /> Tomorrow's Leading Ventures
                    </h2>
                </div>
            </div>
        </>
    );
};

export default AboutUsBanner;
