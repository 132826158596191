import { useRef, useState, useEffect } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import "./Dashboard.scss";
import {
  FaChevronDown,
  FaUserCircle,
  FaSignOutAlt,
  FaTimes,
} from "react-icons/fa";
import logo from "../../Assets/Studentdashboard/logo.png";
import { useNavigate } from "react-router-dom";
import Courses from "../../Module/Dashboard/Courses/Courses";
import Topics from "../../Module/Dashboard/Courses/topics"; // Import the Topics component
import topics from "../../Module/Dashboard/Courses/topicsData"; // Adjust the import path for topics
import { Button } from "primereact/button";
import { FaChevronLeft } from "react-icons/fa";
import Settings from "../../Module/Dashboard/Settings/Settings";
import { BsChatDotsFill } from "react-icons/bs";
import { FaDiagramProject, FaNoteSticky } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import Chatbot from "../../Module/Dashboard/Chatbot/Chatbot";
import MyNotes from "../../Module/Dashboard/MyNotes/MyNotes";
import axios from "axios";
import {
  CONTENT_AUTHORIZE_URL,
  LOGOUT_URL,
  USER_INFO_ID_URL,
} from "../../Utils/APIService";

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("My Courses");
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const overlayPanelRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [parentInfo, setParentInfo] = useState({});
  const [refreshUserInfo, setRefreshInfo] = useState(false)

  const navigate = useNavigate();

  const fetchUserInfo = async () => {
    try {
      const userInfoResponse = await axios.get(
        USER_INFO_ID_URL + localStorage.getItem("userId"),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userAccessToken")}`,
          },
        }
      );
      setUserInfo(userInfoResponse.data.studentInfoResponse);
      setParentInfo(userInfoResponse.data.parentInfoResponse);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, [refreshUserInfo]);

  const showProfileMenu = (event) => {
    setShowMenu(!showMenu);
    overlayPanelRef.current.toggle(event);
  };

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar); // Toggle sidebar on mobile
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1008) {
        setShowSidebar(false); // Hide sidebar on small screens
      } else {
        setShowSidebar(true); // Show sidebar on large screens
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setSelectedTopic(null);
    setSelectedCourse(null);
    toggleSidebar();
  };

  const handleGoToTopic = (id) => {
    setSelectedTopic(topics.find((topic) => topic.id === id));
  };

  // const handleBackToCourses = () => {
  //     setSelectedTopic(null);
  // };

    const handleLogout = () => {
        axios
        .post(LOGOUT_URL + localStorage.getItem('email'), {}, { headers: { Authorization: `Bearer ${localStorage.getItem("userAccessToken")}` } })
            .then(() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/";
            });
    };

  return (
    <div className="dashboard-container">
      <div className={`left-bar ${showSidebar ? "show" : ""}`}>
        <div className="logo" onClick={handleLogoClick}>
          <img src={logo} alt="" />
        </div>
        <div className="avatar-section">
          {userInfo?.profilePicture ? (
            <img
              src={userInfo?.profilePicture}
              alt=""
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                margin: "auto",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                padding:"5px"
              }}
            />
          ) : (
            <FaUserCircle
              style={{ width: "100px", height: "100px", margin: "auto" }}
            />
          )}
          <div className="student-name">{userInfo.firstName}</div>
          <div className="student-grade">{userInfo.schoolGrade}</div>
        </div>
        <div className="menu-items">
          <ul>
            <li
              className={activeSection === "My Courses" ? "active" : ""}
              onClick={() => handleSectionChange("My Courses")}
            >
              <FaDiagramProject className="menu-item-icon" /> My Courses
            </li>
            <li
              className={activeSection === "Chatbot" ? "active" : ""}
              onClick={() => handleSectionChange("Chatbot")}
            >
              <BsChatDotsFill className="menu-item-icon" /> Chatbot
            </li>
            <li
              className={activeSection === "My Notes" ? "active" : ""}
              onClick={() => handleSectionChange("My Notes")}
            >
              <FaNoteSticky className="menu-item-icon" /> My Notes
            </li>
            <li
              className={activeSection === "Settings" ? "active" : ""}
              onClick={() => handleSectionChange("Settings")}
            >
              <IoMdSettings className="menu-item-icon" /> Settings
            </li>
            <li onClick={handleLogout}>
              <FaSignOutAlt className="menu-item-icon" /> Logout
            </li>
          </ul>
        </div>
      </div>

      <div className="header">
        <div className="stack-navbar">
          <div className="stackbar-heading">
            {/* <h2>Dashboard</h2> */}
            <h2>{activeSection}</h2>
            <img src={logo} alt="" />
          </div>
          <div className="menu-container">
            <div className="menu-toggle" onClick={toggleSidebar}>
              {showSidebar ? (
                <FaTimes style={{ fontSize: "24px" }} />
              ) : (
                <>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </>
              )}
            </div>
            <ul className={`menu-items ${showMenu ? "show" : ""}`}>
              <div className="profile-item">
                <div
                  className="profileIcon"
                  onClick={(e) => showProfileMenu(e)}
                >
                  <>
                    {userInfo?.profilePicture ? (
                      <img
                        src={userInfo?.profilePicture}
                        alt=""
                        style={{
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          padding:"5px"
                        }}
                      />
                    ) : (
                      <FaUserCircle />
                    )}
                    {userInfo.firstName}
                    <FaChevronDown />
                  </>
                </div>
                <OverlayPanel
                  ref={overlayPanelRef}
                  appendTo={document.body}
                  onHide={() => setShowMenu(false)}
                >
                  <ul className="profile-menu-list">
                    <li onClick={handleLogout}>
                      <FaSignOutAlt className="menu-item-icon" />
                      Logout
                    </li>
                  </ul>
                </OverlayPanel>
              </div>
            </ul>
          </div>
        </div>
        <div className="show-active-section">{activeSection}</div>
        <div className="menu-content-section">
          {activeSection === "My Courses" &&
            (selectedTopic ? (
              <Topics topic={selectedTopic} />
            ) : (
              <Courses
                onTopicSelect={setSelectedTopic}
                onCourseSelect={setSelectedCourse}
              />
            ))}
          {activeSection === "Chatbot" && <Chatbot />}
          {activeSection === "My Notes" && <MyNotes />}
          {activeSection === "Settings" && (
            <Settings userDetails={userInfo} parentDetails={parentInfo} setRefreshInfo={setRefreshInfo}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
