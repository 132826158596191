import React, { useState, useRef, useEffect } from 'react';
import './WorkshopDetails.scss';
import { Link, useParams } from 'react-router-dom';
import Header from '../../../NewLandingPage/Home/Header/Header';
import Footer from '../../../NewLandingPage/Home/Footer/Footer';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { Rating } from 'primereact/rating';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaShareSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaChevronLeft, FaChevronRight, FaFacebook } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Toast } from 'primereact/toast';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bulb from '../../../Assets/Workshops/Workshop1/Lightbulb.png';

const WorkshopDetails = ({ workshops }) => {

    const { name } = useParams();
    const [previewImage, setPreviewImage] = useState(null);


    // Find the workshop based on the name parameter in the URL
    const workshop = workshops.find(workshop => {
        // Convert workshop name to lowercase and replace spaces with dashes for URL compatibility
        const formattedName = workshop.url.replace(/\s+/g, '-').toLowerCase();
        return formattedName === name;
    });

    const [showAllFeedback, setShowAllFeedback] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const toast = useRef(null);



    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            closeDropdown();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleShare = (option) => {
        const shareLink = window.location.href;
        const shareText = `Check out this workshop: ${workshop.url}`;
        const encodedShareText = encodeURIComponent(shareText);
        const encodedShareLink = encodeURIComponent(shareLink);
        const emailSubject = encodeURIComponent(shareText);
        const emailBody = encodeURIComponent(`I thought you might be interested in this workshop: ${workshop.name}. Here's the link: ${shareLink}`);

        console.log(`Sharing via: ${option}`); 

        switch (option) {
            case 'WhatsApp':
                window.open(`https://wa.me/?text=${encodedShareText} - ${encodedShareLink}`, '_blank');
                break;
            case 'Email':
                window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;
                break;
            case 'Facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}`, '_blank');
                break;
            case 'X':
                window.open(`https://x.com/intent/tweet?url=${encodedShareLink}&text=${encodedShareText}`, '_blank');
                break;
            case 'LinkedIn':
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareLink}`, '_blank');
                break;
            case 'Copy Link':
                navigator.clipboard.writeText(shareLink).then(() => {
                    toast.current.show({ severity: 'success', detail: 'Link copied to your clipboard.' });
                }).catch(err => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Could not copy the link.' });
                    console.error('Could not copy text: ', err);
                });
                break;
            default:
                break;
        }

        closeDropdown();
    };

    if (!workshop) {
        return (
            <div>
                <Header />
                <div className='workshop-details-container'>
                    <div className="back-link">
                        <Link to="/workshops"><FaChevronLeft /> Back to Workshops</Link>
                    </div>
                    <div className='workshop-details'>
                        <h2>Workshop Not Found</h2>
                        <p>The workshop you are looking for does not exist.</p>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    const feedbackToShow = showAllFeedback ? workshop.feedback : workshop.feedback.slice(0, 3);

    const headerSliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        cssEase: 'linear'
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false,
                }
            }
        ]
    };

    const handleImageClick = (photo) => {
        setPreviewImage(photo);
    };

    const handleClosePreview = () => {
        setPreviewImage(null);
    };


    return (
        <div>
            <Header />
            <Toast ref={toast} />
            <div className="workshop-details-container">
                <div className="back-link" >
                    <Link to="/workshops"><FaChevronLeft />Back to Workshops</Link>
                </div>
                <div className='workshop-details'>
                    <div className='wd-name'>{workshop.workshopName}</div>
                    <div className='wd-description'>{workshop.description}</div>
                    <div className='wd-date-time flex'>
                        <div className='w-date'>{workshop.date} / </div>
                        <div className='w-readtime'>{workshop.read_time}</div>
                    </div>
                    <div className='share'>
                        <div className='icon-name'>
                            <div className='pr-2'>
                                <img src={bulb} alt="" className='bulb' />
                            </div>
                            <div className='profile-name'>{workshop.title}</div>
                        </div>
                        <div className='share-dropdown'>
                            <div className='pr-1 pt-1'><FaShareSquare /></div>
                            <div>
                                <div onClick={toggleDropdown} style={{ cursor: 'pointer' }}>Share</div>
                                {isDropdownOpen && (
                                    <ul ref={dropdownRef} className='dropdown-wdmenu'>
                                        {/* <li onClick={() => handleShare('Email')}>
                                            <MdEmail className='social-icon' />Email</li> */}
                                        <li onClick={() => handleShare('WhatsApp')}>
                                            <IoLogoWhatsapp className='social-icon' style={{ color: '#25D366' }} />WhatsApp</li>
                                        <li onClick={() => handleShare('Facebook')}>
                                            <FaFacebook className='social-icon' style={{ color: '#316FF6' }} />Facebook</li>
                                        <li onClick={() => handleShare('X')}>
                                            <FaSquareXTwitter className='social-icon' />X</li>
                                        <li onClick={() => handleShare('LinkedIn')}>
                                            <IoLogoLinkedin className='social-icon' style={{ color: '#0077B5' }} />LinkedIn</li>
                                        <CopyToClipboard text={window.location.href}>
                                            <li onClick={() => handleShare('Copy Link')}>
                                                <FaLink className='social-icon' />Copy Link</li>
                                        </CopyToClipboard>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='wd-header-image'>
                            <Slider {...headerSliderSettings}>
                                {workshop.headerImages.map((headerImage, index) => (
                                    <div key={index}>
                                        <img className='header-image' src={headerImage} alt={`Header ${index}`} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className='grid place-time-details'>
                            <div className='col-12 md:col-6 lg:col-3'>
                                <div className='wd-place-details'> Place:</div>
                                <div className='wd-place'>{workshop.place}</div>
                            </div>
                            <div className='col-12 md:col-6 lg:col-3'>
                                <div className='wd-place-details'> Workshop Date:</div>
                                <div className='wd-place'>{workshop.worksshopDate}</div>
                            </div>
                            <div className='col-12 md:col-6 lg:col-3'>
                                <div className='wd-place-details'> Workshop Duration:</div>
                                <div className='wd-place'>{workshop.workshopDuration}</div>
                            </div>
                            <div className='col-12 md:col-6 lg:col-3'>
                                <div className='wd-place-details'> Assitance:</div>
                                <div className='wd-place'>{workshop.assitance}</div>
                            </div>
                        </div>
                    </div>

                    {workshop.about && (
                        <div>
                            <div className='side-heading'>About Workshop</div>
                            {workshop.about.map((ab, index) => (
                                <div key={index}>
                                    <p>{ab}</p>
                                </div>
                            ))}
                        </div>
                    )}

                    {workshop.secondImage && (
                        <div className='wd-header-image'>
                        <Slider {...headerSliderSettings}>
                            {workshop.secondImage.map((sImage, index) => (
                                <div key={index}>
                                    <img className='header-image' src={sImage} alt={`sImage ${index}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    )}

                    <div>
                        <div className='side-heading'>Topics in the Workshop</div>
                        <div>
                            {workshop.topics.map((topic, index) => (
                                <div key={index}>
                                    <strong className="ws-topic">{topic.name}</strong>
                                    <ul className="ws-points">
                                        {topic.subpoints.map((subpoint, subIndex) => (
                                            <li key={subIndex}>{subpoint}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className='side-heading'>Guest speakers/ speaker</div>
                        <div className='guest-list'>
                            {workshop.guest.map((guest, index) => (
                                <div key={index} className='grid guest'>
                                    <div className='col-12 md:col-12 lg:col-3 xl:col-2'>
                                        <img src={guest.Image} alt={guest.name} className='guest-image' />
                                    </div>
                                    <div className='col-12 md:col-12 lg:col-9 xl:col-10 guest-info'>
                                        <div className='guest-content'>
                                            <div>
                                                <div className='guest-name'>{guest.name}</div>
                                                <div className='guest-designation'>{guest.designation}</div>
                                            </div>
                                            <div className="back-link">
                                                <Link to={`/speaker/${guest.name.replace(/\s+/g, '-').toLowerCase()}`} className="front-link">See Speaker Profile <FaChevronRight /> </Link>
                                            </div>
                                        </div>
                                        <div className='guest-description'>{guest.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className='side-heading'>Experience of attending and feedback</div>
                        <div className='grid feedback-grid'>
                            {feedbackToShow.map((fb, index) => (
                                <div className='feedback sm:col-12 md:col-6 lg:col-4'>
                                    <Card key={index} className='wd-feedback-card'>
                                        {/* <div className='fb-name p-1'>{fb.attendee}</div> */}
                                        <div className='fb-description p-1'>{fb.comment}</div>
                                        <div className="flex justify-content-left p-1 mt-2 mb-2">
                                            <Rating value={5} readOnly cancel={false} style={{ color: 'green' }} />
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div>
                        {!showAllFeedback && workshop.feedback.length > 3 && (
                            <button className='wd-view-more-button' onClick={() => setShowAllFeedback(true)}>View More</button>
                        )}

                        {showAllFeedback && workshop.feedback.length > 3 && (
                            <button className='wd-view-more-button' onClick={() => setShowAllFeedback(false)}>View Less</button>
                        )}

                    </div>
                    <div>
                        <div className='side-heading'>Photo Gallery</div>
                        <div className='wd-courosel'>
                            <Slider {...settings}>
                                {workshop.photos.map((photo, index) => (
                                    <div key={index} className="slick-slide">
                                        <img src={photo} alt={`workshopImage-${index}`} className="slick-image" onClick={() => handleImageClick(photo)} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className='wd-social-media'>
                        {/* <div onClick={() => handleShare('Email')}>
                            <MdEmail className='social-icon' /></div> */}
                        <div onClick={() => handleShare('WhatsApp')}>
                            <IoLogoWhatsapp className='social-icon' style={{ color: '#25D366' }} /></div>
                        <div onClick={() => handleShare('Facebook')}>
                            <FaFacebook className='social-icon' style={{ color: '#316FF6' }} /></div>
                        <div onClick={() => handleShare('X')}>
                            <FaSquareXTwitter className='social-icon' /></div>
                        <div onClick={() => handleShare('LinkedIn')}>
                            <IoLogoLinkedin className='social-icon' style={{ color: '#0077B5' }} /></div>
                        <CopyToClipboard text={window.location.href}>
                            <div onClick={() => handleShare('Copy Link')}>
                                <FaLink className='social-icon' /></div>
                        </CopyToClipboard>
                    </div>
                </div>
            </div >
            <Footer />
            {previewImage && (
                <div className="image-preview-modal" onClick={handleClosePreview}>
                    <div className="modal-content">
                        <img src={previewImage} alt="Preview" className="preview-image" />
                    </div>
                </div>
            )}
        </div >
    );
};

export default WorkshopDetails;
