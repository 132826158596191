// Courses.js
import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import axios from "axios";
import "./Courses.scss";
import image from "../../../Assets/Courses/coursescard-image.jpeg";
import certificate from "../../../Assets/Courses/certificate.png";
import {
  GET_ALL_COURSES,
  GET_TOPIC_LISt,
  CONTENT_AUTHORIZE_URL,
  GET_ENROLLEDCOURSE_BYID,
  POST_ENROLL_COURSE,
} from "../../../Utils/APIService";
import { Paginator } from "primereact/paginator";
import { FaArrowLeft } from "react-icons/fa";

const Courses = ({ onTopicSelect, onCourseSelect, onActiveSectionChange }) => {
  const userId = localStorage.getItem("userId");
  const userAccessToken = localStorage.getItem("userAccessToken");
  const [getCourses, setGetCourses] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);
  const [enrolledCourses, setEnrolledCourses] = useState([]);

  const config = {
    headers: { Authorization: `Bearer ${userAccessToken}` },
  };
  const getEnrolledCourses = () => {
    const enrolledCourseResponse = axios
      .get(`${GET_ENROLLEDCOURSE_BYID}${userId}`, config)
      .then((response) => {
        setEnrolledCourses(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchCourses();
    getEnrolledCourses();
  }, []);

  const fetchCourses = () => {
    axios
      .get(GET_ALL_COURSES, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userAccessToken"),
        },
      })
      .then((response) => {
        setGetCourses(response.data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const fetchTopicList = (courseId) => {
    axios
      .get(`${GET_TOPIC_LISt}${courseId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userAccessToken"),
        },
      })
      .then((response) => {
        setTopics(response.data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const handleCourseClick = (course) => {
    fetchTopicList(course.courseId);
    setSelectedCourse(course);
    onCourseSelect(course.courseId);
  };

  const handleEnrollCourse = (course) => {
    const payload = {
      courseId: course.courseId,
      userId: userId,
    };

    axios
      .post(POST_ENROLL_COURSE, payload, config)
      .then((response) => {
        handleCourseClick(course);
      })
      .catch((error) => {
        console.error("Enrollment Error:", error);
      });
  };

  const onPageChnage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const isUserEnrolled = (courseId) => {
    return enrolledCourses.some((course) => course.courseId === courseId);
  };

  const paginatedCourses = getCourses?.slice(first, first + rows);

  const handleBackClick = () => {
    getEnrolledCourses();
    setSelectedCourse(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getUTCFullYear();

    const getDaySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const formattedDate = `${day}${getDaySuffix(day)} ${month} ${year}`;
    return formattedDate;
  };

  return (
    <div className="courses-container">
      {selectedCourse ? (
        <>
          <div className="course-header">
            <div className="back-btn cursor-pointer" onClick={handleBackClick}>
              {" "}
              <FaArrowLeft /> Back to Courses
            </div>
            <div className="dashboard-title">{selectedCourse.title}</div>
            <div className="completion-track-container">
              <h3>Your Completion Track</h3>
              <div className="progress-bar">
                {Array.from({ length: 5 }, (_, index) => (
                  <div key={index} className="step-container">
                    <div className="dot"></div>
                  </div>
                ))}
                <div className="dot last-dot">
                  <img
                    src={certificate}
                    alt="Certificate"
                    className="c-image"
                  />
                </div>
              </div>
              <div className="label-container">
                <span>Start of Course</span>
                <span>Completion</span>
              </div>
            </div>
          </div>
          <div className="topics-list">
            {topics.map((topic, index) => (
              <div key={index} className="course-card">
                <div className="topic-title">{topic.topicTitle}</div>
                <div className="time">Time to complete: {topic.time}</div>
                <div className="t-desc">{topic.description}</div>
                <button
                  className="go-to-topic-btn"
                  onClick={() => onTopicSelect(topic)}
                >
                  Go to topic
                </button>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="courses-section-grid flex flex-column">
          <h1 className="my-course-heading">My Courses</h1>
          <div className="grid">
            {paginatedCourses.map((course, index) => (
              <div
                key={index}
                className={`col-12 md:col-4 lg:col-4 ${
                  isUserEnrolled(course.courseId) ? "cursor-pointer" : ""
                }`}
                onClick={
                  isUserEnrolled(course.courseId)
                    ? () => {
                        handleCourseClick(course);
                      }
                    : null
                }
              >
                <Card className="custom-card">
                  <div className="card-image-container">
                    <img
                      src={image}
                      alt={course.title}
                      className="card-image"
                    />
                  </div>
                  <div className="card-details">
                    <div className="course-title">{course.title}</div>
                    {!isUserEnrolled(course.courseId) ? (
                      <div
                        className="enrolled-label cursor-pointer flex align-items-center gap-2"
                        onClick={() => {
                          handleEnrollCourse(course);
                        }}
                      >
                        <span>Enroll Now</span>
                        <i className="pi pi-arrow-circle-right"></i>
                      </div>
                    ) : null}

                    {isUserEnrolled(course.courseId) ? (
                      <div className="course-date">Started</div>
                    ) : null}
                  </div>
                </Card>
              </div>
            ))}
          </div>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={getCourses.length}
            onPageChange={onPageChnage}
            className="course-paginator-section"
          ></Paginator>
        </div>
      )}
    </div>
  );
};

export default Courses;
