import React from "react";
import "./OurBusinesses.scss";
import Laulama from "../../../Assets/Home/Laulama_logo.png";
import ACC from "../../../Assets/Home/ACC_logo.png";
import S2LAcademy from "../../../Assets/Home/S2L_Academy_Logo.png";

const OurBusinesses = () => {
    const data = [
        {
            title: "Laulama",
            description:
                "Laulama School, a successful Startup2Launch initiative, is transforming education by innovating school improvement systems, empowering leaders, educators, and students to achieve breakthrough progress and create a more effective learning environment.",
            imageUrl: Laulama,
            readMore: "/project/laulama",
        },
        {
            title: "Autism Complete Care",
            description:
                "Autism Complete Care, an impactful venture born from Startup2Launch, began with experts in behavior analysis, psychiatry, and technology, aiming to revolutionize autism therapy through a comprehensive, innovative approach.",
            imageUrl: ACC,
            readMore: "/project/autism-complete-care",
        },
        {
            title: "S2L Academy",
            description:
                "Unlock your potential! Join our exclusive programs and workshops to gain cutting-edge skills that set you apart in your future career--including launching a business while still in school. A unique opportunity for high school students ready to lead and innovate.",
            imageUrl: S2LAcademy,
            readMore: "/academy",
        },
    ];

    return (
        <div className="business-section">
            <h2>We have Launched Real Businesses.</h2>
            <p className="subtitle">
                We empower professionals and students to launch real businesses, turning
                their innovative ideas into impactful ventures. Through our unique blend
                of hands-on learning, mentorship, and resources, we provide the skills
                and guidance needed to bring entrepreneurial visions to life.
            </p>

            <div className="business-content">
                {data.map((item, index) => (
                    <div className="business-item" key={index}>
                        <div className="business-header">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                        <p>{item.description}</p>
                        <a href={item.readMore} className="learn-more">
                            Read more
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurBusinesses;
