import React from "react";
import "./OurTeam.scss";
import Abha from "../../../Assets/AboutUs/Team cards new/Abha.png";
import Allwin from "../../../Assets/AboutUs/Team cards new/Allwin.png";
import Arpita from "../../../Assets/AboutUs/Team cards new/Arpita.png";
import Asiba from "../../../Assets/AboutUs/Team cards new/Asiba.png";
import Batrick from "../../../Assets/AboutUs/Team cards new/Batrick.png";
import Gopal from "../../../Assets/AboutUs/Team cards new/Gopal.png";
import Jayanth from "../../../Assets/AboutUs/Team cards new/Jeyanth.png";
import Venket from "../../../Assets/AboutUs/Team cards new/Venket.png";
import Nicky from "../../../Assets/AboutUs/Team cards new/Nicky.png";
import Krishna from "../../../Assets/AboutUs/Team cards new/Krishna.png";
import Prathyusha from "../../../Assets/AboutUs/Team cards new/Prathyusha.png";
import Jerin from "../../../Assets/AboutUs/Team cards new/Jerin.png";
import Mahika from "../../../Assets/AboutUs/Team cards new/Mahika.png";
import Guillermo from "../../../Assets/AboutUs/Team cards new/Memo.png";
import Nidhi from "../../../Assets/AboutUs/Team cards new/Nidhi.png";
import Phani from "../../../Assets/AboutUs/Team cards new/Phani.png";
import Shaji from "../../../Assets/AboutUs/Team cards new/Shaji.png";
import Tania from "../../../Assets/AboutUs/Team cards new/Tania.png";
import Jenish from "../../../Assets/AboutUs/Team cards new/Jenish.png";
import Vijith from "../../../Assets/AboutUs/Team cards new/Vijith.png";

const OurTeam = () => {
    const teamMembers = [
        { name: 'Abha', position: 'Head of Product', img: Abha },
        { name: 'Allwin', position: 'Software Engineer', img: Allwin },
        { name: 'Arpita', position: 'Head of Operations', img: Arpita },
        { name: 'Asiba', position: 'Marketing Analyst', img: Asiba },
        { name: 'Batrick', position: 'Software Engineer', img: Batrick },
        { name: 'Guillermo', position: 'Sr. UX, Experience Analyst', img: Guillermo },
        { name: 'Jayanth', position: 'Software Engineer', img: Jayanth },
        { name: "Jenish", position: "Software Engineer", img: Jenish },
        { name: 'Jerin', position: 'Sr. Software Engineer', img: Jerin },
        { name: 'Krishna', position: 'Head of Technology', img: Krishna },
        { name: 'Mahika', position: 'Head of Pre-Sales, Coach', img: Mahika },
        { name: 'Nicky', position: 'Technical Advisor, Coach', img: Nicky },
        { name: 'Nidhi', position: 'UX, Experience Analyst', img: Nidhi },
        { name: 'Prathyusha', position: 'Technical Mgr. Front End', img: Prathyusha },
        { name: 'Shaji', position: 'Lead, Software Engineer', img: Shaji },
        { name: 'Tania', position: 'Lead, Marketing', img: Tania },
        { name: 'Venket', position: 'Technical Advisor, Coach', img: Venket },
        { name: "Vijith", position: "Software Engineer", img: Vijith },
    ];

    const executiveAdvisors = [
        { name: "Phani", position: "Executive Advisor, Coach", img: Phani },
        { name: "Gopal", position: "Executive Advisor, Coach", img: Gopal },
    ];
    return (
        <div className="s2l-academy-ourteam">
            <div className="our-team-container">
                <div className="our-team-header">
                    <h2>Our Team</h2>
                    <p className="text-center">
                        Startup2Launch is founded on our diverse team of experts. Spanning
                        the USA, Mexico, and India, our 16 skilled professionals bring deep
                        expertise in strategic planning, software development, creative
                        design, and startup growth. This multicultural mix enriches our
                        perspectives and allows us to approach challenges creatively and
                        adaptably.
                    </p>
                </div>

                <div className="our-team-members flex flex-row flex-wrap w-12 mt-7">
                    {teamMembers.map((member) => (
                        <div
                            className="team-member shadow-5 border-round-xl p-3 mb-4"
                            key={member.name}
                        >
                            <div className="member-img">
                                <img src={member.img} alt={member.name} />
                            </div>

                            <h3>{member.name}</h3>
                            <p>{member.position}</p>
                        </div>
                    ))}
                </div>

                <h2 className="exe-advisor-heading text-center">Executive Advisor</h2>

                <div className="our-team-executive-members flex flex-row flex-wrap w-12 gap-7 justify-content-center">
                    {executiveAdvisors.map((advisor) => (
                        <div className="team-member shadow-5 border-round-xl p-3 mb-4" key={advisor.name}>
                            <div className="member-img">
                                <img src={advisor.img} alt={advisor.name} />
                            </div>
                            <h3>{advisor.name}</h3>
                            <p>{advisor.position}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurTeam;
