import React from 'react'
import "./PastEvents.scss"
import img1 from "../../../Assets/Plans/Ellipse 38.jpg"
import { Button } from 'primereact/button'
import WSData from '../../../NewLandingPage/Workshop/Academy-Workshops/WorkshopsData'
import { Link, useNavigate } from 'react-router-dom'


export default function PastEvents() {
  const navigate = useNavigate()
  const lastEvent = WSData[0];

  const handleClickPastEvent = () => {
    navigate(`/workshop/${encodeURIComponent(lastEvent.url.replace(/\s+/g, '-').toLowerCase())}`);
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className="past-events">
        <h2 className="past-events-header">Our Past Programs</h2>

        <div className="past-events-body">
          <div className="past-events-body-section">
            <div className="past-event-image">
              <img src={img1} alt />
            </div>
            <div className="past-event-content">
              <h3 className="event-content-title">
                Master Entrepreneurship, Excel High School and Ace College
                Admissions
              </h3>
              <p className="event-content-desc">
                Discover Your True Potential! Participate in our exclusive
                3-hours workshop and acquire cutting-edge skills that will
                distinguish you in your future career. This is a unique chance
                for high school students eager to lead and innovate. Don’t let
                this opportunity pass you by; enroll in our workshop and
                embark on your journey today!
              </p>
              <div className="event-content-button">
                {/* <Button label="Read more" onClick={handleClickPastEvent} /> */}
                <span onClick={() => { navigate("/workshops"); window.scrollTo(0, 0); }} >Past Events &gt;&gt; </span>
                <span onClick={handleClickPastEvent}>Read More &gt;&gt; </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='past-events-footer'>
          <Button onClick={() => { navigate("/workshops"); window.scrollTo(0, 0); }}>See Past Events</Button>
        </div> */}
      </div>
    </>
  )
}
