import React from 'react'
import "./Appointment.scss"
import bgImg from "../../../Assets/Home/Background_pink.png"
import CalendlyComponent from '../../../Shared-Components/Calendly/Calendly'

export default function Appointment() {

  const pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '#8DAF61',
    textColor: '#352750'
  }

  return (
    <>
      <div className="appointment-container">
          <div className='appintment-sec' style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="ac-part-one">
              <h2 className="ac-title">Are you interested?</h2>
              <h4 className="ac-content">
                Let our team assist you in reaching your destination.
              </h4>
            </div>
            <div className="ac-part-two">
              <div className="ac-talk-now">
                <CalendlyComponent
                  buttonLabel="Schedule an Appointment to Talk Now"
                  pageSettings={pageSettings}
                  url="https://calendly.com/contactus-s2l/30min"
                />
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
