import React, { useState } from "react";
import "./Header.scss";
import logoOpen from "../../../Assets/Home Page/S2L-Logo.svg";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleRedirect = () => {
        setShowMenu(false);
        navigate("/questionnaire");
    };

    const isActive = (path) =>
        location.pathname === path ? "active-newMenu-item" : "";

    return (
        <header className="s2l-headers">
            <nav className="navigation">
                <div className={`newMenu ${showMenu ? "show-newMenu" : ""}`}>
                    <div className="s2l-nav-newMenu">
                        <div className="logo">
                            <a href="/">
                                <img src={logoOpen} alt="S2L Logo" />
                            </a>
                        </div>

                        <div
                            className={`newMenu-toggle ${showMenu ? "active" : ""}`}
                            onClick={toggleMenu}
                        >
                            <div className={`bar ${showMenu ? "cross" : ""}`}></div>
                            <div className={`bar ${showMenu ? "cross" : ""}`}></div>
                            <div className={`bar ${showMenu ? "cross" : ""}`}></div>
                        </div>
                    </div>

                    <ul>
                        <li className={isActive("/")}>
                            <a href="/">Home</a>
                        </li>
                        <li className={isActive("/programs")}>
                            <a href="/programs">Programs</a>
                        </li>
                        <li className={isActive("/academy")}>
                            <a href="/academy">Academy</a>
                        </li>
                        <li className={isActive("/aboutus")}>
                            <a href="/aboutus">About Us</a>
                        </li>
                        <li>
                            <Button className="p-button" onClick={handleRedirect}>
                                Get Started
                            </Button>
                        </li>
                    </ul>

                </div>
            </nav>
        </header>
    );
};

export default Header;
