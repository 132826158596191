import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { USER_INFO_URL, CONTENT_AUTHORIZE_URL, PAYMENT_EDIT_URL } from '../../../Utils/APIService'
import { CONTENT_HEADERS } from '../../../Utils/LandingService'
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from 'primereact/dialog';

const Users = () => {

    const [userData, setUserData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        axios
            .get(USER_INFO_URL, {
                headers: {
                    "content-type": "application/json",
                    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
                }
            })
            .then(response => {
                setUserData(response.data);
            })
    }

    const handleEdit = (rowData) => {
        setSelectedId(rowData.studentInfoResponse.userId);
        setDialogOpen(true);
    }

    const actionBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <Button
                    rounded
                    outlined
                    onClick={() => handleEdit(rowData)}
                    className="action-button"
                >
                    <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
            </React.Fragment>
        );
    };

    const hideDialogOpen = () => {
        setDialogOpen(false);
    };

    const handlePayment = () => {

        if (selectedId) {

            const payload = {
                userId: selectedId,
                isPaid: true
            }

            axios
                .post(PAYMENT_EDIT_URL, payload, { headers: CONTENT_HEADERS })
                .then((res) => {
                    setDialogOpen(false);
                    fetchData();
                })
                .catch((error) => {
                    console.error('Delete API Error:', error);
                });
        } else {
            console.error('Invalid data for deletion.');
        }
    };

    const dialogFooter = (
        <React.Fragment>
            <Button label="No" severity="secondary" icon="pi pi-times" outlined onClick={hideDialogOpen} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={handlePayment} />
        </React.Fragment>
    )

    return (
        <>
            <div className="courses-container m-4">
                <div className="course-header">
                    <div className="dashboard-title text-left p-3 mt-0">Users</div>
                </div>

                <Card className="flex flex-column justify-content-center items-center ">
                    <DataTable
                        value={userData}
                        selectionMode="checkbox"
                        onSelectionChange={(e) => setSelectedRows(e.value)}
                        paginator rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        columnResizeMode="expand"
                        showGridlines
                    // removableSort
                    // selection={selectedRows}
                    // onSelectionChange={(e) => {
                    //     setSelectedRows(e.value);
                    // }}
                    // value={userData}
                    // selectionMode="checkbox"
                    // paginator rows={10}
                    // rowsPerPageOptions={[5, 10, 25, 50]}
                    // tableStyle={{ minWidth: '50rem' }}
                    // columnResizeMode="expand"
                    // resizableColumns
                    // showGridlines
                    >
                        <Column
                            field="firstName"
                            header="First Name"
                            body={rowData => `${rowData.studentInfoResponse.firstName}`}

                        />
                        <Column
                            field="lastName"
                            header={"Last Name"}
                            body={rowData => ` ${rowData.studentInfoResponse.lastName}`}
                        />
                        <Column field="email"
                            header={"Email"}
                            body={rowData => `${rowData.studentInfoResponse.email}`}
                        />
                        <Column field="phoneNumber" header="Phone Number"
                            body={rowData => `${rowData.studentInfoResponse.phoneNumber}`} />

                        <Column
                            header="Actions"
                            body={actionBodyTemplate}
                            exportable={false}
                        ></Column>
                    </DataTable>
                </Card>

                <Dialog visible={dialogOpen} style={{ width: '32rem' }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    header="Confirm" modal footer={dialogFooter}
                    onHide={() => setDialogOpen(false)}
                >
                    <div className="confirmation-content flex justify-content-center mt-4">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '1.5rem' }} />
                        <span className="flex text-center">
                            Has the payment done?
                        </span>
                    </div>
                </Dialog>
            </div>
        </>
    )
}

export default Users