import React from "react";
import "./OurStory.scss";

const OurStory = () => {
    const services = [
        {
            id: 1,
            title: "Product Launch",
        },
        {
            id: 2,
            title: "Market Research",
        },
        {
            id: 3,
            title: "Design",
        },
        {
            id: 4,
            title: "Branding",
        },
        {
            id: 5,
            title: "Development",
        },
        {
            id: 6,
            title: "Funding",
        },
        {
            id: 7,
            title: "Legal compliance",
        },
    ];
    return (
        <div className="s2l-academy-ourstory">
            <div className="ourstory-container">
                <h2>Our Story</h2>
                <div className="story-content">
                    <p>
                        Startup2Launch was founded to bridge the gap between innovative
                        ideas and successful businesses. Our founders, experienced
                        entrepreneurs, understood firsthand the challenges of turning a
                        startup concept into a reality. They recognized that what many
                        founders needed was structured, end-to-end support. That's why
                        Startup2Launch was created—to provide the guidance necessary to
                        bring ideas to market. After successfully launching a MedTech
                        startup in just 18 months, our founders wanted to help others
                        achieve similar success. At Startup2Launch, we believe in the
                        potential of every idea to make a real impact. By securing an equity
                        stake in each project, we align our goals with our clients' and
                        share a passion for creating transformative businesses.
                    </p>
                </div>
                <div className="our-services-abtus">
                    <p className="font-medium">
                        Our comprehensive range of services gives startups the tools they
                        need to thrive, including:
                    </p>
                    <div className="about-services-list flex flex-row justify-content-center flex-wrap w-12 mb-3 mt-5">
                        {services.map((service) => (
                            <div className="about-service-item  p-3 border-round-3xl align-content-center" key={service.id}>
                                <p >{service.title}</p>
                            </div>
                        ))}
                    </div>
                    <p>
                        We provide an environment that fosters innovation, tackles
                        challenges directly, and makes success attainable.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OurStory;
