// src/components/LandingPage/Academy/Academy-Workshops/SpeakerDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './SpeakerDetails.scss';
import { FaChevronLeft } from 'react-icons/fa6';
import Header from '../../../NewLandingPage/Home/Header/Header';
import Footer from '../../../NewLandingPage/Home/Footer/Footer';

const SpeakerDetails = ({ workshops }) => {
    const { speakerName } = useParams();

    // Find the speaker based on the speakerName parameter in the URL
    let speaker = null;
    workshops.forEach(workshop => {
        const foundSpeaker = workshop.guest.find(g => g.name.replace(/\s+/g, '-').toLowerCase() === speakerName);
        if (foundSpeaker) {
            speaker = foundSpeaker;
        }
    });

    if (!speaker) {
        return (
            <div>
                <Header />
                <div className='speaker-details-container'>
                    <div className='back-link pb-3'>
                        <Link onClick={() => window.history.back()} className="back-button"><FaChevronLeft /> Back</Link>
                    </div>
                    <h2>Speaker Not Found</h2>
                    <p>The speaker you are looking for does not exist.</p>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className='speaker-details-container'>
                <div className='back-link pb-3'>
                    <Link onClick={() => window.history.back()} className="back-button"><FaChevronLeft /> Back</Link>
                </div>
                <div className='grid'>
                    <div className='col-12 md:col-12 lg:col-5 speaker-section-image'>
                        <img className="speakerimage" src={speaker.Image} alt={speaker.name} />
                    </div>
                    <div className='col-12 md:col-12 lg:col-7'>
                        <div className='speakername'>{speaker.name}</div>
                        <div className='speakerdesignation pb-2'>{speaker.designation}</div>
                        <div className='speakerdescription pb-2'>{speaker.fullDescription}</div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SpeakerDetails;
