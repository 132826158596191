import Navigation from './Navigation/Navigation'
import './App.scss';
import ChatBot from './Shared-Components/Chat-Bot/ChatBot';


const App = () => {
  return (
    <>
      <Navigation />
      <ChatBot />
    </>
  );
};

export default App;
