import React from "react";
import AboutUsBanner from "./Banner/AboutUsBanner";
import OurStory from "./OurStory/OurStory";
import OurValues from "./OurValues/OurValues";
import OurProjects from "./OurProjects/OurProjects";
import OurTeam from "./OurTeam/OurTeam";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <AboutUsBanner />
      <OurStory />
      <OurValues />
      <OurProjects />
      <OurTeam />
      <Footer /> 
    </div>
  );
};

export default AboutUs;
