import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill editor styles
import { MdNote, MdLightbulbOutline, MdHelpOutline } from "react-icons/md";
import './topics.scss';
import { ImEnlarge2 } from "react-icons/im";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import axios from 'axios';
import { DELETE_NOTE, GET_ALL_NOTES_BY_USER_TOPIC, POST_ADD_TOPIC, QUILL_UPLOAD_URL, UPDATE_NOTE } from '../../../Utils/APIService';
import { config } from 'aws-sdk';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import profile1 from "../../../Assets/Courses/Speaker/profile-1.png";
import profile2 from "../../../Assets/Courses/Speaker/profile-2.png";

const Topics = ({ topic }) => {
  const userId = localStorage.getItem("userId");
  const userAccessToken = localStorage.getItem("userAccessToken");
  const toast = useRef();
  const editorRef = useRef();
  const [uploadImg, setUploadImg] = useState([]);
  const [noteTitle, setNoteTitle] = useState('');
  const [noteType, setNoteType] = useState('');
  const [noteContent, setNoteContent] = useState(''); // Stores the rich text
  const [updatedNoteTitle, setUpdatedNoteTitle] = useState('');
  const [updatedNoteType, setUpdatedNoteType] = useState('');
  const [updatedNoteContent, setUpdatedNoteContent] = useState('');
  const [notes, setNotes] = useState([]);
  const [image, setImage] = useState(null);
  const [viewDialog, setViewDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [renderKey, setRenderKey] = useState(0);

  const noteTypes = [
    { label: 'Class Notes', value: 'Class Notes' },
    { label: 'New Idea', value: 'New Idea' },
    { label: 'Question', value: 'Question' }
  ];

  useEffect(() => {
    getNotesByTopicAndUser();

  }, [userId, renderKey])

  const config = {
    headers: { Authorization: `Bearer ${userAccessToken}` },
  }

  const getNotesByTopicAndUser = () => {
    const params = {
      params: {
        userId: userId,
        topicId: topic.topicId,
      },
    };

    const noteResponse = axios
      .get(GET_ALL_NOTES_BY_USER_TOPIC, {
        ...config,
        ...params,
      })
      .then((response) => {
        setNotes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const createNote = () => {
    const payload = {
      noteTitle: noteTitle,
      noteType: noteType,
      noteContent: noteContent,
      topicId: topic?.topicId,
      userId: userId,
    };

    const createNoteresponse = axios
      .post(POST_ADD_TOPIC, payload, config)
      .then((response) => {
        closeDialog();
        setNotes((prevNote) => (
          [...prevNote, response.data]
        ))

      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdateNote = () => {

    const payload = {
      noteId: selectedNote.noteId,
      noteTitle: updatedNoteTitle,
      noteType: updatedNoteType,
      noteContent: updatedNoteContent,
      topicId: selectedNote.topicId,
      userId: userId,
    };

    const updateNoteresponse = axios
      .put(UPDATE_NOTE, payload, config)
      .then((response) => {
        closeDialog();
        setRenderKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Handle form submission
  const handleNoteSubmit = (e) => {
    e.preventDefault();
    const newNote = {
      title: noteTitle,
      type: noteType,
      content: noteContent,
      date: new Date().toLocaleDateString(),
      image: image,
    };
    setNotes([newNote, ...notes]);
    // Clear the form fields after submission
    setNoteTitle('');     // Clear the note title
    setNoteType('');      // Clear the note type
    setNoteContent('');   // Clear the note content
    setImage(null);       // Clear the image upload
  };

  // Handle Image Upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result); // Save image data as base64
      };
      reader.readAsDataURL(file);
    }
  };

  const ImageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem(
                "userAccessToken"
              )}`,
            },
          };
          const res = await axios
            .post(QUILL_UPLOAD_URL, formData, config)
            .then((response) => {
              const imageUrl = response.data;

              const quill = editorRef.current.getQuill();
              const range = quill.getSelection();
              quill.insertEmbed(range.index, "image", imageUrl);

              setUploadImg(response.data);
            })
            .catch((error) => console.error(error));
        } catch (err) {
          console.error("Error uploading image:", err);
        }
      }
    };
  };

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
      ],
      handlers: {
        image: ImageHandler,
      },
    },
  };

  // Open the View Dialog
  const openViewDialog = (note) => {
    setSelectedNote(note);
    setViewDialog(true);
  };

  // Open the Edit Dialog
  const openEditDialog = (note) => {
    setSelectedNote(note);
    setUpdatedNoteTitle(note.noteTitle);
    setUpdatedNoteType(note.noteType);
    setUpdatedNoteContent(note.noteContent);
    setEditDialog(true);
  };

  // Save edited note
  const saveEditedNote = () => {
    const updatedNotes = notes.map((note) =>
      note === selectedNote
        ? { ...note, title: noteTitle, type: noteType, content: noteContent }
        : note
    );
    setNotes(updatedNotes);
    setEditDialog(false);

    // Clear the form fields after saving
    setNoteTitle('');     // Clear the note title
    setNoteType('');      // Clear the note type
    setNoteContent('');   // Clear the note content
    setImage(null);       // Clear the image upload
  };

  const closeDialog = () => {
    setEditDialog(false);

    // Clear the form fields after saving
    setNoteTitle('');     // Clear the note title
    setNoteType('');      // Clear the note type
    setNoteContent('');   // Clear the note content
    setImage(null);       // Clear the image upload
  }

  // Handle Delete Note
  const handleDeleteNote = (noteToDelete) => {
    const filteredNotes = notes.filter((note) => note !== noteToDelete);
    setNotes(filteredNotes);
  };

  // Function to get icon based on note type
  const getNoteIcon = (type) => {
    switch (type) {
      case 'Class Notes':
        return <MdNote className="note-icon" />;
      case 'New Idea':
        return <MdLightbulbOutline className="note-icon" />;
      case 'Question':
        return <MdHelpOutline className="note-icon" />;
      default:
        return null;
    }
  };


  const handleAccept = (note) => {
    const deleteNoteResponse = axios
      .delete(DELETE_NOTE, {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
        params: {
          noteId: note?.noteId,
        },
      })
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Confirmed",
          detail: `${response.data}`,
          life: 3000,
        });
        setRenderKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleReject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Cancelled",
      detail: "Your note remains intact",
      life: 3000,
    });
  };

  const confirmNoteDelete = (note) => {
    confirmDialog({
      message: (
        <span className="custom-message">
          <i className="pi pi-info-circle"></i> Do you want to delete this record?
        </span>
      ),
      header: "Delete Confirmation",
      // icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      rejectClassName: "bck-btn",
      accept: () => handleAccept(note),
      reject: handleReject,
    });
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }


  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog draggable={true} className="topic-del-cnf" />
      <div className="topic-details-container">
        <div className="topic-header">
          <div className="topic-name">
            {topic.topicTitle}: {topic.name}
          </div>

          {/* Video Section */}
          <div className="video-section">
            <video width="800" controls>
              <source src={topic?.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={topic?.coverFileUrl} width="800" alt="" />
          </div>
        </div>

        {/* PrimeReact Tabs */}
        <div className="tabs">
          <TabView className="tabview-custom">
            <TabPanel header="Overview">
              <div className="overview">
                {/* <div className='sub-title'>Description</div> */}
                <div
                  dangerouslySetInnerHTML={{ __html: topic.overview }}
                ></div>
                {/* <div className='desc'>{topic.overview}</div> */}
                {/* <div className='sub-title'>Skills Covered</div> */}
                {/* <ul>
                                {topic?.skillsCovered.map((skill, index) => (
                                    <li className="skills" key={index}>{skill}</li>
                                ))}
                            </ul>
                            <div className='sub-title'>Key Points</div>
                            <ul>
                                {topic.keyPoints.map((point, index) => (
                                    <li className="keypoints pb-2" key={index}>
                                        <strong className="keypoints-heading">{point.heading}</strong>: {point.point}
                                    </li>
                                ))}
                            </ul> */}
              </div>
            </TabPanel>

            {/* Notes Section */}
            <TabPanel header="Notes">
              <div className="notes-section">
                <div className="sub-title">My Notes</div>
                <div className="desc">
                  Use this space to share your solutions and discuss with
                  other students.
                </div>

                {/* Form to add new notes */}
                <div className="note-form">
                  <div className="grid">
                    <div className="field col-12 md:col-8 flex flex-column">
                      <label htmlFor="noteTitle">Note Title</label>
                      <InputText
                        id="noteTitle"
                        value={noteTitle}
                        onChange={(e) => setNoteTitle(e.target.value)}
                        placeholder="Enter note title"
                        required
                      />
                    </div>
                    <div className="field col-12 md:col-4 flex flex-column">
                      <label htmlFor="noteType">Note Type</label>
                      <Dropdown
                        id="noteType"
                        value={noteType}
                        options={noteTypes}
                        onChange={(e) => setNoteType(e.value)}
                        placeholder="Select a note type"
                        required
                      />
                    </div>
                    <div className="field col-12 flex flex-column">
                      <label htmlFor="noteContent">Content</label>
                      <Editor
                        theme="snow"
                        ref={editorRef}
                        value={noteContent}
                        onTextChange={(e) => setNoteContent(e.htmlValue)}
                        placeholder="Write your note description here..."
                        modules={modules}
                      />
                    </div>
                  </div>
                  <div className="upload-notes-btn">
                    <Button label="Upload Notes" onClick={createNote} />
                  </div>
                </div>

                {/* Display notes */}
                <div className="grid notes">
                  {notes?.map((note, index) => (
                    <div
                      key={index}
                      className="col-12 md:col-6 lg:col-4 note-card"
                    >
                      <div className="each-note">
                        <div className="note-header">
                          <div className="header-left">
                            {getNoteIcon(note.noteType)}
                            <div className="note-type">{note.noteType}</div>
                          </div>
                          <div>
                            <ImEnlarge2
                              className="view-icons cursor-pointer"
                              onClick={() => openViewDialog(note)}
                            />
                          </div>
                        </div>
                        <div className="note-content">
                          <div className="note-date">{formatDate(note.createdOn)}</div>
                          <div className="note-title">{note.noteTitle}</div>

                          {/* Buttons for delete and edit */}
                          <div className="view-edit-buttons">
                            <FaEdit
                              className="view-edit-icons cursor-pointer"
                              onClick={() => openEditDialog(note)}
                            />
                            <AiFillDelete
                              className="view-edit-icons cursor-pointer"
                              onClick={() => confirmNoteDelete(note)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>

            <TabPanel header="Extra Material">
              <div className="extra-material">
                <h3>Extra Material</h3>
                {/* <p>Here, you can provide extra materials such as PDFs, links, or other resources.</p> */}
                <div>
                  <div>Extra Material Name :</div>
                  <div></div>
                </div>
              </div>
            </TabPanel>

            <TabPanel header="My Teachers" >
              <div className='my-teachers-topic'>
                <div className='my-teacher-header'>
                  <h3>Teachers</h3>
                  <p>If you ever feel stuck or need guidance, feel free to contact your mentor. You can click here to schedule a one-on-one session or send a message.</p>
                </div>
                <div className='my-teachers-content flex flex-row gap-5 '>
                  <div className='teacher '>
                    <div className='teacher-header'>
                      <p className='text-center'>Samantha Garza</p>
                    </div>
                    <div className='teacher-content'>
                      <p className='text-center'>Course Teacher</p>
                      <div className='teacher-profile flex justify-content-center'>
                        <img src={profile1} alt='teacher-profile' className='teacher-avatar' />

                      </div>
                      <p className='text-center'>sgarza@gmail.com</p>
                    </div>
                    <div className='teacher-contact-us flex flex-row justify-content-center'>
                      <Button label='Contact' className='teacher-contact' />
                    </div>
                  </div>
                  <div className='teacher '>
                    <div className='teacher-header'>
                      <p className='text-center'>Samantha Garza</p>
                    </div>
                    <div className='teacher-content'>
                      <p className='text-center'>Course Teacher</p>
                      <div className='teacher-profile flex justify-content-center'>
                        <img src={profile1} alt='teacher-profile' className='teacher-avatar' />

                      </div>
                      <p className='text-center'>sgarza@gmail.com</p>
                    </div>
                    <div className='teacher-contact-us flex flex-row justify-content-center'>
                      <Button label='Contact' className='teacher-contact' />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>

        {/* View Dialog */}
        {selectedNote && (
          <Dialog
            header="Note Details"
            visible={viewDialog}
            style={{ width: "70vw" }}
            onHide={() => setViewDialog(false)}
          >
            <div className="note-details">
              <h3>{selectedNote.title}</h3>
              <p>
                <strong>Type:</strong> {selectedNote.noteType}
              </p>
              <p>
                <strong>Date:</strong> {selectedNote.createdOn}
              </p>
              <p>
                <strong>Content:</strong>
              </p>
              <div
                dangerouslySetInnerHTML={{ __html: selectedNote.noteContent }}
              ></div>
            </div>
          </Dialog>
        )}

        {/* Edit Dialog */}
        {selectedNote && (
          <Dialog
            className="edit-dialog"
            header="Edit Note"
            visible={editDialog}
            style={{ width: "70vw" }}
            onHide={() => closeDialog({})}
          >
            <div className="edit-note">
              <div className="grid">
                <div className="field col-12 md:col-8 flex flex-column">
                  <label htmlFor="editNoteTitle">Note Title</label>
                  <InputText
                    id="editNoteTitle"
                    value={updatedNoteTitle}
                    onChange={(e) => setUpdatedNoteTitle(e.target.value)}
                  />
                </div>
                <div className="field col-12 md:col-4 flex flex-column">
                  <label htmlFor="editNoteType">Note Type</label>
                  <Dropdown
                    id="editNoteType"
                    value={updatedNoteType}
                    options={noteTypes}
                    onChange={(e) => setUpdatedNoteType(e.value)}
                  />
                </div>
              </div>
              <div className="field col-12 flex flex-column">
                <label htmlFor="editNoteContent">Content</label>
                <Editor
                  theme="snow"
                  ref={editorRef}
                  value={updatedNoteContent}
                  onTextChange={(e) => setUpdatedNoteContent(e.htmlValue)}
                  placeholder="Write your note description here..."
                  modules={modules}
                />
              </div>
              <div className="edit-save-btn">
                <Button label="Save" onClick={handleUpdateNote} />
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default Topics;
