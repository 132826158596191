import React from "react";
import "./Home.scss";
import Header from "../../NewLandingPage/Home/Header/Header";
import Banner from "./Banner/Banner";
import FrameWork from "./FrameWorkSection/FrameWork";
import Explore from "./ExploreSection/Explore";
import OurBusinesses from "./OurBusinesses/OurBusinesses";
import ClientReview from "./ClientReview/ClientReview";
import Footer from "./Footer/Footer";

const Home = () => {
    return (
        <>
            <Header />
            <Banner />
            <FrameWork />
            <Explore />
            <OurBusinesses />
            <ClientReview />
            <Footer />
        </>
    )
}

export default Home;