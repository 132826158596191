import React, { useEffect, useState } from 'react';
import "./Project.scss";
import AutismImg from "../../../../Assets/Our-Work/Logo_ACC_Blue_x4.png";
import LaulamaImg from "../../../../Assets/Our-Work/Logo-Laulama.png";
import AryaNowImg from "../../../../Assets/AboutUs/ourprojects/Arya Now.png";
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Footer from '../../../Home/Footer/Footer';
import Header from '../../../Home/Header/Header';


const Project = () => {
    const navigate = useNavigate();
    const { name } = useParams();
    const [selectProject, setSelectedProject] = useState({});
    const [activeAccordian, setActiveAccordian] = useState(null);
    const [activeItem, setActiveItem] = useState(null);

    const projects = [
        {
            id: 1,
            title: "Arya Now",
            year: 2023,
            logo: AryaNowImg,
            description: "AryaNow is a virtual mental health care provider focused on providing the best patient experience. We use audio and video capabilities from the digital devices our patients use securely to help solve their mental health issues anytime, anywhere. There is no waiting for treatment, and it is Easy to access and patient-centric.",
            tags: [
                "Mental Health",
                "TeleMedicine",
            ],
            menu: ["About", "Technologies", "Frameworks", "Testimonials"],
            about: "AryaNow org’s mission is to make psychiatric services accessible, free of cost, and convenient. There are no hoops to jump through, just meaningful, on-demand care at no cost to patients who need quality care.AryaNow org was created by world-class psychiatrists and technologists to overcome barriers like cost, time, and availability, ensuring everyone has access to quality support when and where they need it without worrying about the cost of treatment.",
            technologies: "AryaNow leverages AWS for robust cloud services, responsive design for cross-device compatibility, multi-modal communication for patient-provider interaction, secure payment processing, custom-built HIPAA-compliant EHR, advanced notification systems, intelligent scheduling, and detailed PDF summaries, embodying a comprehensive and secure telehealth platform.",
            frameworks: [
                "AWS suite for scalable and reliable cloud services",
                "HTML5, CSS3, and JavaScript with Angular for responsive front-end",
                "WebRTC and WebSocket for real-time audio/video and chat communication",
                "PayPal APIs for secure payment processing",
                "Custom HIPAA-compliant EHR system with Java and APIs",
                "Service workers and push APIs for real-time notifications",
                "Custom scheduling algorithms or libraries for doctor-patient matching",
                "Apache PDFBox for structured PDF report generation"
            ],
            testimonials: [
                {
                    id: 1,
                    name: "Mina",
                    text: "I was very impressed with the quality of technical services during my doctor’s visit. I was able to be seen right away"
                },
                {
                    id: 2,
                    name: "Ram",
                    text: "Urgent psychiatry in less than two hours from very experienced and professional psychiatrists."
                },
                {
                    id: 3,
                    name: "Jesse",
                    text: "I had a great experience here. Everything was timely, I felt understood and got the results. I was looking for.Very professional."
                },
                {
                    id: 4,
                    name: "Chris",
                    text: "These are great guys. This service is a life saver for mentally ill people. Their Psychiatrists are top notch."
                },
                {
                    id: 5,
                    name: "Bonnie",
                    text: "These are great guys. This service is a life saver for mentally ill people. Their Psychiatrists are top notch."
                }

            ]


        },
        {
            id: 2,
            title: "Laulama",
            year: 2023,
            logo: LaulamaImg,
            website: "https://laulamaschools.com",
            description: "Laulama school is working to innovate education’s underlying improvement system for breakthroughs that transform schools for their leaders, educators, and students. Laulama Learning and Laulama Schools result from a 13-year venture focused on impacting education in Hawaii. This venture started in 2010 with the formation of an organizational transformation practice called Optimum Business Solutions.",
            tags: [
                "Education"
            ],
            menu: ["About", "Technologies", "Frameworks", "Testimonials"],
            about: "Mel Horikami began by learning as much as he could about the challenges facing the public education system in Hawaii. This undertaking involved queries and discussions with a wide range of stakeholders involved in the education arena—business leaders, community members, union representatives, administrators, teachers, students, parents, and many others. He also gained first-hand knowledge by volunteering at schools to work with administrators and staff from elementary to high schools and at district and state offices. Through these efforts, Mel learned about the persistent “nature” of the educational challenge and realized it was not due to a lack of good instructional practices, programs, resources, or dedicated teachers and leaders. He concluded that schools needed a way to combine all the good inputs for increased results and envisioned a platform to serve that purpose.",
            technologies: [
                "AWS Cloud Technologies: Provides a robust and scalable infrastructure.",
                "React for Front-End: Delivers a dynamic and responsive user interface across devices.",
                "Design Thinking Methodologies: Ensures a user-centric approach to product development.",
                "API Ecosystem: Facilitates connectivity and integration of various services and data.",
                "Java Framework for Back-End: Offers a secure and robust server-side solution.",
                "Scheduling Module: Manages class schedules, appointments, and resources efficiently.",
                "Report Generating: Automates the creation of insightful and structured reports."
            ]
            ,
            frameworks: [
                "AWS suite for scalable and reliable cloud services",
                "ReactJS, HTML5, CSS3, and JavaScript for responsive front-end",
                "WebRTC and WebSocket for real-time audio/video and chat communication",
                "PayPal APIs for secure payment processing",
                "Custom Java frameworks, Spring, APIs",
                "Service workers and push APIs for real-time notifications",
                "Custom scheduling algorithms",
                "Apache PDFBox for structured PDF report generation"
            ],
            testimonials: [
                {
                    id: 1,
                    name: "Corey Nakamura",
                    text: "Fantastic Experience - This is different, something I never learned in my entire career in education."
                },
                {
                    id: 2,
                    name: " Leila Kobayashi",
                    text: "Very insightful and helpful! - By attending the Conference, I now better understand Laulama and what we have been doing."
                },
                {
                    id: 3,
                    name: "Tayler Kanoho ",
                    text: "The best experience! - I now know what my issue was that caused the results for my class and what I need to change to improve."
                }

            ]


        },
        {
            id: 3,
            title: "Autism Complete Care",
            year: 2024,
            logo: AutismImg,
            website: "https://www.autismcompletecare.com",
            description: "Autism Complete Care started with a team of experts in behavior analysis, psychiatry, and technologists who wanted to change how autism therapy is done. They saw that every child is special and that therapy should be made just for them. They made a clinic that understands each child’s needs, includes expert mental health advice, and works closely with families in the therapy journey.We began in a small, friendly place, but with a big dedication to our values and the families we help. Our story is about caring deeply, never giving up, and believing in the bright future of every child with autism.",
            tags: [
                "Mental Health",
                "Autism",
                "Child Austism"
            ],
            menu: ["About", "Technologies", "Frameworks", "Testimonials"],
            about: "We envision a world where all children on the autism spectrum receive the support, respect, and opportunities they deserve. To be a beacon of hope and a source of support for families, setting the standard for excellence in autism care through innovative therapies, Psychiatric consultation, compassionate support, and unwavering commitment to the children we serve. At Autism Complete Care, we believe families are integral to the therapeutic process. We are committed to providing therapy for children and support, education, and resources for the whole family. We strive to build strong, collaborative relationships with parents and caregivers, empowering them to advocate for their children within our clinic and in the wider community.",
            technologies: [
                "Design Thinking Methodologies: A user-centric (patients and parents) approach to product development.",
                "AWS Cloud Technologies: Provides a robust and scalable infrastructure.",
                "React for Front-End: Delivers a dynamic and responsive user interface across devices for parents.",
                "API Ecosystem: Facilitates connectivity and integration of various services and data, different third-party systems, and EHR.",
                "Java Framework for Back-End: Offers a secure and robust server-side solution.",
                "Scheduling Module: Manages class schedules, appointments, and resources efficiently.",
                "Report Generating: Automates the creation of insightful and structured reports."
            ],
            frameworks: [
                "AWS suite for scalable and reliable cloud services",
                "ReactJS, HTML5, CSS3, and JavaScript for responsive front-end",
                "Third party APIs using gateways",
                "Payment APIs for secure payment processing",
                "Custom Java frameworks, Spring, APIs",
                "Apache PDFBox for structured PDF report generation",
                "HIPAA-compliant ecosystem"
            ],
            testimonials: [
                {
                    id: 1,
                    name: "Vicky M",
                    text: "I am very pleased and happy with services from Dr. Sunkara and his staff. He has helped with my three year old son with severe ADHD."
                },
                {
                    id: 2,
                    name: "Dinah S",
                    text: "We needed someone with compassion to guide us on treating our son and without hesitation. Not only he has helped us immediately, he has continued to show care."
                },
                {
                    id: 3,
                    name: "Steve K",
                    text: "Dr. Sunkara is awesome! Awesome Doc. He is vary caring and really knows his stuff."
                },

            ]


        },
    ]

    useEffect(() => {
        const projectData = projects.find((project) => project.title.toLowerCase().replace(/ /g, '-') === name);
        setSelectedProject(projectData);
    }, [name]);

    const formatTechnology = (technology) => {

        if (Array.isArray(technology)) {

            return (
                <div className='technology-list'>
                    <p>Here’s a concise summary of the technologies and methodologies employed:</p>
                    <ul>
                        {technology.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            )

        } else {
            return <p>{technology}</p>;
        }
    }

    const renderAboutHeader = (index) => (
        <div className="additionalInfo-header-accordian w-full flex flex-row align-items-center justify-content-between">
            <h2 className='m-0'>About</h2>
            <span>
                <i className={`pi ${activeAccordian === index ? "pi-angle-up" : "pi-angle-down"}`} ></i>
            </span>
        </div>
    );

    const renderTechonlogyHeader = (index) => (
        <div className="additionalInfo-header-accordian w-full flex flex-row align-items-center justify-content-between">
            <h2 className='m-0'>Technologies</h2>
            <span>
                <i className={`pi ${activeAccordian === index ? "pi-angle-up" : "pi-angle-down"}`} ></i>
            </span>
        </div>
    );

    const renderFrameworkHeader = (index) => (
        <div className="additionalInfo-header-accordian w-full flex flex-row align-items-center justify-content-between">
            <h2 className='m-0'>Frameworks</h2>
            <span>
                <i className={`pi ${activeAccordian === index ? "pi-angle-up" : "pi-angle-down"}`} ></i>
            </span>
        </div>
    );

    const renderTestmonialHeader = (index) => (
        <div className="additionalInfo-header-accordian w-full flex flex-row align-items-center justify-content-between">
            <h2 className='m-0'>Testimonials</h2>
            <span>
                <i className={`pi ${activeAccordian === index ? "pi-angle-up" : "pi-angle-down"}`} ></i>
            </span>
        </div>
    );


    const handleScrollTo = (id) => {

        setActiveItem(id);
        const headerOffset = 100;
        const element = document.getElementById(id);
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }


    return (

        <>
            <Header />
            <div className='s2l-our-project-content'>
                <div className='our-work-project-container'>
                    <span className='back-button-project cursor-pointer flex flex-row align-items-center justify-content-start gap-1' onClick={() => navigate(-1)}>
                        <i className='pi pi-angle-left'></i>
                        <p>Back</p>
                    </span>
                    {
                        selectProject &&
                        <div className='project-content-main'>
                            <div className='project-header'>

                                <div className='project-title-header'>
                                    <div className='project-title-img '>
                                        <div className='project-logo'>
                                            <img src={selectProject?.logo} alt={selectProject?.title} className='project-logo' />
                                        </div>
                                        <div className='project-title'>
                                            <h2>{selectProject?.title} <span className='p-title'>Project</span></h2>
                                            <p>{selectProject?.year}</p>
                                        </div>
                                    </div>
                                    <div className='project-website-btn'>
                                        {
                                            selectProject.website &&
                                            <Button label="Visit the Website" onClick={() => window.open(selectProject.website, "_blank")} />
                                        }
                                    </div>
                                </div>


                                <div className='project-desc'>
                                    <p>{selectProject?.description}</p>
                                </div>
                                <div className='project-tag-list flex flex-row gap-4'>
                                    {selectProject?.tags?.map((tag) => (
                                        <p key={tag} className='project-tag'>{tag}</p>
                                    ))}
                                </div>
                            </div>
                            <div className='project-content flex flex-row gap-6 w-12'>
                                <div className='project-menu w-3'>
                                    <h2>Menu</h2>
                                    <div className='project-menu-item flex'>
                                        <p className={activeItem === 'about' ? 'active' : ''} onClick={() => handleScrollTo('about')}>About</p>
                                    </div>
                                    <div className='project-menu-item flex'>
                                        <p className={activeItem === 'technologies' ? 'active' : ''}  onClick={() => handleScrollTo('technologies')}>Technologies</p>
                                    </div>
                                    <div className='project-menu-item flex'>
                                        <p className={activeItem === 'frameworks' ? 'active' : ''} onClick={() => handleScrollTo('frameworks')}>Frameworks</p>
                                    </div>
                                    <div className='project-menu-item flex'>
                                        <p  className={activeItem === 'testimonials' ? 'active' : ''} onClick={() => handleScrollTo('testimonials')}>Testimonials</p>
                                    </div>
                                </div>
                                <div className='project-menu-content w-9'>
                                    <div className='project-about' id='about'>
                                        <h2>About</h2>
                                        <p>{selectProject.about}</p>
                                    </div>
                                    <div className='project-technologies' id='technologies'>
                                        <h2>Technologies</h2>
                                        {formatTechnology(selectProject?.technologies)}
                                    </div>
                                    <div className='project-frameworks' id='frameworks'>
                                        <h2>Frameworks</h2>

                                        <ul>
                                            {
                                                selectProject?.frameworks?.map((framework, index) => (
                                                    <li>
                                                        {framework}
                                                    </li>

                                                ))
                                            }

                                        </ul>


                                    </div>
                                    <div className='project-testimonial' id='testimonials'>
                                        <h2>Testimonials</h2>

                                        <div className='testimonial-template flex flex-row justify-content-center flex-wrap'>

                                            {
                                                selectProject?.testimonials?.map((testimonial) => (
                                                    <div key={testimonial.id} className='testimonial-card '>
                                                        <h3>{testimonial.name}</h3>
                                                        <p>"{testimonial.text}"</p>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>

                    }



                </div>

                <div className='our-work-project-container-sm'>
                    {
                        selectProject &&
                        <div className='project-content-main'>
                            <div className='project-header'>
                                <div className='project-title-header'>
                                    <div className='project-title-img '>
                                        <div className='project-logo'>
                                            <img src={selectProject?.logo} alt={selectProject?.title} className='project-logo' />
                                        </div>
                                        <div className='project-title'>
                                            <h2>{selectProject?.title} <span className='p-title'>Project</span></h2>
                                            <p>{selectProject?.year}</p>
                                        </div>
                                    </div>
                                    <div className='project-website-btn'>
                                        {
                                            selectProject.website &&
                                            <Button label="Visit the Website" onClick={() => window.open(selectProject.website, "_blank")} />
                                        }
                                    </div>
                                </div>
                                <div className='project-desc'>
                                    <p>{selectProject?.description}</p>
                                </div>
                                <div className='project-tag-list flex flex-row gap-3'>
                                    {selectProject?.tags?.map((tag) => (
                                        <p key={tag} className='project-tag'>{tag}</p>
                                    ))}
                                </div>
                            </div>
                            <div className='project-content mt-5 flex flex-row gap-6'>
                                <div className='project-menu-content w-full'>
                                    <Accordion activeIndex={activeAccordian} onTabChange={(e) => { setActiveAccordian(e.index) }}>
                                        <AccordionTab header={renderAboutHeader(0)}>
                                            <p>{selectProject.about}</p>
                                        </AccordionTab>
                                        <AccordionTab header={renderTechonlogyHeader(1)}>
                                            {formatTechnology(selectProject?.technologies)}
                                        </AccordionTab>
                                        <AccordionTab header={renderFrameworkHeader(2)}>
                                            <ul>
                                                {
                                                    selectProject?.frameworks?.map((framework, index) => (
                                                        <li>
                                                            {framework}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </AccordionTab>
                                        <AccordionTab header={renderTestmonialHeader(3)}>
                                            <div className='testimonial-template flex flex-row justify-content-center flex-wrap'>

                                                {
                                                    selectProject?.testimonials?.map((testimonial) => (
                                                        <div key={testimonial.id} className='testimonial-card '>
                                                            <h3>{testimonial.name}</h3>
                                                            <p>"{testimonial.text}"</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </AccordionTab>
                                    </Accordion>
                                </div>
                            </div>


                        </div>

                    }
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Project