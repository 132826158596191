import React, { useEffect, useState } from "react";
import "./EntrepreneurshipJourney.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseyeArrow, faMagnifyingGlass, faPaperPlane, faPersonSign } from '@fortawesome/pro-solid-svg-icons';
import { faPeopleGroup } from '@fortawesome/pro-duotone-svg-icons';

const EntrepreneurshipJourney = () => {

    const navigate = useNavigate();
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        const interval = setInterval(() => {
            setHighlightedIndex(prevIndex => (prevIndex + 1) % 5);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const handleContinue = () => {
        navigate("/questionnaire/1");
    }

    return (
        <>
            <Header />
            <div className="entrepreneurship-journey">
                <h2>Welcome to Your Entrepreneurship Journey!</h2>
                <p>
                    In just 5 quick questions, we’ll help you find the perfect
                    entrepreneurship program that matches your <strong>goals</strong> and{" "}
                    <strong>interests</strong>.
                </p>

                <h4>What We'll Ask You:</h4>

                <div className="questions-container">
                    {[
                        { number: "01", title: "Who You Are", desc: "Tell us if you’re a student or a professional.", icon: faPersonSign },
                        { number: "02", title: "Your Business Interests", desc: "Let us know if you have an idea or are still exploring.", icon: faMagnifyingGlass },
                        { number: "03", title: "Your Business Stage", desc: "Whether you’re brainstorming or growing, we’ll find the right support.", icon: faPaperPlane },
                        { number: "04", title: "Team Status", desc: "Working solo or with a team? Need help finding co-founders? We’ve got you covered.", icon: faPeopleGroup },
                        { number: "05", title: "Your Goal", desc: "Explore, launch, or grow—tell us what you want to achieve.", icon: faBullseyeArrow }
                    ].map((question, index) => (
                        <div
                            key={index}
                            className={`question-box ${index === highlightedIndex ? 'highlighted' : ''}`} // Highlight the active one
                        >
                            <div className="question">
                                <span className="question-number">{question.number}</span>
                                <div className="question-icon">
                                    <FontAwesomeIcon icon={question.icon} />
                                </div>
                            </div>
                            <h3>{question.title}</h3>
                            <p>{question.desc}</p>
                        </div>
                    ))}
                </div>

                <p className="why-it-matters">
                    Why It Matters: Your answers will help us recommend the best program for
                    your needs, from learning to launching or scaling your business.
                </p>

                <Button onClick={handleContinue}>Continue</Button>
            </div>
            <Footer />
        </>
    );
};

export default EntrepreneurshipJourney;