import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import "./Chatbot.scss";
import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
import ReactMarkdown from 'react-markdown';
import { Dropdown } from 'primereact/dropdown';
import avatar1 from "../../../Assets/Settings/avatar1.png";
import avatar2 from "../../../Assets/Settings/avatar2.png";
import avatar3 from "../../../Assets/Settings/avatar3.png";
import avatar4 from "../../../Assets/Settings/avatar4.png";
import avatar5 from "../../../Assets/Settings/avatar5.png";
import avatar6 from "../../../Assets/Settings/avatar6.png";
import avatar7 from "../../../Assets/Settings/avatar7.png";
import avatar8 from "../../../Assets/Settings/avatar8.png";
import avatar9 from "../../../Assets/Settings/avatar9.png";
import avatar10 from "../../../Assets/Settings/avatar10.png";
import avatar11 from "../../../Assets/Settings/avatar11.png";
import avatar12 from "../../../Assets/Settings/avatar12.png";
import bgimg1 from "../../../Assets/Chatbot/Wallpapers/1.jpg";
import bgimg2 from "../../../Assets/Chatbot/Wallpapers/2.jpg";
import bgimg3 from "../../../Assets/Chatbot/Wallpapers/3.jpg";
import bgimg4 from "../../../Assets/Chatbot/Wallpapers/4.jpg";
import bgimg5 from "../../../Assets/Chatbot/Wallpapers/5.jpg";
import bgimg6 from "../../../Assets/Chatbot/Wallpapers/6.jpg";
import bgimg7 from "../../../Assets/Chatbot/Wallpapers/7.jpg";
import bgimg8 from "../../../Assets/Chatbot/Wallpapers/8.jpg";
import bgimg9 from "../../../Assets/Chatbot/Wallpapers/9.jpg";
import bgimg10 from "../../../Assets/Chatbot/Wallpapers/10.jpg";
import bgimg11 from "../../../Assets/Chatbot/Wallpapers/11.jpg";
import bgimg12 from "../../../Assets/Chatbot/Wallpapers/12.jpg";
import bgimg13 from "../../../Assets/Chatbot/Wallpapers/13.jpg";
import axios from 'axios';
import { CHAT_URL, GET_CUSTOM_BOT_URL, USER_INFO_ID_URL, GET_MESSAGE_URL } from '../../../Utils/APIService';
import CustomizationDialog from './CustomizationDialog';

const fontSizes = [
    { name: 'Small', value: 'small' },
    { name: 'Medium', value: 'medium' },
    { name: 'Large', value: 'large' },
];

const backgrounds = [
    { name: 'backgroundImg1', image: bgimg1 },
    { name: 'backgroundImg2', image: bgimg2 },
    { name: 'backgroundImg3', image: bgimg3 },
    { name: 'backgroundImg4', image: bgimg4 },
    { name: 'backgroundImg5', image: bgimg5 },
    { name: 'backgroundImg6', image: bgimg6 },
    { name: 'backgroundImg7', image: bgimg7 },
    { name: 'backgroundImg8', image: bgimg8 },
    { name: 'backgroundImg9', image: bgimg9 },
    { name: 'backgroundImg10', image: bgimg10 },
    { name: 'backgroundImg11', image: bgimg11 },
    { name: 'backgroundImg12', image: bgimg12 },
    { name: 'backgroundImg13', image: bgimg13 },
];

const Chatbot = () => {

    const avatars = [
        { name: 'avatar1', icon: avatar1 },
        { name: 'avatar2', icon: avatar2 },
        { name: 'avatar3', icon: avatar3 },
        { name: 'avatar4', icon: avatar4 },
        { name: 'avatar5', icon: avatar5 },
        { name: 'avatar6', icon: avatar6 },
        { name: 'avatar7', icon: avatar7 },
        { name: 'avatar8', icon: avatar8 },
        { name: 'avatar9', icon: avatar9 },
        { name: 'avatar10', icon: avatar10 },
        { name: 'avatar11', icon: avatar11 },
        { name: 'avatar12', icon: avatar12 },
    ];

    const [avatar, setAvatar] = useState(avatars[0].icon);
    const [bubbleColor, setBubbleColor] = useState('FFFFFF');
    const [backgroundImage, setBackgroundImage] = useState(backgrounds[0].image);
    const [fontSize, setFontSize] = useState(fontSizes[0].name);
    const [fontColor, setFontColor] = useState('000000');
    const [userMessages, setUserMessages] = useState([]);
    const [typedMessage, setTypedMessage] = useState('');
    const [name, setName] = useState('');
    const [botResponse, setBotResponse] = useState(null);

    const [showCustomDialog, setShowCustomDialog] = useState(false);
    const [chatId, setChatId] = useState('');
    const [botId, setBotId] = useState('');

    const [grade, setGrade] = useState('');
    const [, setUserName] = useState('');
    const [category, setCategory] = useState('');

    const [loading, setLoading] = useState(false);

    const messageEndRef = useRef(null);

    const handleCustomStyle = () => setShowCustomDialog(true);

    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const categories = [
        { name: 'Marketing', value: 'Marketing' },
        { name: 'Business', value: 'Business' },
        { name: 'Algebra', value: 'Algebra' },
        { name: 'Trigonometry', value: 'Trigonometry' },
        { name: 'Project Management', value: 'Project Management' },
    ]

    const handleChangeCategory = (e) => {
        setCategory(e.value);
    };

    const fetchData = async () => {
        try {
            const userInfoResponse = await axios.get(USER_INFO_ID_URL + localStorage.getItem('userId'), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userAccessToken")}`
                }
            });
            const studentInfo = userInfoResponse.data.studentInfoResponse;

            setChatId(studentInfo?.chatId);
            setBotId(studentInfo?.botId);
            setGrade(studentInfo?.schoolGrade);
            setUserName(studentInfo?.firstName);

            if (studentInfo?.chatId) {
                await handleGetMsg(studentInfo?.chatId);
            } else {
                console.log("No chatId available");
            }

            if (studentInfo?.botId) {
                const botResponse = await axios.get(GET_CUSTOM_BOT_URL, {
                    params: { botId: studentInfo.botId },
                    headers: { Authorization: `Bearer ${localStorage.getItem('userAccessToken')}` }
                });

                if (
                    botResponse.data !== null
                    // botResponse.data.avatar !== null &&
                    // botResponse.data.fontSize !== null &&
                    // botResponse.data.fontColor !== null &&
                    // botResponse.data.bubbleColor !== null &&
                    // botResponse.data.backgroundImage !== null
                ) {
                    setAvatar(botResponse.data?.avatar);
                    setFontSize(botResponse.data.fontSize);
                    setFontColor(botResponse.data.fontColor);
                    setBubbleColor(botResponse.data.bubbleBackground);
                    setBackgroundImage(botResponse.data.chatTheme);
                    setName(botResponse.data.chatBotName);
                    handleGetMsg(studentInfo?.chatId);
                    setBotResponse(botResponse.data);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleGetMsg = async (chatId) => {
        try {
            const response = await axios.get(GET_MESSAGE_URL + chatId, {
                headers: { Authorization: `Bearer ${localStorage.getItem('userAccessToken')}` },
            });
            const messageResponses = response.data.messageResponses;
            if (!messageResponses || messageResponses.length === 0) {
                console.log("No messages received.");
                return;
            }

            const combinedMessages = messageResponses.flatMap((msg) => [
                { role: 'user', content: msg.prompt },
                { role: 'assistant', content: msg.response },
            ]);
            // setUserMessages((prevMessages) => [...prevMessages, ...combinedMessages]);
            setUserMessages((prevMessages) => {
                const newMessages = [...prevMessages, ...combinedMessages];
                const uniqueMessages = Array.from(new Set(newMessages.map(JSON.stringify))).map(JSON.parse);
                return uniqueMessages;
            });
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    useEffect(() => {
        const initialMessage = {
            role: 'assistant',
            content: `Hi! I’m a chatbot here to help. How can I assist you today?`,
        };
        setUserMessages([initialMessage]);
    }, [name]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setTypedMessage('');

        if (!typedMessage.trim()) return;

        const newMessages = [...userMessages, { role: 'user', content: typedMessage }];
        setUserMessages(newMessages);
        setLoading(true);

        try {
            const response = await axios
                .post(CHAT_URL, {
                    message: typedMessage,
                    category: category,
                    grade: grade,
                    chatId: chatId,
                }, { headers: { Authorization: `Bearer ${localStorage.getItem('userAccessToken')}` } });

            const allResponses = response.data?.allResponses;

            if (allResponses && allResponses.length > 0) {
                const botMessages = allResponses.map((responseContent) => ({
                    role: 'assistant',
                    content: responseContent,
                }));

                const updatedMessages = [...newMessages, ...botMessages];
                setUserMessages(updatedMessages);
            } else {
                const botMessage = { role: 'assistant', content: response.data };
                setUserMessages([...newMessages, botMessage]);
            }
        } catch (error) {
            console.error('Error fetching response:', error);

            setUserMessages([...newMessages, { role: 'assistant', content: 'Oops, something went wrong.' }]);
        }

        setLoading(false);
        setTypedMessage('');
    };

    const onInputChange = (e) => setTypedMessage(e.target.value);

    const handleSaveSettings = (settings) => {
        fetchData();
        setUserMessages([]);
    };

    useEffect(() => {
        scrollToBottom();
    }, [userMessages]);

    const handleOnClose = () => setShowCustomDialog(false);

    return (
        <>
            <div className="chat-bot-header">
                <h2>Welcome to S2L Chatbot</h2>
                <Button className="purple-bg" label="Customize" onClick={handleCustomStyle} />

            </div>

            <div className="chatbot-section">
                <div className="result-page" style={{ backgroundImage: backgroundImage }}>
                    <div className="chat-window">
                        <div
                            className="messages-container"
                            style={{
                                backgroundImage: `url(${backgroundImage})`,
                                backgroundSize: 'cover',
                                height: '100vh',
                                width: '100%',
                                overflowY: 'auto',
                            }}
                        >

                            {userMessages.map((message, index) => {
                                return (
                                    <div key={index}>
                                        {message.role === 'user' && (
                                            <div
                                                className="message user"
                                                style={{
                                                    fontSize: fontSize,
                                                    color: `#${fontColor}`,
                                                    backgroundColor: `#${bubbleColor}`,
                                                    padding: '10px',
                                                    borderRadius: "0 10px 0 10px",
                                                    marginBottom: '10px',
                                                    maxWidth: "fit-content",
                                                    wordWrap: 'break-word',
                                                    marginLeft: 'auto',
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                    opacity: '0.6',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <strong>You:</strong>&nbsp;&nbsp;
                                                <ReactMarkdown>{message.content}</ReactMarkdown>{' '}
                                            </div>
                                        )}

                                        {message.role === 'assistant' && (
                                            <div
                                                className="message assistant"
                                                style={{
                                                    fontSize: fontSize,
                                                    color: `#${fontColor}`,
                                                    backgroundColor: `#${bubbleColor}`,
                                                    padding: '10px',
                                                    borderRadius: "0 10px 0 10px",
                                                    marginBottom: '10px',
                                                    maxWidth: '80%',
                                                    wordWrap: 'break-word',
                                                    marginLeft: '0',
                                                }}
                                            >
                                                <Avatar
                                                    image={avatar}
                                                    shape="circle"
                                                    className="mr-2"
                                                    style={{ cursor: 'pointer', border: '2px solid #8DAF61', padding: "3px" }}
                                                />
                                                <strong>{name}: </strong>{' '}&nbsp;&nbsp;
                                                <ReactMarkdown>{message.content}</ReactMarkdown>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            <div ref={messageEndRef} />
                        </div>

                        {loading && (
                            <div className="message assistant loading">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        )}

                        <form onSubmit={handleSubmit} className="chat-input-form">
                            <Dropdown
                                name='Select the Category'
                                value={category}
                                options={categories}
                                optionLabel='name'
                                optionValue='value'
                                onChange={handleChangeCategory}
                                placeholder='Select the Category'
                                required
                            />

                            <InputText
                                type="text"
                                value={typedMessage}
                                onChange={onInputChange}
                                placeholder="Type your message here..."
                                required
                            />
                            <Button type="submit">Send</Button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="chatbot-dialog">
                {showCustomDialog && (
                    <CustomizationDialog data={botResponse} botId={botId} onSave={handleSaveSettings} handleOnClose={handleOnClose} />
                )}
            </div>
        </>
    )
}

export default Chatbot;