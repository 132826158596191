import React from "react";
import "./OurValues.scss";
import innovationImg from "../../../Assets/AboutUs/ourvalues/Innovation.png";
import integrityImg from "../../../Assets/AboutUs/ourvalues/Integrity.png";
import collaborationImg from "../../../Assets/AboutUs/ourvalues/Collaboration.png";
import excellenceImg from "../../../Assets/AboutUs/ourvalues/Excellence.png";

const OurValues = () => {
    const ourValues = [
        {
            id: 1,
            title: "Innovation",
            img: innovationImg,
            description:
                "We thrive on creativity and forward-thinking, always seeking new methods to bring ideas to life—for example, utilizing rapid prototyping to test concepts quickly.",
        },
        {
            id: 2,
            title: "Integrity",
            img: integrityImg,
            description:
                "Transparency and ethical conduct are at the core of what we do, and they help us build trust with our partners and clients. Our commitment to transparency is seen in our open project timelines and regular client updates.",
        },
        {
            id: 3,
            title: "Collaboration",
            img: collaborationImg,

            description:
                "We build relationships based on respect, open communication, and shared growth, fostering a community that supports entrepreneurs.",
        },
        {
            id: 4,
            title: "Excellence",
            img: excellenceImg,

            description:
                "We are dedicated to maintaining high standards and continuously refining our processes to ensure the best possible outcomes for our clients.",
        },
    ];
    return (
        <div className="s2l-academy-ourvalues">
            <div className="ourvalues-container">
                <h2>Our Values</h2>
                <div className="about-our-values-list flex flex-row w-12 ">
                    {ourValues.map((value) => (
                        <div
                            className="about-our-values-item shadow-5 p-4 border-round-xl"
                            key={value.id}
                        >
                            <img src={value.img} alt={value.title} className="value-img-lg" />
                            <div className="value-title-sec">
                                <h3>{value.title}</h3>
                                <img
                                    src={value.img}
                                    alt={value.title}
                                    className="value-img-sm"
                                />
                            </div>
                            <p>{value.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurValues;
