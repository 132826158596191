import React from "react";
import "./OurProjects.scss";
import AutismImg from "../../../Assets/AboutUs/ourprojects/Autism Complete Care.png";
import LaulamaImg from "../../../Assets/AboutUs/ourprojects/Laulama.png";
import AryaNowImg from "../../../Assets/AboutUs/ourprojects/Arya Now.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const OurProjects = () => {

    const navigate = useNavigate();
    const projects = [
        {
            id: 1,
            title: "Autism Complete Care",
            img: AutismImg,
            description:
                "Autism Complete Care began with a team of experts in behavior analysis, psychiatry, and technology, committed to transforming autism therapy. Recognizing that each child is unique, they focused on providing personalized therapy. They built a clinic that integrates expert mental health guidance and encourages collaboration with families. Over the years, Autism Complete Care has grown into a comprehensive platform that remains deeply committed to the children and families they serve, fostering an environment of compassion and support while empowering children with autism to thrive."
        },
        {
            id: 2,
            title: "Laulama",
            img: LaulamaImg,
            description:
                "Laulama is an EdTech startup committed to transforming the foundational systems of education to drive meaningful improvements for schools, leaders, educators, and students. Laulama Learning and Laulama Schools are the culmination of a 13-year journey dedicated to enhancing education in Hawaii. This journey began in 2010 with the creation of Optimum Business Solutions, an organizational transformation practice, and has since evolved into a comprehensive initiative to foster impactful change in education.",
        },
        {
            id: 3,
            title: "Arya Now",
            img: AryaNowImg,
            description:
                "AryaNow is a virtual mental health care provider dedicated to delivering an exceptional patient experience. By leveraging secure audio and video capabilities on digital devices, we provide timely, accessible support for mental health challenges anytime, anywhere. Our patient-centric approach eliminates traditional barriers, such as long waiting periods for treatment, ensuring individuals receive the help they need when they need it most. AryaNow empowers patients by offering convenient and compassionate mental health services at their fingertips.",
        },
    ];

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay:true,
        autoplaySpeed: 10000,
    };


    const handleProjectRoute = (title) => {
        switch (title) {
            case "Autism Complete Care":
                navigate("/project/autism-complete-care");
                break;
            case "Laulama":
                navigate("/project/laulama");
                break;
            case "Arya Now":
                navigate("/project/arya-now");
                break;
            default:
                break;
        }
    };
    return (
        <div className="s2l-academy-ourprojects">
            <div className="ourprojects-conatiner">
                <h2>Our Projects</h2>
                <div className="about-our-projects-list  ">
                    <Slider {...settings}>
                        {projects.map((project) => (
                            <div className="projects-item-conatiner border-round-xl">
                                <div className="projects-item  p-4 " key={project.id}>
                                    <div className="aboutus-project-logo ">
                                        <img src={project.img} alt={project.title} />
                                    </div>

                                    <div className="project-content ">
                                        <h3>{project.title}</h3>
                                        <p>{project.description}</p>
                                    </div>
                                </div>
                                <div className="read-more p-4 ">
                                    <Button label="Read the full case" onClick={() => {handleProjectRoute(project.title); window.scrollTo(0, 0);}} />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default OurProjects;
