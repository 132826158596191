import React, { useEffect, useRef, useState } from "react";
import logo from '../../Assets/Studentdashboard/logo.png';
import { CONTENT_AUTHORIZE_URL, GET_ADMIN_INFO_URL, LOGOUT_URL } from '../../Utils/APIService';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaChevronDown, FaHome, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { FaDiagramProject } from "react-icons/fa6";
import { OverlayPanel } from "primereact/overlaypanel";
import "../../Module/Dashboard/Dashboard.scss"
import Dashboard from "./Dashboard/Dashboard";
import Users from "./Users/Users";
import Courses from "./Courses/Courses";
import "./Admin.scss";

const Admin = () => {
    const navigate = useNavigate();

    const [showMenu, setShowMenu] = useState(false);
    const [activeSection, setActiveSection] = useState('dashboard');
    const [menuOpen, setMenuOpen] = useState(false);
    const [showContent, setShowContent] = useState(window.innerWidth >= 1008);

    const [data, setData] = useState('')

    const overlayPanelRef = useRef(null);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        axios
            .get(GET_ADMIN_INFO_URL + userId, { headers: CONTENT_AUTHORIZE_URL }
            )
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    const handleLogoClick = () => {
        navigate('/adminDashboard');
    };

    const showProfileMenu = (event) => {
        setShowMenu(!showMenu);
        overlayPanelRef.current.toggle(event);
    };

    const toggleContent = () => {
        setShowContent(!showContent);
        setMenuOpen(!menuOpen);
    };

    window.addEventListener('resize', () => {
        setShowContent(window.innerWidth >= 1009);
    });

    const handleDashboard = () => {
        setActiveSection('dashboard');
    };

    const handleUsers = () => {
        setActiveSection('users');
    };

    const handleCourses = () => {
        setActiveSection('courses');
    };

    const handleLogout = () => {
        axios
            .post(LOGOUT_URL + localStorage.getItem('email'), {}, { headers: { Authorization: `Bearer ${localStorage.getItem("userAccessToken")}` } })
            .then(() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/';
            });
    }

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    return (

        <div className="container">
            <>
                <div className="left-bar p-0">
                    <div className="logo" onClick={handleLogoClick}>
                        <img src={logo} alt="" />
                    </div>
                    <div className="avatar-section">
                        <FaUserCircle style={{ width: "100px", height: "100px" }} />

                        <div className="student-name">
                            Admin
                        </div>
                    </div>
                    <div className="menu-items">
                        <ul>
                            <li
                                className={activeSection === 'dashboard' ? 'active' : ''}
                                onClick={handleDashboard}
                            >
                                <FaHome className='menu-item-icon' />My Dashboard
                            </li>
                            <li
                                className={activeSection === 'users' ? 'active' : ''}
                                onClick={handleUsers}
                            >
                                <FaUserCircle className='menu-item-icon' /> Users
                            </li>
                            <li
                                className={activeSection === 'courses' ? 'active' : ''}
                                onClick={handleCourses}
                            >
                                <FaDiagramProject className='menu-item-icon' /> Courses
                            </li>
                            <li
                                onClick={handleLogout}
                            >
                                <FaSignOutAlt className='menu-item-icon' />Logout
                            </li>
                        </ul>
                    </div>

                </div>

                <div className="header">
                    <div className="stack-navbar">
                        <div className="stackbar-heading"><h2>Dashboard</h2></div>

                        <div className="menu-container">
                            {window.innerWidth < 1008 && (

                                <div
                                    className={`menu-toggle ${showContent ? "active" : ""}`}
                                    onClick={toggleContent}
                                >
                                    <div className={`bar ${showContent ? "cross" : ""}`} ></div>
                                    <div className={`bar ${showContent ? "cross" : ""}`}></div>
                                    <div className={`bar ${showContent ? "cross" : ""}`}></div>
                                </div>
                            )}
                            {showContent && (
                                <ul className={`menu-items ${showMenu ? 'show' : ''}`}>

                                    <div>
                                        <div className='profileIcon' onClick={(e) => showProfileMenu(e)}>
                                            <>
                                                <FaUserCircle />
                                                Admin
                                                <FaChevronDown />
                                            </>
                                        </div>
                                        <OverlayPanel
                                            ref={overlayPanelRef}
                                            appendTo={document.body}
                                            onHide={() => setShowMenu(false)}
                                        >
                                            <ul className="profile-menu-list">
                                                <li
                                                    onClick={handleLogout}
                                                >
                                                    <FaSignOutAlt className='menu-item-icon' />Logout
                                                </li>
                                            </ul>
                                        </OverlayPanel>
                                    </div>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="menu-content-section">
                        {activeSection === 'dashboard' && (
                            <div>
                                <Dashboard data={data} onSectionChange={handleSectionChange} />
                            </div>
                        )}
                        {activeSection === 'users' && (
                            <div>
                                <Users />
                            </div>
                        )}
                        {activeSection === 'courses' && (
                            <div>
                                <Courses />
                            </div>
                        )}
                    </div>
                </div>
            </>

        </div>
    )
}

export default Admin;