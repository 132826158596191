import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
    GET_COURSE_URL,
    COURSE_CREATE_URL,
    CONTENT_AUTHORIZE_URL,
    GET_TOPIC_LIST_URL,
    UPDATE_COURSE_URL,
    CREATE_TOPIC_URL,
    QUILL_UPLOAD_URL,
    UPDATE_TOPIC_URL,
} from "../../../Utils/APIService";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Editor } from "primereact/editor";
import 'react-quill/dist/quill.snow.css';

const Courses = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [courseData, setCourseData] = useState({
        courses: [],
        selectedCourse: null,
        courseName: "",
    });
    const [globalFilter, setGlobalFilter] = useState("");
    const dt = useRef(null);

    const [topicsData, setTopicsData] = useState([]);
    const [showTopicForm, setShowTopicForm] = useState(false);

    const [topicTitle, setTopicTitle] = useState("");

    const [editTopicMode, setEditTopicMode] = useState(false);

    const [coverUrl, setCoverUrl] = useState(null);

    const [overview, setOverview] = useState("");
    const [presentationTitle, setPresentationTitle] = useState("");
    const [, setSelectedFile] = useState(null);
    const [speaker, setSpeaker] = useState(null);
    const [extraMaterial, setExtraMaterial] = useState([]);
    const [extraMaterialDialog, setExtraMaterialDialog] = useState(false);

    const [extraMaterialTitle, setExtraMaterialTitle] = useState("");
    const [extraMaterialURL, setExtraMaterialURL] = useState("");
    const [extraMaterialFile, setExtraMaterialFile] = useState(null);

    const [courseId, setCourseId] = useState(null);
    const [topicId, setTopicId] = useState(null);

    const speakers = [
        { label: "Speaker 1", value: "Speaker 1" },
        { label: "Speaker 2", value: "Speaker 2" },
        { label: "Speaker 3", value: "Speaker 3" },
    ];

    const [, setUploadImg] = useState();

    const editorRef = useRef(null);

    useEffect(() => {
        fetchCourses();
    }, []);

    const fetchCourses = async () => {
        try {
            const response = await axios.get(GET_COURSE_URL, {
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("userAccessToken"),
                },
            });
            setCourseData({ ...courseData, courses: response.data });
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    const handleCreateCourse = () => {
        setCourseData({ ...courseData, courseName: "" });
        setEditMode(false);
        setShowDialog(true);
    };

    const handleEditCourse = (course) => {
        setCourseData({
            ...courseData,
            selectedCourse: course,
            courseName: course.title,
            courseId: course.courseId,
        });
        setEditMode(true);
        setShowDialog(true);
    };

    const fetchTopics = (courseId) => {
        axios
            .get(GET_TOPIC_LIST_URL + courseId, {
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("userAccessToken"),
                },
            })
            .then((response) => {
                setTopicsData(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleViewCourseTopics = (course) => {
        setCourseData({ ...courseData, selectedCourse: course });
        setViewMode(true);
        fetchTopics(course.courseId);
        setCourseId(course.courseId);
    };

    const handleSubmitCourse = async () => {
        const payload = {
            userId: localStorage.getItem("userId"),
            courseTitle: courseData.courseName,
        };
        const editPayload = {
            userId: localStorage.getItem("userId"),
            courseTitle: courseData.courseName,
            courseId: courseData.courseId,
        };

        try {
            if (editMode && courseData.selectedCourse) {
                await axios.put(UPDATE_COURSE_URL, editPayload, {
                    headers: {
                        "content-type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("userAccessToken"),
                    },
                });
            } else {
                await axios.post(COURSE_CREATE_URL, payload, {
                    headers: CONTENT_AUTHORIZE_URL,
                });
            }
            fetchCourses();
        } catch (error) {
            console.error("Error creating or updating course:", error);
        }
        setShowDialog(false);
    };

    const handleBackToCourses = () => {
        setViewMode(false);
        setCourseData({ ...courseData, selectedCourse: null });
    };

    const renderHeader = () => (
        <div className="table-header text-right">
            <span className="p-input-icon-left mr-3">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search by Course Title"
                />
            </span>
            <Button
                label="Clear"
                icon="pi pi-times"
                onClick={() => setGlobalFilter("")}
            />
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    label="View Topics"
                    className="p-button-rounded p-button-info"
                    onClick={() => handleViewCourseTopics(rowData)}
                />
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning mr-2"
                    onClick={() => handleEditCourse(rowData)}
                />
            </div>
        );
    };

    const handleTopicClick = () => {
        setShowTopicForm(true);
    };

    const handleSubmitTopic = async () => {
        const payload = {
            courseId: courseId,
            coverFileUrl: selectedCover ? selectedCover.name : null,
            topicTitle: topicTitle,
            presentation: presentationTitle,
            overview: overview,
            extraMaterial: extraMaterial,
        };

        const editpayload = {
            topicId: topicId,
            courseId: courseId,
            coverFileUrl: selectedCover ? selectedCover.name : null,
            topicTitle: topicTitle,
            presentation: presentationTitle,
            overview: overview,
            extraMaterial: extraMaterial,
        };

        try {
            if (editTopicMode) {
                await axios.put(UPDATE_TOPIC_URL, editpayload, {
                    headers: {
                        "content-type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("userAccessToken"),
                    },
                });

                fetchTopics(courseData.selectedCourse.courseId);
                setShowTopicForm(false);
                setEditTopicMode(false);
            } else {
                await axios.post(CREATE_TOPIC_URL, payload, {
                    headers: {
                        "content-type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("userAccessToken"),
                    },
                });

                fetchTopics(courseData.selectedCourse.courseId);
                setShowTopicForm(false);
                setEditTopicMode(false);
                setTopicTitle("");
            }
        } catch (error) {
            console.error("Error submitting topic:", error);
        }
    };

    const [selectedCover, setSelectedCover] = useState(null);

    const onCoverSelect = (e) => {
        const file = e.files[0];
        setSelectedCover(file);
    };

    const previewCover = () => {
        if (selectedCover) {
            if (selectedCover?.type?.startsWith("image")) {
                return (
                    <img
                        src={URL.createObjectURL(selectedCover)}
                        alt="Cover"
                        style={{ width: "100%", height: "auto" }}
                    />
                );
            } else if (selectedCover?.type?.startsWith("video")) {
                return (
                    <video width="100%" controls>
                        <source
                            src={URL.createObjectURL(selectedCover)}
                            type={selectedCover.type}
                        />
                        Your browser does not support the video tag.
                    </video>
                );
            }
        }

        if (coverUrl) {
            if (
                coverUrl.endsWith(".png") ||
                coverUrl.endsWith(".jpg") ||
                coverUrl.endsWith(".jpeg")
            ) {
                return (
                    <img
                        src={coverUrl}
                        alt="Cover"
                        style={{ width: "100%", height: "auto" }}
                    />
                );
            } else if (coverUrl.endsWith(".mp4") || coverUrl.endsWith(".webm")) {
                return (
                    <video width="100%" controls>
                        <source src={coverUrl} />
                        Your browser does not support the video tag.
                    </video>
                );
            }
        }
        return null;
    };

    const hideExtraMaterialDialog = () => {
        setExtraMaterialDialog(false);
    };

    const addExtraMaterial = () => {
        const newMaterial = {
            name: extraMaterialTitle,
            url: extraMaterialFile ? extraMaterialFile.name : extraMaterialURL,
            contentType: extraMaterialFile ? extraMaterialFile.type : "URL",
        };
        setExtraMaterial([...extraMaterial, newMaterial]);
        hideExtraMaterialDialog();
    };

    const onFileSelect = (e) => {
        setSelectedFile(e.files[0]);
    };

    const addNewExtramaterial = () => {
        setExtraMaterialDialog(true);
        setExtraMaterialTitle("");
        setExtraMaterialURL("");
        setExtraMaterialFile("");

        if (extraMaterialTitle && extraMaterialURL && extraMaterialFile) {
            const newMaterial = {
                name: extraMaterialTitle,
                url: extraMaterialURL,
                contentType: extraMaterialFile,
            };

            setExtraMaterial((prevMaterials) => [...prevMaterials, newMaterial]);

            setExtraMaterialTitle("");
            setExtraMaterialURL("");
            setExtraMaterialFile("");
        } else {
            console.log("All fields are required for adding extra material.");
        }
    };

    const dialogFooter = (
        <div>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideExtraMaterialDialog}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={addExtraMaterial}
                disabled={
                    !extraMaterialTitle || (!extraMaterialURL && !extraMaterialFile)
                }
            />
        </div>
    );

    const handleEditTopic = (topic) => {
        setTopicTitle(topic.topicTitle);
        setPresentationTitle(topic.presentation);
        setOverview(topic.overview);
        setExtraMaterial(topic.extraMaterial || []);

        setSelectedCover(null);
        setCoverUrl(topic.coverFileUrl);
        setEditTopicMode(true);
        setShowTopicForm(true);
        setTopicId(topic.topicId);
    };

    const topicEditTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning mr-2"
                    onClick={() => handleEditTopic(rowData)}
                />
            </div>
        );
    };

    const ImageHandler = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const formData = new FormData();
                formData.append("file", file);

                try {
                    const config = {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem(
                                "userAccessToken"
                            )}`,
                        },
                    };
                    const res = await axios
                        .post(QUILL_UPLOAD_URL, formData, config)
                        .then((response) => {
                            const imageUrl = response.data;

                            const quill = editorRef.current.getQuill();
                            const range = quill.getSelection();
                            quill.insertEmbed(range.index, "image", imageUrl);

                            setUploadImg(response.data);
                        })
                        .catch((error) => console.log(error));
                } catch (err) {
                    console.error("Error uploading image:", err);
                }
            }
        };
    };

    const modules = {
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
            ],
            handlers: {
                image: ImageHandler,
            },
        },
    };

    return (
        <div className="admin-container">
            <div className="course-header">
                <div className="dashboard-title text-left p-3 mt-0">Courses</div>
            </div>

            {!viewMode ? (
                <div>
                    <div className="course-header text-right">
                        <Button
                            label="Create Course"
                            icon="pi pi-plus"
                            className="p-button-success"
                            onClick={handleCreateCourse}
                        />
                    </div>

                    <div className="course-list mt-4 px-3">
                        {courseData.courses.length > 0 ? (
                            <DataTable
                                ref={dt}
                                value={courseData.courses}
                                responsiveLayout="scroll"
                                globalFilter={globalFilter}
                                header={renderHeader()}
                                paginator
                                rows={5}
                            >
                                <Column field="title" header="Title" sortable />
                                <Column
                                    field="createdOn"
                                    header="Created On"
                                    body={(rowData) => rowData.createdOn || "N/A"}
                                    sortable
                                />
                                <Column header="Actions" body={actionBodyTemplate} />
                            </DataTable>
                        ) : (
                            <p>No courses available.</p>
                        )}
                    </div>
                </div>
            ) : (
                <div className="course-details mt-4 px-3">
                    <span onClick={handleBackToCourses}>Back to Courses</span>

                    <h3>Course: {courseData.selectedCourse?.title}</h3>
                    <h4>Topics:</h4>

                    <Button
                        label="Create Topic"
                        icon="pi pi-plus"
                        className="p-button-success text-right"
                        onClick={handleTopicClick}
                    />

                    {topicsData.length > 0 && (
                        <DataTable
                            ref={dt}
                            value={topicsData}
                            responsiveLayout="scroll"
                            globalFilter={globalFilter}
                            header={renderHeader()}
                            paginator
                            rows={5}
                        >
                            <Column field="topicTitle" header="Title" sortable />
                            <Column header="Actions" body={topicEditTemplate} />
                        </DataTable>
                    )}
                </div>
            )}

            <Dialog
                header={editMode ? "Edit Course" : "Create Course"}
                visible={showDialog}
                style={{ width: "50vw" }}
                modal
                onHide={() => setShowDialog(false)}
            >
                <div className="admin-dialog-section">
                    <div className="fields-section">
                        <label htmlFor="courseName">Course Title</label>
                        <InputText
                            id="courseName"
                            value={courseData.courseName}
                            onChange={(e) =>
                                setCourseData({ ...courseData, courseName: e.target.value })
                            }
                            placeholder="Enter Course Title"
                        />
                    </div>

                    <div className="admin-dialog-btn-footer">
                        <Button
                            label="Submit"
                            icon="pi pi-check"
                            onClick={handleSubmitCourse}
                            disabled={!courseData.courseName}
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                header={editTopicMode ? "Edit Topic" : "Create Topic"}
                visible={showTopicForm}
                style={{ width: "80%" }}
                onHide={() => setShowTopicForm(false)}
            >
                <div className="admin-dialog-section">
                    <h3>Topic Information</h3>
                    <div className="fields-section">
                        <label htmlFor="topicTitle">Topic Title</label>
                        <InputText
                            id="topicTitle"
                            value={topicTitle}
                            onChange={(e) => setTopicTitle(e.target.value)}
                        />
                    </div>

                    <h3>Cover</h3>
                    <div className="fields-section">
                        <div
                            className="p-d-flex p-ai-center p-jc-center"
                            style={{
                                border: "1px solid #ccc",
                                height: "150px",
                                marginBottom: "1em",
                            }}
                        >
                            {previewCover()}
                        </div>

                        <FileUpload
                            name="cover"
                            accept="image/*,video/*"
                            maxFileSize={1000000}
                            onSelect={onCoverSelect}
                            customUpload
                            mode="basic"
                            chooseLabel="Choose Cover Image/Video"
                        />
                    </div>

                    <h3>Overview</h3>
                    <div className="p-field">
                        <Editor
                            theme="snow"
                            ref={editorRef}
                            style={{ height: "320px" }}
                            value={overview}
                            onTextChange={(e) => setOverview(e.htmlValue)}
                            modules={modules}
                        />
                    </div>

                    <h3>Presentation Title</h3>

                    <div className="fields-section">
                        <label htmlFor="presentationTitle">Presentation Title</label>
                        <InputText
                            id="presentationTitle"
                            value={presentationTitle}
                            onChange={(e) => setPresentationTitle(e.target.value)}
                        />
                        <br />

                        <FileUpload
                            name="presentation"
                            customUpload
                            onSelect={onFileSelect}
                            mode="basic"
                            accept="application/pdf"
                            maxFileSize={1000000}
                        />
                    </div>

                    <div className="fields-section">
                        <div className="flex justify-content-between flex-wrap">
                            <h3>Extra Material</h3>

                            <Button
                                label="Add New Extramaterial"
                                icon="pi pi-plus"
                                className="p-button-secondary mb-3"
                                onClick={addNewExtramaterial}
                            />
                        </div>

                        {extraMaterial.length > 0 && (
                            <ul>
                                {extraMaterial.map((material, index) => (
                                    <li key={index}>
                                        {material.name} ({material.contentType}) - {material.url}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="fields-section">
                        <label htmlFor="speaker">Speaker(s)</label>
                        <Dropdown
                            id="speaker"
                            value={speaker}
                            options={speakers}
                            onChange={(e) => setSpeaker(e.value)}
                            placeholder="Select a speaker"
                        />
                    </div>

                    <Dialog
                        header="Add Extramaterial"
                        visible={extraMaterialDialog}
                        style={{ width: "50vw" }}
                        footer={dialogFooter}
                        onHide={hideExtraMaterialDialog}
                    >
                        <div className="admin-dialog-section">
                            <div className="fields-section">
                                <label htmlFor="extraMaterialTitle">Extramaterial Title</label>
                                <InputText
                                    id="extraMaterialTitle"
                                    value={extraMaterialTitle}
                                    onChange={(e) => setExtraMaterialTitle(e.target.value)}
                                />
                            </div>

                            <div className="fields-section">
                                <label htmlFor="extraMaterialURL">Extramaterial URL</label>
                                <InputText
                                    id="extraMaterialURL"
                                    value={extraMaterialURL}
                                    onChange={(e) => setExtraMaterialURL(e.target.value)}
                                    disabled={extraMaterialFile !== null}
                                />
                            </div>

                            <div className="fields-section">
                                <label> Upload file</label>
                                <FileUpload
                                    name="file"
                                    customUpload
                                    mode="basic"
                                    onSelect={onFileSelect}
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </Dialog>

                    <div className="admin-dialog-btn-footer">
                        <Button label="Confirm" onClick={handleSubmitTopic} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Courses;
