import React from 'react';
import './Explore.scss';
import Students from "../../../Assets/Home/S2L_for_students.png";
import Professional from "../../../Assets/Home/S2L_for_Profesional.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';


const Explore = () => {
    const data = [
        {
            title: "For Students",
            description: "Our program is divided into four main courses, each focusing on developing essential entrepreneurial skills, from foundational knowledge to launching our own startup. Additionally, the program offers an optional internship to provide real-world experience.",
            imageUrl: Students,
            linkText: "Learn More about the courses",
            linkUrl: "/programs",
            color: "#FF2132"
        },
        {
            title: "For Professional Entrepreneurs",
            description: "Explore Startup2Launch's customized plans, designed for every stage of your entrepreneurial journey, offering the ideal balance of guidance, expertise, and resources to achieve business success.",
            imageUrl: Professional,
            linkText: "Learn More about the plans",
            linkUrl: "/programs",
            color: "#FFCE09"
        }
    ];

    const navigate = useNavigate()

    const handleRedirect = () => {
        navigate("/questionnaire");
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div className="programs-section">
                <h2>Explore Our Programs & Services</h2>

                <div className="programs-content">
                    {data.map((program, index) => (
                        <div className="program-item" key={index}>
                            <img src={program.imageUrl} alt={program.title} />
                            <h3 style={{ borderBottom: `3px solid ${program.color}` }}>{program.title}</h3>
                            <p>{program.description}</p>
                            {/* <a href={program.linkUrl} className="learn-more">
                                {program.linkText} <FontAwesomeIcon icon={faArrowRight} />
                            </a> */}
                        </div>
                    ))}
                </div>

                <div className="help-section">
                    <div className="help-content">
                        <h2>We can help you to get the best program for your needs</h2>
                        <p>Let our team assist you in reaching your destination.</p>
                    </div>
                    <div className="help-button">
                        <Button className="button-link" onClick={handleRedirect} >Go to the questionnaire</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Explore;
