import React, { useEffect, useRef, useState } from "react";
import "./Banner.scss";
import { Button } from "primereact/button";
import Bannerimage from "../../../Assets/Home/Header_squares.png";
import thumbnail from "../../../Assets/Home/thumbnail.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const videoElement = videoRef.current;
        const updatePlayingState = () => setIsPlaying(!videoElement.paused);

        videoElement.addEventListener("play", updatePlayingState);
        videoElement.addEventListener("pause", updatePlayingState);

        return () => {
            videoElement.removeEventListener("play", updatePlayingState);
            videoElement.removeEventListener("pause", updatePlayingState);
        };
    }, []);


    // useEffect(() => {
    //     if (videoRef.current) {
    //         videoRef.current.muted = true;
    //         videoRef.current.play().catch(error => {
    //             console.error("Error attempting to play the video:", error);
    //         });
    //     }
    // }, []);

    const handleRedirect = () => {
        navigate("/questionnaire");
    };

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        } else {
            console.log("Video element ref is not defined.");
        }
    };

    return (
        <>
            <section className="layout">
                <div className="banner-content">
                    <section className="banner-section-content">
                        <h1>Empowering the Next Generation of Entrepreneurs</h1>
                        <p>
                            Join our unique entrepreneurship programs for aspiring innovators,
                            where we help you transform ideas into successful products and
                            thriving businesses.
                        </p>
                    </section>

                    <div className="banner-button">
                        <Button onClick={handleRedirect}>Get Started</Button>
                    </div>
                </div>

                <div className="banner-images">
                    <img src={Bannerimage} alt="Banner" className="background-image" />
                    <div className="video-container">
                        {!isPlaying && (
                            <img
                                src={thumbnail}
                                alt="Thumbnail"
                                className="thumbnail"
                                onClick={togglePlayPause}
                            />
                        )}
                        <video
                            ref={videoRef}
                            src="https://staticfile.startup2launch.com/Video.mp4"
                            poster={thumbnail}
                            controls
                            playsInline
                            muted
                            autoPlay
                        />

                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                togglePlayPause();
                            }}
                            className={`play-pause-button ${!isPlaying ? "show" : ""}`}
                        >
                            {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Banner;
