import React, { useState } from "react";
import "../LoginPage.scss";
import { Button } from "primereact/button";
import LogIn from "../../../../Assets/Authentication/Forgot.jpg";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FORGOT_PASSWORD_URL } from "../../../../Utils/APIService";
import { CONTENT_HEADERS } from "../../../../Utils/LandingService";
import logo from "../../../../Assets/Home Page/S2L-Logo.svg";
import ErrorMessage from "../../../../Shared-Components/ErrorMessage/ErrorMessage";

const ForgotPasswordPage = () => {
    const [email, setUsername] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setUsername(e.target.value);
        setError('');
    };
    const isEmailValid = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    const handleSentIt = () => {
        if (!email) {
            setError("Please provide an email.");
        }
        else if (!isEmailValid(email)) {
            setError("Please enter a valid email address.");
            return;
        }
        else {
            axios
                .post(FORGOT_PASSWORD_URL + email, { headers: CONTENT_HEADERS })
                .then((response) => {
                    navigate(`/resetPassword`, { state: { email } });
                }
                )
                .catch((error) => {
                    if (error?.response?.data?.hasOwnProperty("message")) {
                        const errorMessage = error.response.data.message;

                        if (errorMessage === "Enter the valid EmailId") {
                            setError("The email you’re entered doesn’t exist. Please check that you have typed your email correctly.");
                        }
                    }
                    else {
                        setError('Please double-check and try again.')
                    }
                });
        }


    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSentIt();
        }
    };


    return (
        <>
            <div className="auth-sectio">
                <div className="logos">
                    <a href="/"><img src={logo} alt="" /></a>
                </div>

                <div className="login-page">
                    <Card className="login-card p-2">
                        <div className="login-section">
                            <div className="w-full login-detail-section">
                                <h2>Forget Password?</h2>

                                <div className='login-sub'>
                                    <p> Please provide your email for change your password.</p>

                                    {error && <ErrorMessage message={error} isSuccess={true} />}
                                </div>
                                <div className="p-fluid">
                                    <div className="field">
                                        <label className="email">Email</label>
                                        <InputText
                                            type="email"
                                            value={email}
                                            placeholder='Enter your email'
                                            onChange={handleInputChange}
                                            className={error && !email.email ? "email-error" : ""}
                                            onKeyPress={email ? handleKeyPress : null}
                                        />
                                    </div>
                                    <div className='button'>
                                        <Button label="Send It" className="p-button-sm" type="submit" onClick={email ? handleSentIt : null} />
                                    </div>
                                </div>
                            </div>

                            <div className="login-img">
                                <img src={LogIn} alt="login-up-image" />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordPage