import React, { useEffect, useRef, useState } from "react";
import "./Settings.scss";
import { Avatar } from "primereact/avatar";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { MdModeEditOutline, MdSave } from "react-icons/md";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import axios from "axios";
import profileImage from "../../../Assets/Settings/ProfileAvatar.svg";
import profileImage1 from "../../../Assets/Settings/avatar1.png";
import profileImage2 from "../../../Assets/Settings/avatar2.png";
import profileImage3 from "../../../Assets/Settings/avatar3.png";
import profileImage4 from "../../../Assets/Settings/avatar4.png";
import profileImage5 from "../../../Assets/Settings/avatar5.png";
import profileImage6 from "../../../Assets/Settings/avatar6.png";
import profileImage7 from "../../../Assets/Settings/avatar7.png";
import profileImage8 from "../../../Assets/Settings/avatar8.png";
import profileImage9 from "../../../Assets/Settings/avatar9.png";
import profileImage10 from "../../../Assets/Settings/avatar10.png";
import profileImage11 from "../../../Assets/Settings/avatar11.png";
import profileImage12 from "../../../Assets/Settings/avatar12.png";
import {
  USER_INFO_URL,
  UNIQUEID_URL,
  CHANGE_PASSWORD,
} from "../../../Utils/APIService";
import { CONTENT_HEADERS } from "../../../Utils/LandingService";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { v4 as uuidv4 } from "uuid";
import JSEncrypt from "jsencrypt";

const SettingsComponent = ({ userDetails, parentDetails, setRefreshInfo }) => {
  const profilePeopleImages = [
    profileImage1,
    profileImage2,
    profileImage3,
    profileImage4,
    profileImage5,
    profileImage6,
    profileImage7,
    profileImage8,
    profileImage9,
    profileImage10,
    profileImage11,
    profileImage12,
  ];
  const [isEditingPersonal, setIsEditingPersonal] = useState(false);
  const [isEditingParent, setIsEditingParent] = useState(false);
  const [showAvatarDialog, setShowAvatarDialog] = useState(false); // For showing dialog on avatar edit click
  const [avatarImage, setAvatarImage] = useState(profileImage); // Initial avatar image
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const toast = useRef(null);
  const [updatedImage, setUpdatedImage] = useState(
    userDetails?.profilePicture ? userDetails?.profilePicture : profileImage
  );
  const [personalInfo, setPersonalInfo] = useState({
    email: "",
    phone: userDetails?.phoneNumber,
    dob: userDetails?.dob,
    school: userDetails?.schoolName,
    grade: userDetails?.schoolGrade,
  });
  const [parentInfo, setParentInfo] = useState({
    parentFirstName: parentDetails?.firstName,
    parentLastName: parentDetails?.lastName,
    parentPhone: parentDetails?.phoneNumber,
  });

  const gradeOptions = [
    { label: "Grade 6", value: "Grade 6" },
    { label: "Grade 7", value: "Grade 7" },
    { label: "Grade 8", value: "Grade 8" },
    { label: "Grade 9", value: "Grade 9" },
    { label: "Grade 10", value: "Grade 10" },
    { label: "Grade 11", value: "Grade 11" },
    { label: "Grade 12", value: "Grade 12" },
  ];


  const handleSave = async () => {

    const userAccessToken = localStorage.getItem("userAccessToken");

    try {
      const requestBody = getBody();

      await axios.post(USER_INFO_URL, requestBody, {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      });

      setRefreshInfo((prev) => !prev);
      setShowAvatarDialog(false);
      setIsEditingPersonal(false);
      setIsEditingParent(false);
    } catch (error) {
      console.error("Error during save:", error);
    }
  };

  const getBody = () => {
    return {
      userId: `${localStorage.getItem("userId")}`,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      phoneNumber: personalInfo?.phone || userDetails?.phoneNumber,
      profilePicture: updatedImage || userDetails?.profilePicture,
      schoolGrade: personalInfo?.grade || userDetails?.schoolGrade,
      dob: personalInfo?.dob || userDetails?.dob,
      schoolName: personalInfo?.school || userDetails?.schoolName,
      areaOfInterest: userDetails?.areaOfInterest,
      parentFirstName: parentInfo?.parentFirstName || parentDetails?.firstName,
      parentLastName: parentInfo?.parentLastName || parentDetails?.lastName,
      parentPhoneNumber: parentInfo?.parentPhone || parentDetails?.phoneNumber,
      parentEmail: parentDetails?.email,
    };
  };


  const handlePasswordChange = () => {
    const uniqueId = uuidv4();

    const requestData = { uniqueId };

    const userAccessToken = localStorage.getItem("userAccessToken");

    axios
      .post(UNIQUEID_URL, requestData, { headers: CONTENT_HEADERS })
      .then((uniqueIdResponse) => {
        const publicKey = uniqueIdResponse.data.PublicKey;

        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(publicKey);

        const combainedString = `${userDetails?.email}:${currentPassword}:${newPassword}`;
        const encryptedCombinedString = jsEncrypt.encrypt(combainedString);

        const apiPayload = {
          uniqueId: requestData.uniqueId,
          encryptedData: encryptedCombinedString,
        };

        return axios.post(CHANGE_PASSWORD, apiPayload, {
          headers: {
            Authorization: "Bearer " + userAccessToken,
          },
        });
      })
      .then((response) => {
        setCurrentPassword("");
        setNewPassword("");
        toast.current.show({ severity: 'success', summary: 'success', detail: response.data.message });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePersonalEditToggle = () => {
    setIsEditingPersonal(!isEditingPersonal);
  };

  const handleParentEditToggle = () => {
    setIsEditingParent(!isEditingParent);
  };

  const handleInputChange = (event, field, section) => {
    if (section === "personal") {
      setPersonalInfo({ ...personalInfo, [field]: event.target.value });
    } else {
      setParentInfo({ ...parentInfo, [field]: event.target.value });
    }
  };

  const toggleAvatarDialog = () => {
    setShowAvatarDialog(!showAvatarDialog);
  };

  const handleProfileEditSave = () => {
    setAvatarImage(updatedImage);

    handleSave();
  };

  const profileEditHeader = (
    <div className="inline-flex align-items-left justify-content-left gap-2">
      <span className="font-bold white-space-nowrap">
        Customize your Profile Image
      </span>
    </div>
  );
  const profileEditFooter = (
    <div>
      <Button label="Save" icon="pi pi-check" onClick={handleProfileEditSave} />
    </div>
  );

  const handleProfileImageChange = (image) => {
    setUpdatedImage(image);
  };


  return (
    <>
      <Toast ref={toast} />
      <div className="settings-container">
        {/* Profile Section */}
        <div className="flex profile-section">
          <div className="avatar-container">
            {userDetails?.profilePicture ? (
              <img
                src={userDetails?.profilePicture}
                alt=""
                style={{
                  width: "6rem",
                  height: "6rem",
                  borderRadius: "50%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  padding: "5px"
                }}
              />
            ) : (
              <img src={avatarImage} alt="Avatar" className="profile-image" />
            )}

            <div className="edit-avatar-icon" onClick={toggleAvatarDialog}>
              <MdModeEditOutline className="onhover-edit-icon" />
            </div>
          </div>

          <div className="name-id">
            <div className="stu-name">
              {userDetails?.firstName + " " + userDetails?.lastName}
            </div>
            {/* <div className="stu-id">(ID: 8456325)</div> */}
          </div>
        </div>

        {/* Personal Information Section */}
        <div className="personal-info-section">
          <div className="info-header">
            <div className="info-title">Personal Information</div>
            {isEditingPersonal ? (
              <MdSave
                onClick={handlePersonalEditToggle}
                className="edit-pencil"
              />
            ) : (
              <MdModeEditOutline
                onClick={handlePersonalEditToggle}
                className="edit-pencil"
              />
            )}
          </div>
          <div className="personalInfo-form">
            <div className="grid">
              <div className="field col-12 md:col-4 flex flex-column gap-2">
                <label htmlFor="Email">Email</label>
                <InputText
                  value={userDetails?.email}
                  onChange={(e) => handleInputChange(e, "email", "personal")}
                  disabled={true}
                  placeholder="Enter email"
                />
              </div>
              <div className="field col-12 md:col-4 flex flex-column gap-2">
                <label htmlFor="Phone Number">Phone Number</label>
                <InputText
                  keyfilter="int"
                  value={
                    personalInfo?.phone 
                  }
                  onChange={(e) => handleInputChange(e, "phone", "personal")}
                  disabled={!isEditingPersonal}
                  placeholder="Enter phone number"
                />
              </div>
              <div className="field col-12 md:col-4 flex flex-column gap-2">
                <label htmlFor="Date of Birth">Date of Birth</label>
                <Calendar
                  id="dob"
                  name="dob"
                  value={
                    personalInfo?.dob 
                  }
                  onChange={(e) => handleInputChange(e, "dob", "personal")}
                  dateFormat="mm/dd/yy"
                  placeholder={
                    personalInfo?.dob 
                  }
                  disabled={!isEditingPersonal}
                />
              </div>
            </div>

            <div className="grid">
              <div className="field col-12 md:col-6 flex flex-column gap-2">
                <label htmlFor="School Name">School Name</label>
                <InputText
                  value={
                    personalInfo?.school
                  }
                  onChange={(e) => handleInputChange(e, "school", "personal")}
                  disabled={!isEditingPersonal}
                  placeholder="Enter school name"
                />
              </div>
              <div className="field col-12 md:col-6 flex flex-column gap-2">
                <label htmlFor="Grade">Grade</label>
                <Dropdown
                  id="schoolGrade"
                  name="schoolGrade"
                  value={
                    personalInfo?.grade
                  }
                  options={gradeOptions}
                  onChange={(e) => handleInputChange(e, "grade", "personal")}
                  disabled={!isEditingPersonal}
                  placeholder={
                    personalInfo?.grade
                  }
                />
              </div>
            </div>
            <div className="save-button-container">
              <Button label="Save" onClick={handleSave} />
            </div>
          </div>
        </div>

        {/* Parents Information Section */}
        <div className="parent-info-section">
          <div className="info-header">
            <div className="info-title">Parents Information</div>
            {isEditingParent ? (
              <MdSave
                onClick={handleParentEditToggle}
                className="edit-pencil"
              />
            ) : (
              <MdModeEditOutline
                onClick={handleParentEditToggle}
                className="edit-pencil"
              />
            )}
          </div>
          <div className="personalInfo-form">
            <div className="grid">
              <div className="field col-12 md:col-6 flex flex-column gap-2">
                <label htmlFor="Parent Name">First Name</label>
                <InputText
                  value={
                    parentInfo?.parentFirstName
                  }
                  onChange={(e) =>
                    handleInputChange(e, "parentFirstName", "parent")
                  }
                  disabled={!isEditingParent}
                  placeholder="Enter first name"
                />
              </div>

              <div className="field col-12 md:col-6 flex flex-column gap-2">
                <label htmlFor="Parent Name">Last Name</label>
                <InputText
                  value={
                    parentInfo?.parentLastName
                  }
                  onChange={(e) =>
                    handleInputChange(e, "parentLastName", "parent")
                  }
                  disabled={!isEditingParent}
                  placeholder="Enter last name"
                />
              </div>
              <div className="field col-12 md:col-6 flex flex-column gap-2">
                <label htmlFor="Email">Email</label>
                <InputText
                  value={parentDetails?.email}
                  // onChange={(e) => handleInputChange(e, "parentPhone", "parent")}
                  disabled={true}
                  placeholder="Enter email"
                />
              </div>
              <div className="field col-12 md:col-6 flex flex-column gap-2">
                <label htmlFor="Phone Number">Phone Number</label>
                <InputText
                  keyfilter="int"
                  value={
                    parentInfo?.parentPhone
                  }
                  onChange={(e) =>
                    handleInputChange(e, "parentPhone", "parent")
                  }
                  disabled={!isEditingParent}
                  placeholder="Enter phone number"
                  maxLength={10}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="save-button-container">
          <Button label="Save" onClick={handleSave} />
        </div>

        {/* Change Password Section */}
        <div className="changepswd-info-section">
          <div className="info-header">
            <div className="info-title">Change Password</div>
          </div>
          <div className="changepwsd-form">
            <div className="grid">
              <div className="field col-12 flex flex-column gap-2">
                <label htmlFor="currentPassword">Current Password</label>
                <Password
                  id="currentPassword"
                  toggleMask
                  placeholder="Enter current password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </div>
              <div className="field col-12 flex flex-column gap-2">
                <label htmlFor="newPassword">New Password</label>
                <Password
                  id="newPassword"
                  toggleMask
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <Button
              label="Confirm"
              className="changepswd-button"
              style={{ backgroundColor: "#8AB060" }}
              onClick={handlePasswordChange}
              disabled={
                !currentPassword ||
                !newPassword ||
                currentPassword === newPassword
              }
            />
          </div>
        </div>

        <Dialog
          className="dialog-boxes"
          visible={showAvatarDialog}
          modal
          header={profileEditHeader}
          footer={profileEditFooter}
          style={{ width: "50rem" }}
          onHide={() => {
            setShowAvatarDialog(false);
            setUpdatedImage(
              userDetails?.profilePicture
                ? userDetails?.profilePicture
                : profileImage
            );
          }}
        >
          <div className="flex d-profile-section">
            <div className="avatar-container">
              <img src={updatedImage} alt="Avatar" className="profile-image" />
              {/* <div className="edit-avatar-icon" onClick={toggleAvatarDialog}>
                                    <MdModeEditOutline className='onhover-edit-icon' />
                                </div> */}
            </div>

            <div className="name-id">
              <h2>{userDetails?.firstName + " " + userDetails?.lastName}</h2>
            </div>
          </div>

          <div className="background-options">
            <h3>Update Profile Picture</h3>
            <div>
              {profilePeopleImages.map((image, index) => (
                <div
                  className="inline-flex align-items-center justify-content-left"
                  key={index}
                >
                  <img
                    src={image}
                    alt={`Background ${index}`}
                    style={{
                      width: "55px",
                      height: "55px",
                      margin: "5px",
                      objectFit: "contain",
                      padding: "5px",
                    }}
                    onClick={() => {
                      handleProfileImageChange(image);
                      // handleImageToFile(image);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default SettingsComponent;
