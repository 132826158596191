import React from "react";
import "./ErrorMessage.scss";

const ErrorMessage = ({ message, isSuccess }) => {
    return (
        <>
            <div>
                {isSuccess ? (
                    <div className="error-container w-full grid col-12 m-0 ">
                        <div className="col-fixed flex align-items-center justify-content-center">
                            <i
                                className="pi pi-times-circle flex align-items-center justify-content-center"
                                style={{ fontSize: "1rem", color: "#E04A4A" }}
                            ></i>
                        </div>
                        <div className="col">
                            <h3 className="error-head flex flex-wrap m-0">Error</h3>
                            <span className="error-messages flex flex-wrap">{message}</span>
                        </div>
                    </div>
                ) : (
                    <div className="success-container w-full grid col-12 m-0 ">
                        <div className="col-fixed flex align-items-center justify-content-center">
                            <i
                                className="pi pi-check-circle flex align-items-center justify-content-center"
                                style={{ fontSize: "1rem", color: "#036E35" }}
                            ></i>
                        </div>
                        <div className="col">
                            <h3 className="success-head flex flex-wrap m-0">Success</h3>
                            <span className="success-messages flex flex-wrap">{message}</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ErrorMessage;
