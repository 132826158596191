import React, { useRef, useState } from "react";
import "./LoginPage.scss";
import { Button } from "primereact/button";
import LogIn from "../../../Assets/Authentication/Login.png";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { LOGIN_URL, UNIQUEID_URL } from "../../../Utils/APIService";
import { CONTENT_HEADERS } from "../../../Utils/LandingService";
import JSEncrypt from "jsencrypt";
import logo from "../../../Assets/Home Page/S2L-Logo.svg";
import { Messages } from "primereact/messages";

const LoginPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const msg = useRef(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({ ...errors, [name]: "" });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.email) {
            newErrors.email = "Email is required";
        }
        if (!formData.password) {
            newErrors.password = "Password is required";
        }
        return newErrors;
    };

    const handleLogin = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        setLoading(true);
        const uniqueId = uuidv4();

        const requestData = { uniqueId };

        axios
            .post(UNIQUEID_URL, requestData, { headers: CONTENT_HEADERS })
            .then((uniqueIdResponse) => {
                const publicKey = uniqueIdResponse.data.PublicKey;

                const jsEncrypt = new JSEncrypt();
                jsEncrypt.setPublicKey(publicKey);

                const combinedString = `${formData.email}:${formData.password}`;
                const encryptedCombinedString = jsEncrypt.encrypt(combinedString);

                const apiPayload = {
                    uniqueId: requestData.uniqueId,
                    encryptedData: encryptedCombinedString,
                };

                return axios.post(LOGIN_URL, apiPayload, { headers: CONTENT_HEADERS });
            })
            .then((loginResponse) => {
                setLoading(false);

                localStorage.setItem("userAccessToken", loginResponse.data.access_token);
                localStorage.setItem("userRefreshToken", loginResponse.data.refresh_token);
                localStorage.setItem("userId", loginResponse.data.user_id);
                localStorage.setItem("email", loginResponse.data.email_Id);
                
                if (loginResponse.data.role === "ADMIN") {
                    navigate("/adminDashboard")
                } else {
                    if (loginResponse.data.isUserEnabled) {
                        navigate("/dashboard")
                    } else {
                        navigate("/additionalInfo");
                    }
                }
            })
            .catch((error) => {
                msg.current.show({ severity: 'error', detail: `${error.response.data.message}` })
                setLoading(false);
                // setErrors({ apiError: "Login failed. Please try again." });
                console.error("API Error:", error);
                console.error("API Error:", error.response.data.message);
                setErrors({ apiError: error.response.data.message })
            });
    };

    const handleForgetPassword = () => {
        navigate("/forgot-password");
    }

    return (
        <>
            <div className="auth-section">
                <div className="logos">
                    <a href="/"><img src={logo} alt="" /></a>
                </div>

                <div className="login-page">
                    <Card className="login-card p-2">
                        <div className="login-section">
                            <div className="w-full login-detail-section">
                                <h2>Welcome Back!</h2>
                                <p>Please enter your details</p>

                                {errors && <Messages ref={msg} className="noteMessages" />}

                                <div className="fields">
                                    <div className="flex flex-column w-full">
                                        <label htmlFor="email">Email</label>
                                        <InputText
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className="w-full"
                                            placeholder="Enter Your Email"
                                        />
                                        {errors.email && <small className="error-message">{errors.email}</small>}
                                    </div>

                                    <div className="flex flex-column w-full mt-3">
                                        <label htmlFor="password">Password</label>
                                        <InputText
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            toggleMask={true}
                                            placeholder="Enter Your Password"
                                        />
                                        {errors.password && <small className="error-message">{errors.password}</small>}
                                    </div>

                                    <div className="login-button">
                                        <Button
                                            label={loading ? "Logging in..." : "Login"}
                                            onClick={handleLogin}
                                            className="w-full mt-4"
                                            disabled={!formData.email || !formData.password || loading}
                                        />
                                    </div>
                                    <br />
                                    <div className="auth-footer-section">
                                        <span onClick={handleForgetPassword}>Forgot Password</span>
                                    </div>

                                    <span className="login-footer">
                                        Don’t have an account?&nbsp;
                                        <span
                                            className="signup-button"
                                            onClick={() => navigate("/signup")}
                                        >
                                            Sign up
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div className="login-img">
                                <img src={LogIn} alt="login-up-image" />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
